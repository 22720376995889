/* Trading - Giao Dich */
import i18n from '../i18n';
export const constants = {
  trading: {
    NORMAL_ORDER_TAB: '0',
    CONDITIONAL_ORDER_TAB: '1',
    PUT_THROUGH_ORDER_TAB: '2',
  },
  news: {
    ALL_MARKET_TAB: '0',
    US_INDICES_TAB: '1',
    COMMODITY_TAB: '2',
    VIDEO_TAB: '0',
    MARKET_BRIEF_TAB: '1',
  },
  pinefolio: {
    PRODUCT_LIST: 0,
    ORDER_LIST: 1,
    PORTFOLIO: 2,
    PLAN: 3,
    ANALYSIS: 4,
    createPlan: {
      FULL_SET: 0,
      SPLIT_SET: 1,
    },
    planManagement: {
      VIEW_1_MONTH: 1,
      VIEW_3_MONTHS: 3,
      VIEW_6_MONTHS: 6,
      VIEW_9_MONTHS: 9,
      VIEW_12_MONTHS: 12,
      ACCUMULATED_TAB: 0,
      PLAN_HISTORY_TAB: 1,
      BOND_ORDER_LIST_TAB: 2,
      BOND_DEAL_LIST_TAB: 3,
    },
  },
  marginCompetition: {
    GENERAL_INTRO_TAB: '0',
    RULE_AND_AWARD: '1',
    PERFORMANCE: '2',
    LEADER_BOARD: '3',
    QA: '4',
  },
  modules: {
    COMPETITION: 'COMPETITION',
    BOND: 'BOND',
    PINEFOLIO: 'PINEFOLIO',
  },
  G: {
    // Type of priceboard
    DEFAULT: 'G1', // normal
    ODD_LOT: 'G4', // oddlot
    POST_CLOSE: 'G3', // hnx post close
    BUY_IN: 'G7', // buyin
  },
  subAccount: {
    Pinefolio: 'P',
    Derivative: 'D',
    Margin: 'M',
    Normal: 'N',
  },
  authenType: {
    smsOTP: { name: 'SMS', value: 1 },
    matrix: { name: i18n.t('txt-matrix'), value: 2 },
    smartOTP: { name: 'Smart OTP', value: 3 },
  },
  bondProductType: {
    Fix: '1',
    Var: '2',
    Growth: '3',
  },
  sortDirection: {
    Asc: 0,
    Desc: 1,
  },
  siteTitle: 'Bảng giá chứng khoán - PineTree Securities',
};

export const bondConstants = {
  tabs: {
    PRODUCT_LIST: 0,
    ORDER_LIST: 1,
    DEAL_LIST: 2,
    PORTFOLIO: 3,
  },
  modals: {
    CLOSE: -1,
    INFOR: 0,
    BUY_ORDER: 1,
    SELL_ORDER: 2,
    DEMO: 3,
    CONTRACT: 4,
    INCOME_FLOW: 5,
  },
};

export const DERIVATIVE_CONSTANTS = {
  DRVX_TYPE: {
    INDEX: '21',
    BOND5Y: '22',
    BOND10Y: '23',
  },
  POSITION_PANEL: {
    POSITION_TAB: 0,
    OPEN_ORDER_TAB: 1,
    HISTORY_ORDER_TAB: 2,
    CONDITIONAL_ORDER_TAB: 3,
    CLOSE_POSITION_TAB: 4,
    PNL_REPORT_TAB: 5,
    ASSET_TAB: 6,
  },

  ORDER_TYPE: [
    {
      id: '01',
      label: 'LO',
    },
    {
      id: '02',
      label: 'ATO',
    },
    {
      id: '03',
      label: 'ATC',
    },
    {
      id: '05',
      label: 'MTL',
    },

    {
      id: '06',
      label: 'MOK',
    },
    {
      id: '07',
      label: 'MAK',
    },
  ],

  CONDITIONAL_ORDER_TYPE: [
    {
      id: '01',
      label: 'LO',
    },
    {
      id: '05',
      label: 'MTL',
    },
  ],

  PERCENTS: [
    {
      id: 25,
      label: '25%',
    },
    {
      id: 50,
      label: '50%',
    },
    {
      id: 75,
      label: '75%',
    },
    {
      id: 100,
      label: '100%',
    },
  ],
};

export const TRADING_TYPE = {
  LO: '01',
  ATO: '02',
  ATC: '03',
  MP: '04',
  MTL: '05',
  MOK: '06',
  MAK: '07',
  PLO: '08',
};

export const TYPE_SOCKET_CONDITION = [
  'CONW',
  'COCA',
  'COAD',
  'COEX',
  'CORE',
  'COEP',
];

export const TYPE_SOCKET_CONDITION_ACTIVE = ['COCA', 'COEX', 'CORE', 'COEP'];

export const SO_CONDITION_TYPE = {
  EQUAL: 'EQUAL',
  LESS_THAN: 'LESS_THAN',
  GREATER_THAN: 'GREATER_THAN',
  LESS_EQUAL_THAN: 'LESS_EQUAL_THAN',
  GREATER_EQUAL_THAN: 'GREATER_EQUAL_THAN',
};

export const SO_CONDITION_VALUE = {
  EQUAL: '=',
  LESS_THAN: '<',
  GREATER_THAN: '>',
  LESS_EQUAL_THAN: '≤',
  GREATER_EQUAL_THAN: '≥',
};

export const ORDER_ACTION_TYPE = {
  CANCEL_ORDER: '1',
  EDIT_ORDER: '2',
};

export const DERIVATIVE_CONFIRM_STATUS = {
  ALWAYS_SHOW: '0',
  HIDE_TO_RE_LOGIN: '1',
  HIDE_TO_TURN_ON: '2',
};

export const EQUITIES_OPEN_ORDER_PANEL_TABS = {
  OPEN_ORDER: '0',
  ORDER_HISTORY: '1',
  CONDITIONAL_ORDER: '2',
  PUT_THROUGH_ORDER: '3',
};

export const DERIVATIVES_POSITIONS_ASSET_TABS = {
  POSTIONS_TAB: '0',
  CLOSED_POSITION_TAB: '1',
  ASSET_TAB: '2',
};

/* Derivative */

export const CLOSE_REVERSE_TYPE = {
  CLOSE: '1',
  REVERSE: '2',
};

export const LONG_SHORT_TP = {
  LONG: '1',
  SHORT: '2',
};

export const CONDITION_TP = {
  TP: 'TP',
  SL: 'SL',
  SO: 'SO',
};

export const ORDER_PANEL_TP = {
  QUICK: 1,
  NORMAL: 2,
};

/* Trading - Giao Dich */

export const EVENT_TYPE = {
  NEW_ORDER: 'ODNW',
  CANCEL_ORDER: 'ODCA',
  MATCHING_ORDER: 'ODMA',
  REJECT_NEW_ORDER: 'ODRE',
  ADJUST_ORDER: 'ODAD',
  CASH_IN: 'CIIN',
  CASH_OUT: 'CIOU',
};

export const KEYBOARD_SHORTCUTS = {
  Long: {
    keycode: 113,
    name: 'F2',
  },
  Short: {
    keycode: 120,
    name: 'F9',
  },
};

// using to define switch multiple field in a cell column header in table
export const SWITCH_FIELD_STYLE = {
  dots: 1,
  switch: 2,
  none: 0,
};
