import {
  call,
  put,
  takeLatest,
  all,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';
import {
  ALL_STOCK_REQUESTING,
  SNAPSHOT_REQUESTING,
  STOCK_INFO_REQUESTING,
  ALL_ORD_REQUESTING,
  SNAPSHOT_PART_REQUESTING,
  PT_LIST_REQUESTING,
  CW_LIST_REQUESTING,
  LIST_30_REQUESTING,
  LIST_MESSAGE_REQUESTING,
  BRANCH_CODE_REQUESTING,
  SUMMARY_REQUESTING,
  NOI_CAP_REQUESTING,
  GET_STOCK_BY_ID_REQUESTING,
  WORLD_INDEX_REQUESTING,
  INDEX_REQUESTING,
  COMMODITY_REQUESTING,
  GET_PRICEBOARD_COMMON_REQUEST,
} from './constants';

import {
  allStockRequestSuccess,
  allStockRequestError,
  snapshotRequestSuccess,
  snapshotRequestError,
  stockInfoRequestError,
  stockInfoRequestSuccess,
  allOrdRequestSuccess,
  allOrdRequestError,
  snapshotPartRequestSuccess,
  snapshotPartRequestError,
  ptListRequestSuccess,
  ptListRequestError,
  cwListRequestSuccess,
  cwListRequestError,
  list30RequestSuccess,
  list30RequestError,
  listMessageRequestSuccess,
  listMessageRequestError,
  branchCodeRequestSuccess,
  branchCodeRequestError,
  indSumRequestSuccess,
  indSumRequestError,
  noicapRequestSuccess,
  noicapRequestError,
  getStockByIdRequestSuccess,
  getStockByIdRequestError,
  worldIndexRequestSuccess,
  worldIndexRequestError,
  indexRequestSuccess,
  indexRequestError,
  setOddlot,
  commodityRequest,
  commodityRequestError,
  commodityRequestSuccess,
} from './actions';

import { _processMapDataCS, _processMapDataIndex } from '../../util';
import { getMessage } from '../../lib/selector';
import { removeCookie } from '../../lib/storages';
import { constants } from '../../util/constant';
import { setCurrentG } from '../socket/actions';
import { Logout, logoutRequest } from '../../components/modal/login/actions';
import { setToast, unsetClient } from '../client/actions';
import { lockResendOtp } from '../../components/modal/auth/actions';
import i18n from '../../i18n';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const appUrl2 = `${process.env.REACT_APP_API_URL_2}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function allStockRequestApi() {
  const url = `${appUrl}/getlistallstock`;
  const request = fetch(url);

  return handleRequest(request);
}

function ptListRequestApi(data) {
  const url = `${appUrl}/getPTList`;
  const request = fetch(url);

  return handleRequest(request);
}

function cwListRequestApi(data) {
  const url = `${appUrl}/listCW.pt`;
  const request = fetch(url);

  return handleRequest(request);
}

function list30RequestApi(data) {
  const url = `${appUrl}/list30.pt?market=${data}`;
  const request = fetch(url);

  return handleRequest(request);
}

function listMessageRequestApi() {
  const url = `${appUrl}/listMessage`;
  const request = fetch(url);

  return handleRequest(request);
}

function branchCodeRequestApi() {
  const url = `${appUrl}/getInmemList/BRANCH_CODE`;
  const request = fetch(url);

  return handleRequest(request);
}

function noicapRequestApi() {
  const url = `${appUrl}/getInmemList/NOI_CAP`;
  const request = fetch(url);

  return handleRequest(request);
}

function indSumRequestApi() {
  const url = `${appUrl}/getSummary`;
  const request = fetch(url);

  return handleRequest(request);
}

function snapshotRequestApi(data) {
  const url = `${appUrl}/getliststockdata/${data.toUpperCase()}`;
  const request = fetch(url);

  return handleRequest(request);
}

function stockByIdRequestApi(marketID, board) {
  const url = `${appUrl}/getliststockById/${marketID}?board=${board}`;
  const request = fetch(url);
  return handleRequest(request);
}

function worldIndexRequestApi() {
  const url = `${appUrl}/getlistbaseworldindex/`;
  const request = fetch(url);

  return handleRequest(request);
}

function commodityApi(lang) {
  const url = `${appUrl2}/api/Market/GetCommodity?lang=${lang}`;
  const request = fetch(url);

  return handleRequest(request);
}

function indexRequestApi(data) {
  const url = `${appUrl}/getlistindexdetail/${data}`;
  const request = fetch(url);

  return handleRequest(request);
}

function allOrderRequestApi(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* allStockRequestFlow() {
  try {
    const dataList = yield call(allStockRequestApi);

    yield put(allStockRequestSuccess(dataList));
  } catch (error) {
    // console.log(error)
    yield put(allStockRequestError(error));
  }
}

function* snapshotRequestFlow(action) {
  try {
    const { data } = action;
    const dataList = yield call(snapshotRequestApi, data);
    let csData = [];
    dataList.forEach((item) => {
      let symbol = _processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(snapshotRequestSuccess(csData));
    yield put(setCurrentG());
  } catch (error) {
    yield put(snapshotRequestError(error));
  }
}

function* snapshotPartRequestFlow(action) {
  try {
    const { data } = action;
    const dataList = yield call(snapshotRequestApi, data);
    let csData = [];
    dataList.forEach((item) => {
      let symbol = _processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(snapshotPartRequestSuccess(csData));
    yield put(setCurrentG());
  } catch (error) {
    yield put(snapshotPartRequestError(error));
  }
}

function* stockInfoRequestFlow(action) {
  try {
    const { data } = action;
    const dataList = yield call(snapshotRequestApi, data);

    let csData = [];
    dataList.forEach((item) => {
      let symbol = _processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(stockInfoRequestSuccess(csData));
    yield put(setCurrentG());
  } catch (error) {
    yield put(stockInfoRequestError(error));
  }
}

function* allOrderRequestFlow(action) {
  try {
    const { data } = action;
    const resData = yield call(allOrderRequestApi, data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(allOrdRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(allOrdRequestError(error));
  }
}

function* ptListRequestFlow() {
  try {
    const dataList = yield call(ptListRequestApi);

    yield put(ptListRequestSuccess(dataList));
    yield put(setOddlot(false));
    yield put(setCurrentG());
  } catch (error) {
    // console.log(error)
    yield put(ptListRequestError(error));
  }
}

function* cwListRequestFlow() {
  try {
    const dataList = yield call(cwListRequestApi);

    yield put(cwListRequestSuccess(dataList));
    yield put(setOddlot(false));
    yield put(setCurrentG());
  } catch (error) {
    // console.log(error)
    yield put(cwListRequestError(error));
  }
}

function* list30RequestFlow(action) {
  try {
    const { data } = action;

    const dataList = yield call(list30RequestApi, data);

    yield put(list30RequestSuccess(dataList));
    yield put(setOddlot(false));
    yield put(setCurrentG());
  } catch (error) {
    yield put(list30RequestError(error));
  }
}

function* listMessageRequestFlow() {
  try {
    const dataList = yield call(listMessageRequestApi);

    yield put(listMessageRequestSuccess(dataList));
  } catch (error) {
    // console.log(error)
    yield put(listMessageRequestError(error));
  }
}

function* branchCodeRequestFlow() {
  try {
    const dataList = yield call(branchCodeRequestApi);

    yield put(branchCodeRequestSuccess(dataList));
  } catch (error) {
    // console.log(error)
    yield put(branchCodeRequestError(error));
  }
}

function* noicapRequestFlow() {
  try {
    const dataList = yield call(noicapRequestApi);

    yield put(noicapRequestSuccess(dataList));
  } catch (error) {
    // console.log(error)
    yield put(noicapRequestError(error));
  }
}

function* indSumRequestFlow() {
  try {
    const dataList = yield call(indSumRequestApi);

    yield put(indSumRequestSuccess(dataList));
  } catch (error) {
    // console.log(error)
    yield put(indSumRequestError(error));
  }
}

function* stockByIdRequestFlow(action) {
  try {
    const { data, board } = action;
    const dataList = yield call(stockByIdRequestApi, data, board);

    let csData = [];
    dataList.forEach((item) => {
      let symbol = _processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(getStockByIdRequestSuccess(csData));
    yield put(setCurrentG(board));
    if (board == constants.G.ODD_LOT) yield put(setOddlot(true));
    else yield put(setOddlot(false));
  } catch (error) {
    yield put(getStockByIdRequestError(error));
  }
}

function* worldIndexRequestFlow(action) {
  try {
    const worldList = yield call(worldIndexRequestApi);
    yield put(worldIndexRequestSuccess(worldList));
  } catch (error) {
    yield put(worldIndexRequestError(error));
  }
}

function* commodityRequestFlow(data) {
  let { lang } = data;
  try {
    const commodityList = yield call(commodityApi, lang);
    yield put(commodityRequestSuccess(commodityList.data));
  } catch (error) {
    yield put(commodityRequestError(error));
  }
}

function* indexRequestFlow(action) {
  try {
    // grab the data from our action
    const { data, callback } = action;

    const indexs = yield call(indexRequestApi, data);
    let indexList = [];
    indexs &&
      !!indexs.length &&
      indexs.forEach((element) => {
        indexList.push(_processMapDataIndex(element));
      });
    yield put(indexRequestSuccess(indexList));
    if (callback && typeof callback == 'function') {
      callback(indexList);
    }
  } catch (error) {
    yield put(indexRequestError(error));
  }
}

function toastHandle(message, title = i18n.t('txt-notice')) {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    title: title,
  };
  return put(setToast(toastMsg));
}

/**
 * This func try to aim for covering almost call priceboard api cases
 * @param {isProtect} param0 // this param help to protect private api (user data - must to authentication)
 */
function* getPriceboardCommonRequestFlow({ params, callback, isProtect }) {
  try {
    const resData = yield call(allOrderRequestApi, params);
    const token = yield select((state) => state.client.token);

    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.http === 401) {
        if (isProtect) {
          if (token) {
            put(logoutRequest(token));
            put(unsetClient());
            put(Logout());
          }
          removeCookie('token');
          removeCookie('authen');
          yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
        }

        toastMsg = {
          id: Math.random(),
          msg: i18n.t('txt-valid-loss-session'),
          title: i18n.t('txt-notice'),
        };
      } else {
        if (resData.data.messageNo === 99991) {
          put(lockResendOtp());
        } else {
          const msgErr = yield select(getMessage, resData);
          toastMsg = {
            id: Math.random(),
            msg: msgErr,
            title: i18n.t('txt-notice'),
          };
        }
      }

      yield put(toastHandle(toastMsg));
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    yield put(toastHandle(error));
  }
}

function* priceboardWatcher() {
  yield all([
    takeLatest(ALL_STOCK_REQUESTING, allStockRequestFlow),
    takeLatest(SNAPSHOT_REQUESTING, snapshotRequestFlow),
    takeEvery(SNAPSHOT_PART_REQUESTING, snapshotPartRequestFlow),
    takeLatest(STOCK_INFO_REQUESTING, stockInfoRequestFlow),
    takeLatest(ALL_ORD_REQUESTING, allOrderRequestFlow),
    takeLatest(PT_LIST_REQUESTING, ptListRequestFlow),
    takeLatest(CW_LIST_REQUESTING, cwListRequestFlow),
    takeLatest(LIST_30_REQUESTING, list30RequestFlow),
    takeLatest(LIST_MESSAGE_REQUESTING, listMessageRequestFlow),
    takeLatest(BRANCH_CODE_REQUESTING, branchCodeRequestFlow),
    takeLatest(NOI_CAP_REQUESTING, noicapRequestFlow),
    takeLatest(SUMMARY_REQUESTING, indSumRequestFlow),
    takeLatest(GET_STOCK_BY_ID_REQUESTING, stockByIdRequestFlow),
    takeLatest(WORLD_INDEX_REQUESTING, worldIndexRequestFlow),
    takeLatest(INDEX_REQUESTING, indexRequestFlow),
    takeLatest(COMMODITY_REQUESTING, commodityRequestFlow),
    takeEvery(GET_PRICEBOARD_COMMON_REQUEST, getPriceboardCommonRequestFlow),
  ]);
}

export default priceboardWatcher;
