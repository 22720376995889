import React, { useState, useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Col, ListGroup } from 'react-bootstrap';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { MdPhone, MdVerifiedUser } from 'react-icons/md';
import {
  makeGetToken,
  makeGetAuth,
  makeGetListAccount,
} from '../../../lib/selector';
import { v4 as uuidv4 } from 'uuid';
import {
  getsMSSettingRequest,
  setsMSSettingRequest,
  getExtSettingRequest,
  setExtSettingRequest,
  resetsMSSettingRequest,
  resetExtSettingRequest,
} from '../../customer/actions';
import { setAuth, setToast } from '../../client/actions';
import { GoUnverified } from 'react-icons/go';
import Switch from 'react-switch';
import Select from 'react-select';
import { withNamespaces } from 'react-i18next';

import './settings.scss';

const SMSRegistSwitches = ({
  notiCode,
  notiStatus,
  auth,
  token,
  dispatch,
  setSMSSettingRequesting,
  onColor,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(notiStatus == '1');
  }, [notiStatus, setSMSSettingRequesting]);

  const toggle = () => {
    if (auth && auth.isVerified) {
      submitChangeHandle();
      setChecked(!checked);
    } else {
      dispatch(setAuth());
    }
  };

  const submitChangeHandle = () => {
    const uuid = uuidv4();
    const status = checked ? '2' : '1';

    if (token) {
      const params = {
        group: 'CORE',
        rqId: uuid,
        user: token.user,
        session: token.session,
        token: auth.token,
        type: auth.type,
        cmd: 'registCustSMSNotifi',
        channel: 'WTS',
        data: {
          notifiCode: notiCode,
          custNo: token.cif,
          custNotifiSetStat: status,
        },
      };

      dispatch(setsMSSettingRequest(params));
    }
  };

  return (
    <Switch
      onChange={toggle}
      checked={checked}
      className="react-switch"
      uncheckedIcon={false}
      checkedIcon={false}
      height={18}
      width={45}
      onColor={onColor}
    />
  );
};

const ServiceSwitches = ({
  featureTp,
  regTp,
  auth,
  token,
  dispatch,
  disabled = false,
  t,
  subAcntNo,
  onColor,
}) => {
  const [checked, setChecked] = useState(regTp == 'Y');

  useEffect(() => {
    setChecked(regTp == 'Y');
  }, [subAcntNo]);

  const toggle = () => {
    if (auth && auth.isVerified) {
      submitChangeHandle();
      setChecked(!checked);
    } else {
      dispatch(setAuth());
    }
  };

  const submitChangeHandle = (state = null) => {
    const uuid = uuidv4();
    let status = checked ? 'N' : 'Y';
    if (state) {
      status = state;
    }

    if (token) {
      const params = {
        group: 'CORE',
        rqId: uuid,
        user: token.user,
        session: token.session,
        token: auth.token,
        type: auth.type,
        cmd: 'registCustExtFeature',
        channel: 'WTS',
        data: {
          featureTp: featureTp || '4',
          custNo: token.cif,
          regTp: status,
          subAcntNo: subAcntNo,
        },
      };

      dispatch(setExtSettingRequest(params));
    }
  };

  return (
    <Switch
      onChange={toggle}
      checked={checked}
      className="react-switch"
      uncheckedIcon={false}
      checkedIcon={false}
      height={18}
      width={45}
      disabled={disabled}
      onColor={onColor}
    />
  );
};

const Setting = withNamespaces('translations')(({ t }) => {
  const [chooseAcc, setChooseAcc] = useState();
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const [accounts, setAccounts] = useState([]);

  const dispatch = useDispatch();
  const auth = useSelector(makeGetAuth());
  const token = useSelector(makeGetToken());
  const lang = useSelector((state) => state.client.setting.lang || 'vi');
  const theme = useSelector((state) => state.client.currentTheme);

  const {
    setExtSettingSuccess,
    setExtSettingErrors,
    lastExtSetting,
    setExtSettingRequesting,
    sMSSetting,
    lastSMSSetting,
    setSMSSettingRequesting,
    setSMSSettingSuccess,
    setSMSSettingErrors,
    extSetting,
  } = useSelector((state) => state.customer);

  useEffect(() => {
    getCustSMSNotifi();
  }, []);

  useEffect(() => {
    if (listAccount) {
      const _chooseAcc = listAccount.find((f) => f.isDefSubAcnt === 1);
      const _accounts = listAccount.filter((o) => o.subAcntStat !== 9);
      if (_accounts && _accounts.length > 0) {
        setAccounts(_accounts);
        if (_chooseAcc) setChooseAcc(_chooseAcc);
      }
    }
  }, [listAccount]);

  useEffect(() => {
    if (lang) {
      getCustSMSNotifi();
      getExtSetting();
    }
  }, [lang]);

  useEffect(() => {
    if (chooseAcc) getExtSetting();
  }, [chooseAcc]);

  useEffect(() => {
    if (!setSMSSettingRequesting) {
      let _obj = null;
      if (sMSSetting && lastSMSSetting) {
        _obj = sMSSetting.find(
          (x) => x.notifiCode == lastSMSSetting.notifiCode
        );
      }
      if (setSMSSettingSuccess) {
        const toastMsg = {
          id: Math.random(),
          title: t('txt-success'),
          msg: `${
            _obj.custNotifiSetStat == '1'
              ? t('setting.reg')
              : t('setting.unReg')
          } "${_obj.notifiNm}" ${t('txt-success').toLowerCase()}`,
          time: new Date(),
        };

        dispatch(setToast(toastMsg));
        dispatch(resetsMSSettingRequest());
      } else {
        if (setSMSSettingErrors && setSMSSettingErrors.length > 0) {
          const toastMsg = {
            id: Math.random(),
            title: t('txt-unsuccessful'),
            msg: setSMSSettingErrors[0].body,
            time: setSMSSettingErrors[0].time,
          };

          dispatch(setToast(toastMsg));
          dispatch(resetsMSSettingRequest());
        }
      }
    }
  }, [setSMSSettingRequesting]);

  useEffect(() => {
    if (!setExtSettingRequesting) {
      let _obj = null;
      if (extSetting && lastExtSetting) {
        _obj = extSetting.find((x) => x.featureTp == lastExtSetting.featureTp);
      }

      if (setExtSettingSuccess && _obj) {
        const toastMsg = {
          id: Math.random(),
          title: t('txt-success'),
          msg: `${
            _obj.regTp_note == 'R'
              ? t('setting.reset')
              : _obj.regTp == 'Y'
              ? t('setting.reg')
              : t('setting.unReg')
          } ${t('setting.service').toLowerCase()} ${_obj.featureName} ${t(
            'txt-success'
          ).toLowerCase()}`,
          time: new Date(),
        };

        dispatch(setToast(toastMsg));
        dispatch(resetExtSettingRequest());
      } else {
        if (setExtSettingErrors && setExtSettingErrors.length > 0) {
          const toastMsg = {
            id: Math.random(),
            title: t('txt-unsuccessful'),
            msg: setExtSettingErrors[0].body,
            time: setExtSettingErrors[0].time,
          };

          dispatch(setToast(toastMsg));
          dispatch(resetExtSettingRequest());
        }
      }
    }
  }, [setExtSettingRequesting]);

  const getColor = () => {
    if (theme?.name == 'dark') {
      if (auth && auth.isVerified) return '#00ff57';
      else return '#ffe70b';
    } else {
      if (auth && auth.isVerified) return '#2db35b';
      else return '#f0ae03';
    }
  };

  const getCustSMSNotifi = () => {
    const uuid = uuidv4();

    if (token) {
      const params = {
        group: 'CORE',
        user: token.user,
        rqId: uuid,
        session: token.session,
        cmd: 'getCustSMSNotifi',
        data: {
          cif: token.cif,
          langTp: lang,
        },
      };

      dispatch(getsMSSettingRequest(params));
    }
  };

  const getExtSetting = () => {
    const uuid = uuidv4();

    if (token) {
      const params = {
        group: 'CORE',
        user: token.user,
        rqId: uuid,
        session: token.session,
        cmd: 'getCustExtFeature',
        channel: 'WTS',
        data: {
          cif: token.cif,
          langTp: lang,
          subAcntNo: '',
        },
      };

      dispatch(getExtSettingRequest(params));
    }
  };

  return (
    <PerfectScrollBar>
      <div className="d-flex justify-content-md-center account-service">
        <div className="account-panel col-md-8">
          <div className="account-panel-header">
            <span className="account-panel-header__title">
              {t('lbl-sms-reg-service')}
            </span>
            <span className={'fz_14 ' + (auth.isVerified ? 'i' : 'd')}>
              {auth && auth.isVerified ? (
                <>
                  <MdVerifiedUser className="mr-2" />
                  {t('txt-da-xacthuc')}
                </>
              ) : (
                <>
                  <GoUnverified className="mr-2" />
                  {t('txt-chua-xacthuc')}
                </>
              )}
            </span>
          </div>
          <div className="account-panel-body">
            {sMSSetting &&
              sMSSetting.length > 0 &&
              sMSSetting.map((s, i) => (
                <div
                  className="account-panel-items"
                  key={`sms-setting-item-${i}-${theme}`}
                >
                  <div className="account-panel-item">
                    <span className="account-panel-item__name">
                      {s.notifiNm}
                    </span>
                    <span className="account-panel-item__value"></span>
                    <span className="account-panel-item__action">
                      <SMSRegistSwitches
                        key={`sms-switch-${i}`}
                        auth={auth}
                        token={token}
                        dispatch={dispatch}
                        notiCode={s.notifiCode}
                        notiStatus={s.custNotifiSetStat}
                        setSMSSettingRequesting={setSMSSettingRequesting}
                        onColor={getColor()}
                      />
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="account-panel col-md-8">
          <div className="account-panel-header">
            <span className="account-panel-header__title">
              {t('lbl-other-reg-services')}
            </span>
          </div>
          <div className="account-panel-body">
            {extSetting &&
              extSetting.length > 0 &&
              extSetting
                .filter((x) => x.featureTp != 4)
                .map((ex, i) => (
                  <div
                    className="account-panel-items"
                    key={`sms-setting-item-${i}`}
                  >
                    <div className="account-panel-item">
                      <span className="account-panel-item__name">
                        {ex.featureName}
                      </span>
                      <span className="account-panel-item__value"></span>
                      <span className="account-panel-item__action">
                        <ServiceSwitches
                          key={`service-switch-${i}-${theme}`}
                          auth={auth}
                          token={token}
                          dispatch={dispatch}
                          featureTp={ex.featureTp}
                          featureName={ex.featureName}
                          regTp={ex.regTp}
                          subAcntNo={ex.subAcntNo}
                          setExtSettingRequesting={setExtSettingRequesting}
                          t={t}
                          onColor={getColor()}
                        />
                      </span>
                    </div>
                  </div>
                ))}

            {extSetting &&
              extSetting.length > 0 &&
              extSetting.some((x) => x.featureTp == 4) && (
                <div className="account-panel-items">
                  {extSetting &&
                    extSetting.length > 0 &&
                    extSetting
                      .filter((x) => x.featureTp == 4)
                      .map((ex, i) => (
                        <div className="account-panel-item" key={i}>
                          <span className="account-panel-item__name">
                            {i == 0 ? ex.featureName : ''}
                          </span>
                          <span className="account-panel-item__value">
                            {ex.subAcntNo}
                          </span>
                          <span className="account-panel-item__action">
                            <ServiceSwitches
                              key={`service-switch-${i}-${theme}`}
                              auth={auth}
                              token={token}
                              dispatch={dispatch}
                              featureTp={ex.featureTp}
                              featureName={ex.featureName}
                              regTp={ex.regTp}
                              setExtSettingRequesting={setExtSettingRequesting}
                              t={t}
                              subAcntNo={ex.subAcntNo}
                              disabled={ex.subAcntNo.startsWith('M')}
                              onColor={getColor()}
                            />
                          </span>
                        </div>
                      ))}
                </div>
              )}
          </div>
        </div>
      </div>
    </PerfectScrollBar>
  );
});

export default Setting;
