import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Svgdropdown } from '../assets/img/_arrow_dropdown.svg';

import Select, { components } from 'react-select';
import './PagingSize.scss';

export function PagingSize({ t, value, options, onChange, getOptionLabel }) {
  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <Svgdropdown />
      </components.DropdownIndicator>
    );
  };
  return (
    <div className="paging-size">
      <Select
        classNamePrefix="pagingSize-control-select"
        getOptionLabel={
          getOptionLabel ? getOptionLabel : (option) => option.label
        }
        isSearchable={false}
        value={value}
        onChange={(value) => onChange(value)}
        options={options}
        styles={styles}
        components={{ Placeholder, DropdownIndicator }}
      />
    </div>
  );
}

PagingSize.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
};

/* Style is taken from src/components/customer/settings.js:319 */
const styles = {
  control: () => ({
    display: 'flex',
    height: '24px',
    minHeight: '24px',
    backgroundColor: '#434648',
    borderRadius: '4px',
    border: 'solid 1px rgba(0, 0, 0, 0.4)',
    position: 'relative',
    width: '92px',
  }),

  span: () => ({
    display: 'none',
  }),

  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
    width: '49px',
    fontSize: '13px',
  }),
  option: (provided) => ({
    ...provided,
    padding: 5,
    fontSize: '13px',
  }),
  singleValue: (styles) => ({
    ...styles,
    padding: '2px 0px 2px 5px',
    fontSize: '13px',
    weight: 700,
    color: '#F5F5F5',
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: '#434648',
    fontSize: '13px',
    lineHeight: '20px',
  }),
};
