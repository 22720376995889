import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  unsetClient,
  industryAppend,
} from '../../../containers/client/actions';
import { CLIENT_UNSET } from '../../../containers/client/constants';
import { handleApiErrors } from '../../../lib/api-errors';
import { removeCookie } from '../../../lib/storages';

import {
  LOGIN_ERROR,
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_REQUESTING,
  LOGOUT_SUCCESS,
} from './constants';
import { Mixpanel } from '../../../lib/mixpanel';
import i18n from '../../../i18n';
import { REGISTER_NOT_OK } from '../../../containers/socket/constants';

const loginUrl = `${process.env.REACT_APP_API_URL}`;

function loginApi(username, password, deviceId, fcmToken) {
  const hashDigest = sha256(password);
  const _base64Hash = Base64.stringify(hashDigest);

  const langs = {
    vi: 'vn',
    en: 'en',
  };

  return fetch(loginUrl + '/loginAdv', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify({
      user: username,
      pass: _base64Hash,
      device: deviceId,
      fcmToken,
      lang: langs[i18n.language] || 'vn',
    }),
  })
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => {
      if (json.http !== 200) {
        throw Error(json.data.message);
      }
      return json.data;
    })
    .catch((error) => {
      throw error;
    });
}

function logoutApi(username, session) {
  return fetch(loginUrl + '/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify({
      user: username,
      session: session,
    }),
  })
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => {
      if (json.http !== 200) {
        throw Error(json.msg);
      }
      return json.msg;
    })
    .catch((error) => {
      throw error;
    });
}

function* loginFlow(username, password, deviceId, fcmToken) {
  let token;
  const _date = new Date();
  try {
    token = yield call(
      loginApi,
      username.trim(),
      password.trim(),
      deviceId,
      fcmToken
    );
    Mixpanel.login(token);
    yield put({ type: LOGIN_SUCCESS, token: token });
  } catch (error) {
    Mixpanel.login(null); // login fail
    yield put({ type: LOGIN_ERROR, error });
  } finally {
    if (yield cancelled()) {
    }
  }

  return token;
}

function* logout() {
  yield put(unsetClient());
  yield put({ type: REGISTER_NOT_OK });

  removeCookie('token');
  removeCookie('authen');
}

function* logoutFlow({ username, session }) {
  let token;
  try {
    token = yield call(logoutApi, username, session);
    Mixpanel.logout();
    yield put({ type: LOGOUT_SUCCESS });
    yield put({ type: REGISTER_NOT_OK });

    yield put(industryAppend());
  } catch (error) {
    yield put(industryAppend());
    yield put({ type: LOGOUT_ERROR, error });
  } finally {
    if (yield cancelled()) {
      //
    }
  }

  return token;
}

function* loginWatcher() {
  yield all([takeLeading(LOGOUT_REQUESTING, logoutFlow)]);
  while (true) {
    const { username, password, deviceId, fcmToken } = yield take(
      LOGIN_REQUESTING
    );

    const task = yield fork(loginFlow, username, password, deviceId, fcmToken);

    const action = yield take([
      CLIENT_UNSET,
      LOGIN_ERROR,
      LOGOUT_REQUESTING,
      'INVALID_SESSION',
      'LOG_OUT',
    ]);

    if (
      action.type === CLIENT_UNSET ||
      action.type === 'INVALID_SESSION' ||
      action.type === 'LOG_OUT'
    ) {
      yield cancel(task);
    }

    // if (action.type === LOGOUT_REQUESTING) {
    //   yield fork(logoutFlow, action.username, action.session);
    // }

    // yield call(logout);
  }
}

export default loginWatcher;
