import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Link, useLocation } from 'react-router-dom';
import './verticalNavbar.scss';

const Index = withNamespaces('translations')(({ t, params, data }) => {
  const [navOpens, setOpen] = useState([]);
  const location = useLocation();
  const toogle = (i) => {
    if (navOpens.includes(i)) {
      var _navOpens = navOpens.filter((x) => x != i);
      setOpen(_navOpens);
    } else {
      var _navOpens = [...navOpens];
      _navOpens.push(i);
      setOpen(_navOpens);
    }
  };

  useEffect(() => {
    const { typeId } = params;

    if (!!data?.length > 0) {
      data.map((item, i) => {
        if (
          navOpens &&
          typeId &&
          item.child &&
          item.child.some((x) => x.path == typeId)
        ) {
          const _navOpens = [...navOpens];
          _navOpens.push(i);
          setOpen(_navOpens);
        }
      });

      const parentCheck = data?.find((x) => x.path == typeId);
      if (
        parentCheck &&
        parentCheck.onClick &&
        typeof parentCheck.onClick == 'function'
      ) {
        parentCheck.onClick();
      } else {
        const childs = [].concat.apply(
          [],
          data.map((x) => x.child)
        );

        const childCheck = childs.find((x) => x.path == typeId);
        if (
          childCheck &&
          childCheck.onClick &&
          typeof childCheck.onClick == 'function'
        ) {
          childCheck.onClick();
        }
      }
    }
  }, [data]);

  return (
    <div className="vertical-nav">
      <PerfectScrollBar>
        {!!data?.length > 0 &&
          data.map((item, i) => {
            return (
              <div key={i} className="categories">
                <div
                  className={`categories-header ${
                    params.typeId == item.path ? 'active' : ''
                  }`}
                  onClick={() => toogle(i)}
                >
                  {!item.path && (
                    <span
                      className="d-flex gap-8 align-items-center"
                      onClick={() => {
                        if (item.onClick && typeof item.onClick == 'function') {
                          item.onClick();
                        }
                      }}
                    >
                      <span className={item.class}></span>
                      <span>{t(item.name)}</span>
                    </span>
                  )}

                  {item.path && (
                    <Link
                      to={(location) => {
                        return { ...location, pathname: item.path };
                      }}
                      className="d-flex gap-8 align-items-center"
                      onClick={() => {
                        if (item.onClick && typeof item.onClick == 'function') {
                          item.onClick();
                        }
                      }}
                    >
                      <span className={item.class}></span>
                      <span>{t(item.name)}</span>
                    </Link>
                  )}

                  {item.child && item.child.length > 0 && (
                    <span
                      className={`icon iUp2 ${
                        navOpens && navOpens.includes(i) ? '' : 'is--close'
                      }`}
                    ></span>
                  )}
                </div>

                <div className="categories-body">
                  {navOpens &&
                    navOpens.includes(i) &&
                    item.child &&
                    item.child.length > 0 &&
                    item.child.map((child, j) => {
                      return (
                        <div
                          className={`category nav-item ${
                            params.typeId == child.path ? 'active' : ''
                          }`}
                          key={`child-${j}`}
                        >
                          <Link
                            to={child.path}
                            onClick={() => {
                              if (
                                child.onClick &&
                                typeof child.onClick == 'function'
                              ) {
                                child.onClick();
                              }
                            }}
                          >
                            {t(child.name)}
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </PerfectScrollBar>
    </div>
  );
});

export default Index;
