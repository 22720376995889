import { all } from 'redux-saga/effects';
import ClientSaga from '../containers/client/sagas';

import PriceboardSaga from '../containers/banggia/sagas';
import CashSaga from '../containers/cash/sagas';
import SummarySaga from '../containers/summary/sagas';
import CustomerSaga from '../containers/customer/sagas';
import PrivDataSaga from '../containers/privData/sagas';

import LoginSaga from '../components/modal/login/sagas';
import AuthenSaga from '../components/modal/auth/sagas';
import ResetPassSaga from '../components/modal/resetPass/sagas';
import OrdCondSaga from '../components/modal/ordCond/sagas';
import StockDetailSaga from '../components/modal/stockDetail/sagas';
import PersonalSaga from '../containers/personal/sagas';
import BondSaga from '../containers/bond/sagas';
import NewsSaga from '../containers/news/sagas';
import PinefolioSaga from '../containers/pinefolio/sagas';
import MarginCompetitonSaga from '../containers/marginCompetition/sagas';
import DerivativeSaga from '../containers/derivative/sagas';
import NotificationSaga from '../components/modal/notification/sagas';

export default function* IndexSaga() {
  yield all([
    ClientSaga(),
    LoginSaga(),
    AuthenSaga(),
    ResetPassSaga(),
    OrdCondSaga(),
    StockDetailSaga(),
    PriceboardSaga(),
    CashSaga(),
    SummarySaga(),
    CustomerSaga(),
    PrivDataSaga(),
    PersonalSaga(),
    BondSaga(),
    NewsSaga(),
    PinefolioSaga(),
    MarginCompetitonSaga(),
    DerivativeSaga(),
    NotificationSaga(),
  ]);
}
