import React, { Fragment, useState, useEffect } from 'react';
import DatagridGroup from '../../../table/DatagridGroup';
import {
  BsCaretLeftFill,
  BsCaretRightFill,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
} from 'react-icons/bs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { translate } from 'react-i18next';
import ImgLoading from '../../../../assets/img/loading.gif';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';
import { makeGetIsAsc, makeGetSortField } from '../../../../lib/selector';
import Loading from '../../../loading';

import {
  changeSortField,
  changeSortDirection,
} from '../../../../containers/banggia/actions';

const Thead = ({
  row1,
  row2,
  isPc,
  handleSort,
  setIsPC,
  handleLoading,
  sortField,
  isAsc,
  setSortField,
  setIsAsc,
}) => {
  useEffect(() => {}, [isAsc]);
  useEffect(() => {}, [sortField]);

  const showSortIcon = (field) => {
    if (sortField == field) {
      if (isAsc)
        return (
          <span onClick={() => _handleSort(field)}>
            <BsFillCaretUpFill
              className="fz_10 position-relative"
              style={{ marginLeft: '0.15rem' }}
            />
          </span>
        );
      else
        return (
          <span onClick={() => _handleSort(field)}>
            <BsFillCaretDownFill
              className="fz_10 position-relative"
              style={{ marginLeft: '0.15rem' }}
            />
          </span>
        );
    } else return null;
  };

  const _handleSort = (fieldName) => {
    setSortField(fieldName);
    setIsAsc();
  };

  return (
    <>
      <tr>
        {row1.map((r, i) => (
          <th
            rowSpan={r.rowSpan}
            colSpan={r.colSpan}
            className={r.className}
            key={`r1_${i}`}
            onClick={() => r.key && _handleSort(r.key)}
          >
            <span>{r.text}</span>
            {showSortIcon(r.key)}
          </th>
        ))}
      </tr>
      <tr>
        {row2.map((r, i) => (
          <th
            rowSpan={r.rowSpan}
            colSpan={r.colSpan}
            className={r.className}
            key={`r2_${i}`}
            onClick={() =>
              r.key &&
              !(r.key == 'changePc' || r.key == 'change') &&
              _handleSort(r.key)
            }
          >
            {r.key == 'changePc' || r.key == 'change' ? (
              <>
                <BsCaretLeftFill
                  className="tbl-toggle-left"
                  onClick={() => setIsPC()}
                />
                <span className="filterIcon" onClick={() => _handleSort(r.key)}>
                  {isPc ? '%' : '+/-'}
                </span>
                {showSortIcon(r.key)}
                <BsCaretRightFill
                  className="tbl-toggle-right"
                  onClick={() => setIsPC()}
                />
              </>
            ) : (
              <>
                <span>{r.text}</span>
                {showSortIcon(r.key)}
              </>
            )}
          </th>
        ))}
      </tr>
    </>
  );
};

class TblGroup extends React.Component {
  state = {
    isPc: false,
    partSnapList: [],
    isLoading: false,
  };

  componentDidUpdate(preProps) {
    const { partSnap, sortField, isAsc } = this.props;

    if (partSnap && preProps.partSnap != partSnap) {
      this.setState(
        {
          partSnapList: partSnap,
        },
        () => this.handleSort()
      );
    }

    if (isAsc != preProps.isAsc) {
      this.handleSort();
    }

    if (sortField != preProps.sortField) {
      this.handleSort();
    }
  }

  handleLoading = (_isLoading = false, callback) => {
    this.setState({ isLoading: _isLoading }, callback);
  };

  handleIsPC = () => {
    const { isPc } = this.state;
    this.setState({
      isPc: !isPc,
    });
  };

  handleSort = () => {
    const { partSnapList } = this.state;
    const { sortField, isAsc } = this.props;
    let _partSnapList = [...partSnapList];

    const mySort = (field) => {
      const rs = _partSnapList.sort(function (a, b) {
        return a[field] - b[field];
      });

      return rs;
    };

    if (sortField == 'sym')
      _partSnapList = isAsc
        ? sortBy(_partSnapList, 'sym')
        : sortBy(_partSnapList, 'sym').reverse();
    else {
      const _sort = mySort(sortField);
      _partSnapList = isAsc ? _sort : _sort.reverse();
    }

    this.setState({
      partSnapList: _partSnapList,
    });
  };

  setSortField = (fieldName) => {
    this.props.dispatch(changeSortField(fieldName));
  };

  setIsAsc = () => {
    this.props.dispatch(changeSortDirection());
  };

  render() {
    const { isPc, partSnapList, isLoading } = this.state;
    const {
      t,
      pinnedRow,
      typeIndex,
      isAsc,
      sortField,
      snapshotRequesting,
      snapshotSuccessful,
      currentG,
    } = this.props;

    const heightTb =
      195 +
      (pinnedRow && !!pinnedRow.length ? pinnedRow.length * 25 : 0) +
      (typeIndex === 1 ? 130 : 70);

    const row1 = [
      {
        key: 'sym',
        text: t('txt-symbol'), // Mã CK
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'r',
        text: t('txt-ref'), // T.C
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'c',
        text: t('txt-ceil'), // Trần
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'f',
        text: t('txt-floor'), // Sàn
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        text: t('txt-bid'), // Bên mua
        rowSpan: '1',
        colSpan: '6',
      },
      {
        text: t('txt-match'), // Khớp lệnh
        className: '',
        rowSpan: '1',
        colSpan: '3',
      },
      {
        text: t('txt-ask'), // Bên bán
        rowSpan: '1',
        colSpan: '6',
      },
      {
        key: 'lot',
        text: t('txt-total-vol'), // Tổng KL
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'highPrice',
        text: t('txt-high'), // Cao
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'lowPrice',
        text: t('txt-low'), // Thấp
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'avePrice',
        text: t('txt-ave'), // Trung bình
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        text: t('txt-foreign'), // Khối ngoại
        rowSpan: '1',
        colSpan: '2',
      },
    ];

    const row2 = [
      {
        key: 'bP3',
        text: `${t('txt-price')} 3`, // Giá 3
        className: 'filterIcon fw-500',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bV3',
        text: `${t('txt-vol')} 3`, // KL 3
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bP2',
        text: `${t('txt-price')} 2`, // Giá 2
        className: 'filterIcon fw-500 ',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bV2',
        text: `${t('txt-vol')} 2`, // KL 2
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bP1',
        text: `${t('txt-price')} 1`, // Giá 1
        className: 'filterIcon fw-500 ',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bV1',
        text: `${t('txt-vol')} 1`, // KL 1
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'lastPrice',
        text: t('txt-price'), // Giá
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'lastVolume',
        text: t('txt-vol'), // KL
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: `${isPc ? 'changePc' : 'change'}`,
        text: `${isPc ? '%' : '+/-'}`, // +/-
        className: 'po-relative',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oP1',
        text: `${t('txt-price')} 1`, // Giá 1
        className: 'filterIcon fw-500 ',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oV1',
        text: `${t('txt-vol')} 1`, // KL 1
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oP2',
        text: `${t('txt-price')} 2`, // Giá 2
        className: 'filterIcon fw-500',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oV2',
        text: `${t('txt-vol')} 2`, // KL 2
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oP3',
        text: `${t('txt-price')} 3`, // Giá 3
        className: 'filterIcon fw-500',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oV3',
        text: `${t('txt-vol')} 3`, // KL 3
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'fBVol',
        text: t('txt-buy'), // Mua
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'fSVolume',
        text: t('txt-sell'), // Bán
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
    ];

    return (
      <Fragment>
        <div>
          <DatagridGroup
            resource={[]}
            classname="table table-bordered table-fix"
            isPc={isPc}
          >
            <Thead
              handleSort={this.handleSort}
              setIsPC={this.handleIsPC}
              row1={row1}
              row2={row2}
              isPc={isPc}
              handleLoading={this.handleLoading}
              isAsc={isAsc}
              setIsAsc={this.setIsAsc}
              sortField={sortField}
              setSortField={this.setSortField}
            />
          </DatagridGroup>
        </div>

        {snapshotRequesting && <Loading />}

        <PerfectScrollbar style={{ height: `calc(100vh - ${heightTb}px)` }}>
          <DatagridGroup
            resource={partSnapList}
            classname="table table-fix price-table border-top-0"
            isPc={isPc}
            key={currentG}
          />
          <div
            style={{
              height: '200px',
              marginTop: '10px',
              color: '#c5c5c6',
              textAlign: 'center',
              fontSize: '13px',
              lineHeight: '16px',
            }}
          >
            <span className="text-tb-copyright">{t('txt-copyright')}</span>
          </div>

          {isLoading && (
            <div className="position-absolute loading-bottom">
              <img src={ImgLoading} alt="loading" style={{ height: '22px' }} />
              Loading
            </div>
          )}
        </PerfectScrollbar>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getIsAsc = makeGetIsAsc();
  const getSortField = makeGetSortField();

  const mapStateToProps = (state) => {
    return {
      isAsc: getIsAsc(state),
      sortField: getSortField(state),
      currentG: state.socket.currentG,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(TblGroup)
);
