import React, { Component } from 'react';
import produce, { current } from 'immer';
import { filter, find, isEqual, map, remove } from 'lodash';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ImgLoading from '../../../assets/img/loading.gif';
import {
  clearPartRequest,
  cwListRequest,
  getStockByIdRequest,
  list30Request,
  ptListRequest,
  snapshotRequest,
  setPriceTableSymbols,
  setOddlot,
} from '../../../containers/banggia/actions';
import {
  saveCategoryRequest,
  setCategory,
  setCategorySelected,
} from '../../../containers/client/actions';
import {
  setRegSymbol,
  unsetRegSymbol,
} from '../../../containers/socket/actions';
import { WebSocketContext } from '../../../containers/socket/webSocket';
import {
  makeGetAllStock,
  makeGetCWList,
  makeGetCategory,
  makeGetCategorySelected,
  makeGetDefaultAccount,
  makeGetList30,
  makeGetPart,
  makeGetPinnedRow,
  makeGetSnapShot,
  makeGetSocketStatus,
  makeGetToken,
  makeGetTypeIndex,
} from '../../../lib/selector';
import { saveState } from '../../../lib/storages';
import NavbarPriceboard from './layout/navbar';
import TblGroup from './layout/tblGroup';
import TblThoaThuan from './layout/tblThoaThuan';
import TblWatchList from './layout/tblWatchList';
import { constants } from '../../../util/constant';

class PriceTable extends Component {
  // Add the constructor
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isPt: null,
      ptGr: null,
    };
  }

  scrollTo = (id) => {
    let _rootRow = document.getElementById(id + 'row');
    if (_rootRow) {
      _rootRow.scrollIntoView();
      _rootRow.classList.add('bg-active');
      setTimeout(() => {
        if (_rootRow) _rootRow.classList.remove('bg-active');
      }, 3000);
    }
  };

  fetchCategoryData = (catalogSelected, oldG = 'G1') => {
    const { snapshotRequest, clearPartRequest, setOddlot } = this.props;
    clearPartRequest();

    if (
      catalogSelected.type === 'group' &&
      catalogSelected.groupName != 'CPNGANH'
    ) {
      this.setState({ isLoading: true });
      return this.fetchGroup(
        catalogSelected.name,
        catalogSelected.groupName,
        oldG
      );
    } else {
      // CPNganh categories is group type but using as a watchlist, cause it's external source.
      this.setState({
        isPt: catalogSelected.groupName == 'CPNGANH' ? 'CPNGANH' : null,
      });
      setOddlot(false);
      return snapshotRequest(catalogSelected.value.join(','));
    }
  };

  fetchGroup = (_path, grName, oldG = 'G1') => {
    const {
      getStockByIdRequest,
      allStock,
      unsetRegSymbol,
      regSym,
      partSnap,
      currentG,
    } = this.props;

    if (grName == 'OddLot' && _path) {
      const marketID = _path.toUpperCase().includes('HSX')
        ? '10'
        : _path.toUpperCase().includes('HNX')
        ? '02'
        : '03';
      this.setState({ isLoading: true });
      getStockByIdRequest(marketID, constants.G.ODD_LOT);

      const symbolGr =
        marketID == '10'
          ? map(
              filter(
                allStock,
                (o) => o.post_to === 'HOSE' || o.post_to === 'STO'
              ),
              'stock_code'
            )
          : marketID == '02'
          ? map(
              filter(
                allStock,
                (o) => o.post_to === 'HNX' || o.post_to === 'STX'
              ),
              'stock_code'
            )
          : map(
              filter(
                allStock,
                (o) => o.post_to === 'UPCOM' || o.post_to === 'UPX'
              ),
              'stock_code'
            );

      this._handleLeaveData(regSym);
      unsetRegSymbol();

      this.setState({ isPt: null }, () => {
        this._handleRegisData(symbolGr.join(','), constants.G.ODD_LOT);
      });

      return;
    }

    if (grName == 'Buyin' && _path) {
      const marketID = _path.toUpperCase().includes('HSX')
        ? '10'
        : _path.toUpperCase().includes('HNX')
        ? '02'
        : '03';
      this.setState({ isLoading: true });
      getStockByIdRequest(marketID, constants.G.BUY_IN);

      const symbolGr =
        marketID == '10'
          ? map(
              filter(
                allStock,
                (o) => o.post_to === 'HOSE' || o.post_to === 'STO'
              ),
              'stock_code'
            )
          : marketID == '02'
          ? map(
              filter(
                allStock,
                (o) => o.post_to === 'HNX' || o.post_to === 'STX'
              ),
              'stock_code'
            )
          : map(
              filter(
                allStock,
                (o) => o.post_to === 'UPCOM' || o.post_to === 'UPX'
              ),
              'stock_code'
            );

      this._handleLeaveData(regSym, currentG); // TODO: leave current G
      unsetRegSymbol();

      this.setState({ isPt: null }, () => {
        this._handleRegisData(symbolGr.join(','), constants.G.BUY_IN);
      });

      return;
    }

    _path =
      _path === 'HOSE' || _path === 'STO'
        ? 'HSX'
        : _path === 'STX'
        ? 'HNX'
        : _path === 'UPX'
        ? 'UPCOM'
        : _path;

    if (_path === grName) {
      // get all group
      const marketID =
        grName === 'HSX'
          ? '10'
          : grName === 'HNX'
          ? '02'
          : grName === 'UPCOM'
          ? '03'
          : '10';
      this.setState({ isLoading: true });
      getStockByIdRequest(marketID);

      // grName = grName === 'HSX' ? 'HOSE' : grName;
      // console.log('allStock', grName, allStock);
      const symbolGr =
        grName === 'HSX'
          ? map(
              filter(
                allStock,
                (o) => o.post_to === 'HOSE' || o.post_to === 'STO'
              ),
              'stock_code'
            )
          : grName === 'HNX'
          ? map(
              filter(
                allStock,
                (o) => o.post_to === 'HNX' || o.post_to === 'STX'
              ),
              'stock_code'
            )
          : map(
              filter(
                allStock,
                (o) => o.post_to === 'UPCOM' || o.post_to === 'UPX'
              ),
              'stock_code'
            );

      this._handleLeaveData(regSym, oldG);
      unsetRegSymbol();

      this._handleRegisData(symbolGr.join(','));
      this.setState({ isPt: null });
    } else {
      // TODO: get api watchlist or industry group
      if (_path === 'txt-c' || _path == 'GD thoả thuận') {
        const { ptListRequest } = this.props;
        ptListRequest();
        this.setState({ isPt: 'PT', ptGr: grName, isLoading: false });
      } else if (_path === 'Chứng quyền') {
        const { cwListRequest } = this.props;
        cwListRequest();
        this.setState({ isPt: 'CW', isLoading: false });

        this._handleLeaveData(regSym);
        unsetRegSymbol();

        const symbolGr = map(
          filter(
            allStock,
            (o) =>
              (o.post_to === 'HOSE' || o.post_to === 'STO') &&
              o.stock_code.length === 8
          ),
          'stock_code'
        );

        this._handleRegisData(symbolGr.join(','));
      } else if (_path === 'VN30' || _path === 'HNX30') {
        const { list30Request } = this.props;
        list30Request(_path === 'VN30' ? 'HSX30' : _path);
        this.setState({ isPt: 'LIST30', isLoading: false });
      } else if (_path.startsWith('cp-nganh')) {
        this.setState({ isPt: 'CPNGANH', isLoading: false });
      } else if (_path === 'txt-post-close') {
        debugger;
        const symbolGr = map(
          filter(allStock, (o) => o.post_to === 'HNX' || o.post_to === 'STX'),
          'stock_code'
        );
        this.setState({ isLoading: true });
        this._handleLeaveData(regSym, currentG); // TODO: leave current G
        getStockByIdRequest('02', constants.G.POST_CLOSE);
        unsetRegSymbol();
        this.setState({ isPt: null }, () => {
          this._handleRegisData(symbolGr.join(','), constants.G.POST_CLOSE);
        });
      } else {
        this.setState({ isLoading: false, isPt: null });
      }
    }
  };

  _handleRegisData = (symbol, board = constants.G.DEFAULT) => {
    let ws = this.context;
    const { setRegSymbol } = this.props;

    const payload = {
      action: 'join',
      data: symbol,
      board: board,
    };

    setPriceTableSymbols(symbol);
    setRegSymbol(symbol);
    return ws.sendMessage(payload);
  };

  _handleLeaveData = (symbol, board = constants.G.DEFAULT) => {
    let ws = this.context;

    const payload = {
      action: 'leave',
      data: symbol,
      board: board,
    };
    return ws.sendMessage(payload);
  };

  _regisSocketData = (categoryId = 'vn30') => {
    const { category, unsetRegSymbol, regSym } = this.props;
    if (!category || category.length == 0) return;
    const catalogSelected = find(
      category,
      (o) => o.path && o.path.endsWith('/bang-gia/' + categoryId)
    );
    if (catalogSelected && catalogSelected.type !== 'group') {
      this._handleLeaveData(regSym);
      unsetRegSymbol();

      this._handleRegisData(catalogSelected.value.join(','));
    } else {
      return null;
    }
  };

  _leaveSocketData = (categoryId) => {
    const { category } = this.props;

    const catalogSelected = find(
      category,
      (o) => o.path && o.path.endsWith('/bang-gia/' + categoryId)
    );

    if (catalogSelected && catalogSelected.type !== 'group') {
      return this._handleLeaveData(catalogSelected.value.join(','));
    }
    return null;
  };

  _handlePinStock = (stock) => {
    const { category, categoryId, setCategory, token } = this.props;
    // Avoid mutate category state
    const newCategoryList = produce(category, (draft) => {
      const currentCategory = draft.find(
        (cat) => cat.path && cat.path.endsWith(categoryId)
      );
      currentCategory.pinnedRow.push(stock);
    });
    const newCategory = newCategoryList.find(
      (cat) => cat.path && cat.path.endsWith(categoryId)
    );
    this._handleSaveCategory(newCategory);
    setCategory(newCategoryList);
    if (!token) {
      saveState('category', newCategoryList);
    }
  };

  _handleUnPinStock = (stock) => {
    const { category, categoryId, setCategory, token } = this.props;
    // Avoid mutate category state
    const newCategoryList = produce(category, (draft) => {
      const currentCategory = draft.find(
        (cat) => cat.path && cat.path.endsWith(categoryId)
      );
      remove(currentCategory.pinnedRow, (item) => item === stock);
    });
    const newCategory = newCategoryList.find(
      (cat) => cat.path && cat.path.endsWith(categoryId)
    );
    this._handleSaveCategory(newCategory);
    setCategory(newCategoryList);
  };

  _handleSaveCategory = (_newCategory) => {
    const { token, saveCategoryRequest } = this.props;

    if (!token) return;
    const uuid = uuidv4();

    const resData = {
      group: 'BACK',
      user: token.user,
      session: token.session,
      cmd: 'SET_WAT',
      rqId: uuid,
      channel: 'WTS',
      data: _newCategory,
    };

    saveCategoryRequest(resData);
  };

  componentDidMount() {
    const { catalogSelected, currentG } = this.props;
    if (catalogSelected) this.fetchCategoryData(catalogSelected, currentG);
  }

  componentDidUpdate(prevProps) {
    const {
      categoryId,
      socketReady,
      catalogSelected,
      allStock,
      partSuccessful,
      partErrors,
      cwList,
      list30,
      unsetRegSymbol,
      regSym,
      serverStatus,
      category,
      snapshot,
      history,
      snapshotRequest,
      clearPartRequest,
      setCategorySelected,
    } = this.props;

    const { isPt, isLoading } = this.state;
    const oldG = prevProps.currentG;

    if (
      catalogSelected &&
      (!prevProps.catalogSelected ||
        !isEqual(catalogSelected, prevProps.catalogSelected))
    ) {
      this.fetchCategoryData(catalogSelected, oldG);
      setCategorySelected(catalogSelected);
    }

    if (allStock && !!allStock.length && allStock !== prevProps.allStock) {
      if (catalogSelected) {
        this.fetchCategoryData(catalogSelected, oldG);
      }
    }

    if (serverStatus == 'on' && serverStatus !== prevProps.serverStatus) {
      if (catalogSelected) {
        this.fetchCategoryData(catalogSelected, oldG);
      }
    }

    if (socketReady && socketReady !== prevProps.socketReady && category) {
      // register new socket data
      this._regisSocketData(categoryId);
    }

    if (categoryId && categoryId != prevProps.categoryId && category) {
      // for case: change watchlist
      // leave old socket data
      this._leaveSocketData(prevProps.categoryId);

      // register new socket data
      this._regisSocketData(categoryId);
    }

    if (
      (partSuccessful && partSuccessful !== prevProps.partSuccessful) ||
      (partErrors && !!partErrors.length && partErrors !== prevProps.partErrors)
    ) {
      this.setState({ isLoading: false });
    }

    if (isPt === 'CW' && cwList && cwList !== prevProps.cwList) {
      // load list chung quyen
      const symbolGr = map(cwList, 'code');
      clearPartRequest();
      snapshotRequest(symbolGr.join(','));
    }

    if (isPt === 'LIST30' && list30 && list30 !== prevProps.list30) {
      // load list 30
      clearPartRequest();
      if (list30.list) {
        const _dtVN30 = list30.list.split(',').sort().join(',');
        snapshotRequest(_dtVN30);
        this._handleLeaveData(regSym, oldG);
        unsetRegSymbol();
        this._handleRegisData(list30.list);
      }
    }

    if (isPt === 'CPNGANH' && snapshot && snapshot !== prevProps.snapshot) {
      clearPartRequest();
      if (snapshot) {
        const syms = snapshot.map((x) => x.sym).join(',');
        this._handleLeaveData(regSym);
        unsetRegSymbol();
        this._handleRegisData(syms);
      }
    }

    if (category && categoryId && category != prevProps.category) {
      // for case: reload page re-regist socket
      this._regisSocketData(categoryId);
    }

    const stock = history.location.state?.stock;

    if (stock && !isLoading) {
      this.scrollTo(stock);
    }
  }

  render() {
    const {
      snapshot,
      snapshotRequesting,
      snapshotSuccessful,
      typeIndex,
      partSnap,
      categoryId,
      pinnedRow,
      catalogSelected,
      isOddlot,
    } = this.props;

    const { isPt, ptGr } = this.state;
    return (
      <div className="d-flex flex-column price-board">
        <NavbarPriceboard categoryId={categoryId} />
        {isPt === 'PT' && <TblThoaThuan ptGr={ptGr} typeIndex={typeIndex} />}
        {(isPt === 'LIST30' || isPt === 'CW' || isPt === 'CPNGANH') &&
          catalogSelected &&
          catalogSelected.type === 'group' && (
            <TblGroup
              partSnap={snapshot}
              pinnedRow={pinnedRow}
              typeIndex={typeIndex}
              snapshotRequesting={snapshotRequesting}
              snapshotSuccessful={snapshotSuccessful}
            />
          )}
        {!isPt && catalogSelected && catalogSelected.type === 'group' && (
          <TblGroup
            partSnap={partSnap}
            pinnedRow={pinnedRow}
            typeIndex={typeIndex}
          />
        )}
        {!isPt && catalogSelected && catalogSelected.type !== 'group' && (
          <TblWatchList
            snapshot={snapshot}
            snapshotRequesting={snapshotRequesting}
            snapshotSuccessful={snapshotSuccessful}
            pinnedRow={pinnedRow}
            typeIndex={typeIndex}
            handlePinStock={this._handlePinStock}
            handleUnPinStock={this._handleUnPinStock}
          />
        )}
        {this.state.isLoading && (
          <div className="position-absolute loading-bottom">
            <img src={ImgLoading} alt="loading" style={{ height: '22px' }} />
            Loading
          </div>
        )}
        <input id="priceboard_oddlot" type="hidden" value={isOddlot} />
      </div>
    );
  }
}

PriceTable.contextType = WebSocketContext;

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getDefaultAccount = makeGetDefaultAccount();
  const getCategory = makeGetCategory();
  const getAllStock = makeGetAllStock();
  const getTypeIndex = makeGetTypeIndex();
  const getCWList = makeGetCWList();
  const getList30 = makeGetList30();
  const getPart = makeGetPart();
  const getSnapShot = makeGetSnapShot();
  const getPinnedRow = makeGetPinnedRow();
  const getCatalogSelected = makeGetCategorySelected();
  const getSocketStatus = makeGetSocketStatus();

  const mapStateToProps = (state, props) => {
    return {
      token: getToken(state),
      defaultAccount: getDefaultAccount(state),
      category: getCategory(state),
      allStock: getAllStock(state),
      typeIndex: getTypeIndex(state),
      cwList: getCWList(state),
      list30: getList30(state),
      partSnap: getPart(state),
      partErrors: state.priceBoard.partIdErrors,
      partSuccessful: state.priceBoard.partIdSuccessful,
      snapshot: getSnapShot(state),
      snapshotRequesting: state.priceBoard.snapshotRequesting,
      snapshotSuccessful: state.priceBoard.snapshotSuccessful,
      socketReady: state.socket.socketReady,
      regSym: state.socket.regSym,
      pinnedRow: getPinnedRow(state, props),
      catalogSelected: getCatalogSelected(state, props),
      serverStatus: getSocketStatus(state),
      isOddlot: state.priceBoard.isOddlot,
      currentG: state.socket.currentG,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps, {
  snapshotRequest,
  ptListRequest,
  cwListRequest,
  list30Request,
  clearPartRequest,
  // snapshotPartRequest,
  getStockByIdRequest,
  setCategory,
  saveCategoryRequest,
  setRegSymbol,
  unsetRegSymbol,
  setOddlot,
  setCategorySelected,
})(PriceTable);
