import { constants } from '../../util/constant';
import {
  GET_PRIV_DATA_REQUEST,
  REGISTER_REQUESTING,
} from '../socket/constants';
import {
  CANCEL_ORDER_REQUESTING,
  CANCEL_ORDER_REQUEST_ERROR,
  CANCEL_ORDER_REQUEST_SUCCESS,
  CASH_BALANCE_REQUESTING,
  CASH_BALANCE_REQUEST_ERROR,
  CASH_BALANCE_REQUEST_SUCCESS,
  EDIT_ORDER_REQUESTING,
  EDIT_ORDER_REQUEST_ERROR,
  EDIT_ORDER_REQUEST_SUCCESS,
  MATCH_ORDER_REQUESTING,
  MATCH_ORDER_REQUEST_ERROR,
  MATCH_ORDER_REQUEST_SUCCESS,
  NEW_ORDER_REQUESTING,
  NEW_ORDER_REQUEST_ERROR,
  NEW_ORDER_REQUEST_SUCCESS,
  POSITION_REQUESTING,
  POSITION_REQUEST_ERROR,
  POSITION_REQUEST_SUCCESS,
  PURCHASE_POWER_REQUESTING,
  PURCHASE_POWER_REQUEST_ERROR,
  PURCHASE_POWER_REQUEST_SUCCESS,
  SHARE_BALANCE_REQUESTING,
  SHARE_BALANCE_REQUEST_ERROR,
  SHARE_BALANCE_REQUEST_SUCCESS,
  STOCK_BALANCE_REQUESTING,
  STOCK_BALANCE_REQUEST_ERROR,
  STOCK_BALANCE_REQUEST_SUCCESS,
  SYMBOL_INFO_REQUESTING,
  SYMBOL_INFO_REQUEST_ERROR,
  SYMBOL_INFO_REQUEST_SUCCESS,
  SYMBOL_INFO_RESETING,
  SET_ORDER_SYMBOL,
  SET_ORDER_TYPE,
  SET_ORDER_PRICE,
  SET_ORDER_QUANTITY,
  LIST_PUT_THROUGH_REQUESTING,
  LIST_PUT_THROUGH_REQUEST_SUCCESS,
  LIST_PUT_THROUGH_REQUEST_ERROR,
  SET_ORDER_G,
} from './constants';

export const cashBalanceRequest = function cashBalanceRequest(data) {
  return {
    type: CASH_BALANCE_REQUESTING,
    data,
  };
};

export const cashBalanceRequestSuccess = function cashBalanceRequestSuccess(
  cashBalance
) {
  return {
    type: CASH_BALANCE_REQUEST_SUCCESS,
    cashBalance,
  };
};

export const cashBalanceRequestError = function cashBalanceRequestError(error) {
  return {
    type: CASH_BALANCE_REQUEST_ERROR,
    error,
  };
};

export const shareBalanceRequest = function shareBalanceRequest(data) {
  return {
    type: SHARE_BALANCE_REQUESTING,
    data,
  };
};

export const shareBalanceRequestSuccess = function shareBalanceRequestSuccess(
  shareBalance
) {
  return {
    type: SHARE_BALANCE_REQUEST_SUCCESS,
    shareBalance,
  };
};

export const shareBalanceRequestError = function shareBalanceRequestError(
  error
) {
  return {
    type: SHARE_BALANCE_REQUEST_ERROR,
    error,
  };
};

export const purchasePowerRequest = function purchasePowerRequest(data) {
  return {
    type: PURCHASE_POWER_REQUESTING,
    data,
  };
};

export const purchasePowerRequestSuccess = function purchasePowerRequestSuccess(
  purchasePower
) {
  return {
    type: PURCHASE_POWER_REQUEST_SUCCESS,
    purchasePower,
  };
};

export const purchasePowerRequestError = function purchasePowerRequestError(
  error
) {
  return {
    type: PURCHASE_POWER_REQUEST_ERROR,
    error,
  };
};

export const stockBalanceRequest = function stockBalanceRequest(data) {
  return {
    type: STOCK_BALANCE_REQUESTING,
    data,
  };
};

export const stockBalanceRequestSuccess = function stockBalanceRequestSuccess(
  stockBalance
) {
  return {
    type: STOCK_BALANCE_REQUEST_SUCCESS,
    stockBalance,
  };
};

export const stockBalanceRequestError = function stockBalanceRequestError(
  error
) {
  return {
    type: STOCK_BALANCE_REQUEST_ERROR,
    error,
  };
};

export const positionsRequest = function positionsRequest(data) {
  return {
    type: POSITION_REQUESTING,
    data,
  };
};

export const positionsRequestSuccess = function positionsRequestSuccess(
  positions
) {
  return {
    type: POSITION_REQUEST_SUCCESS,
    positions,
  };
};

export const positionsRequestError = function positionsRequestError(error) {
  return {
    type: POSITION_REQUEST_ERROR,
    error,
  };
};

export const newOrderRequest = function newOrderRequest(data, successCallback) {
  return {
    type: NEW_ORDER_REQUESTING,
    data,
    successCallback,
  };
};

export const newOrderRequestSuccess = function newOrderRequestSuccess(
  newOrder
) {
  return {
    type: NEW_ORDER_REQUEST_SUCCESS,
    newOrder,
  };
};

export const newOrderRequestError = function newOrderRequestError(error) {
  return {
    type: NEW_ORDER_REQUEST_ERROR,
    error,
  };
};

export const editOrderRequest = function editOrderRequest(data) {
  return {
    type: EDIT_ORDER_REQUESTING,
    data,
  };
};

export const editOrderRequestSuccess = function editOrderRequestSuccess(
  editOrder
) {
  return {
    type: EDIT_ORDER_REQUEST_SUCCESS,
    editOrder,
  };
};

export const editOrderRequestError = function editOrderRequestError(error) {
  return {
    type: EDIT_ORDER_REQUEST_ERROR,
    error,
  };
};

export const cancelOrderRequest = function cancelOrderRequest(data) {
  return {
    type: CANCEL_ORDER_REQUESTING,
    data,
  };
};

export const cancelOrderRequestSuccess = function cancelOrderRequestSuccess(
  cancelOrder
) {
  return {
    type: CANCEL_ORDER_REQUEST_SUCCESS,
    cancelOrder,
  };
};

export const cancelOrderRequestError = function cancelOrderRequestError(error) {
  return {
    type: CANCEL_ORDER_REQUEST_ERROR,
    error,
  };
};

export const symbolInfoRequest = function symbolInfoRequest(data) {
  return {
    type: SYMBOL_INFO_REQUESTING,
    data,
  };
};

export const symbolInfoReset = function symbolInfoReset(data) {
  return {
    type: SYMBOL_INFO_RESETING,
    data,
  };
};

export const symbolInfoRequestSuccess = function symbolInfoRequestSuccess(
  symbolInfo
) {
  return {
    type: SYMBOL_INFO_REQUEST_SUCCESS,
    symbolInfo,
  };
};

export const symbolInfoRequestError = function symbolInfoRequestError(error) {
  return {
    type: SYMBOL_INFO_REQUEST_ERROR,
    error,
  };
};

export const matchOrderRequest = function matchOrderRequest(data) {
  return {
    type: MATCH_ORDER_REQUESTING,
    data,
  };
};

export const matchOrderRequestSuccess = function matchOrderRequestSuccess(
  matchOrder
) {
  return {
    type: MATCH_ORDER_REQUEST_SUCCESS,
    matchOrder,
  };
};

export const matchOrderRequestError = function matchOrderRequestError(error) {
  return {
    type: MATCH_ORDER_REQUEST_ERROR,
    error,
  };
};

export const setOrderSymbol = function setOrderSymbol(symbol) {
  return {
    type: SET_ORDER_SYMBOL,
    symbol,
  };
};

export const setOrderType = function setOrderType(orderType) {
  return {
    type: SET_ORDER_TYPE,
    orderType,
  };
};

export const setOrderPrice = (price) => {
  return {
    type: SET_ORDER_PRICE,
    price,
  };
};

export const setOrderQuantity = (quantity) => {
  return {
    type: SET_ORDER_QUANTITY,
    quantity,
  };
};

export const listPutThroughRequest = (data) => {
  return {
    type: LIST_PUT_THROUGH_REQUESTING,
    data,
  };
};

export const listPutThroughRequestSucsess = (listPutThrough) => {
  return {
    type: LIST_PUT_THROUGH_REQUEST_SUCCESS,
    listPutThrough,
  };
};

export const listPutThroughRequestError = (error) => {
  return {
    type: LIST_PUT_THROUGH_REQUEST_ERROR,
    error,
  };
};

export const setOrderG = (data = constants.G.DEFAULT) => {
  debugger;
  return {
    type: SET_ORDER_G,
    data,
  };
};

export const registPrivateRequest = () => {
  return {
    type: REGISTER_REQUESTING,
  };
};

export const getPrivDataRequest = (params, callback, isProtect = false) => {
  return {
    type: GET_PRIV_DATA_REQUEST,
    params,
    callback,
    isProtect,
  };
};
