import {
  LOGIN_REQUESTING,
  LOG_OUT,
  LOGOUT_REQUESTING,
  RESET_LOGIN,
} from './constants';

export const resetLoginRequest = () => {
  return {
    type: RESET_LOGIN,
  };
};

export const loginRequest = function loginRequest({
  username,
  password,
  deviceId,
  fcmToken,
}) {
  return {
    type: LOGIN_REQUESTING,
    username,
    password,
    deviceId,
    fcmToken,
  };
};

export const Logout = function logout() {
  return {
    type: LOG_OUT,
  };
};

export const logoutRequest = function logoutRequest({ user, session }) {
  return {
    type: LOGOUT_REQUESTING,
    username: user,
    session,
  };
};
