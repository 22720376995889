import React, { createContext } from 'react';
import PubSub from 'pubsub-js';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';
import { handlePrivateApiErrors } from '../../lib/api-errors';
import { getCookie, removeCookie } from '../../lib/storages';
import {
  accountTypeDetect,
  isRebootServerTime,
  recursionTask,
} from '../../util';
import {
  process3210,
  process3220,
  process3250,
} from '../../util/priceboard_krx';
import {
  forcePrivateRegisterHandle,
  registerPrivateDone,
  serverOff,
  serverOn,
  setSID,
  updateListAccount,
} from './actions';
import {
  process3410,
  process3420,
  process3450,
} from '../../util/derivativePriceBoard';
import {
  REGISTER_NOT_OK,
  REGISTER_OK,
  SERVER_OFF,
  SERVER_ON,
} from './constants';
import i18n from '../../i18n';
import { setDataSocketConditionOrder } from '../derivative/actions';
import {
  TYPE_SOCKET_CONDITION,
  TYPE_SOCKET_CONDITION_ACTIVE,
} from '../../util/constant';

const socketUrl = `${process.env.REACT_APP_SOCKET_URL}`;

const WebSocketContext = createContext(null);

export { WebSocketContext };

export default ({ children }) => {
  let socket;
  let ws;
  let reconnectCount = 0;

  const dispatch = useDispatch();

  const sendMessage = (message) => {
    if (message && message.action === 'join') {
      socket.emit('regs', JSON.stringify(message), (dt) => {
        if (dt === 'OK') {
          dispatch({ type: SERVER_ON });
        } else {
          console.debug('WS reg failed', { socket });
          dispatch({ type: SERVER_OFF });
        }
      });
    } else {
      socket.emit('regs', JSON.stringify(message), (rs) => {
        if (message && message.action == 'register') {
          if (rs == 'OK') dispatch({ type: REGISTER_OK });
          else {
            dispatch({ type: REGISTER_NOT_OK });
            const token = getCookie('token');

            // if user is logged but register private fail then toast noti
            if (token) {
              // const toastMsg = {
              //   id: Math.random(),
              //   msg: i18n.t('txt-regis-unsuccessful'),
              //   time: new Date(),
              //   title: i18n.t('txt-error'),
              // };
              // dispatch(setToast(toastMsg));
              console.error(i18n.t('txt-regis-unsuccessful'));
            }
          }
        }
      });
    }
  };

  const sendPing = () => {
    let msg = { action: 'ping', mode: 'async', data: {} };
    socket.emit('regs', JSON.stringify(msg));
  };

  if (!socket) {
    socket = io.connect(socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      // reconnectionAttempts: 5,
    });

    socket.on('connect', () => {
      if (socket?.id) dispatch(setSID(socket?.id));
      dispatch(serverOn());
    });

    // Fired when an error occurs
    socket.on('error', (error) => {
      console.debug('WS error', { socket, error });
    });

    // Fired upon a connection timeout
    socket.on('connect_timeout', (timeout) => {
      console.debug('WS connect_timeout', { socket, timeout });
    });

    // Fired upon a connection error
    socket.on('connect_error', (error) => {
      console.debug('WS connect_error', { socket, error });
    });

    socket.on('reconnect', (attemptNumber) => {
      // this event fire when reconnect success
      console.log('reconnect success with new id' + socket.id);
      // Todos: dispatch new SID
      dispatch(setSID(socket.id));
      reconnectCount = 0;
    });

    socket.on('disconnect', (reason) => {
      // this event fire after disconnected immediately
      console.log('disconnect');
      dispatch(serverOff());
    });

    // Fired upon an attempt to reconnect
    socket.on('reconnecting', (attemptNumber) => {});

    // Fired upon a reconnection attempt error
    socket.on('reconnect_error', (error) => {
      console.debug('WS reconnect_error', { socket, error });
      // this event fire whenever reconnect error
      reconnectCount++;
      if (reconnectCount >= 5 && !isRebootServerTime()) {
        window.location.reload(true);
      }
    });

    socket.on('reconnect_failed', () => {
      // this event only fire when reconnectionAttempts is limit number
      console.debug('WS reconnect_failed', { socket });
      console.log('reconnect_failed');
      dispatch(serverOff());
    });

    socket.on('public', (msg) => {
      const payload = msg.data;
      if (payload && payload?.id != 1101) {
        publicHandler(msg);
      }
    });

    socket.on('private', (msg) => {
      const action = msg.action;
      if (action === 'ping') {
        sendPing();
        dispatch(registerPrivateDone());
      } else {
        privateHandler(msg, dispatch);
      }
    });

    ws = {
      socket: socket,
      sendMessage,
    };

    // TODO: 02/12/2020 update 10 giay ping server
    // TODO: replace setInterval with recursion setTimeout

    recursionTask(() => sendPing(), 10 * 1000);
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

const publicHandler = (event) => {
  const payload = event.data;
  const board = payload?.brd ?? 'G1';

  switch (payload.id) {
    case 3210: {
      process3210(payload);

      const detailModal = document.getElementById('stock-detail-oddlot')?.value;
      if (detailModal != undefined) {
        const isOddLot = board == 'G4' ? 'true' : 'false';
        if (isOddLot == detailModal)
          PubSub.publish(`${payload.sym}_${board}`, payload);
      }
      break;
    }
    case 3220:
    case 3223: {
      process3220(payload);
      const detailModal = document.getElementById('stock-detail-oddlot')?.value;
      if (detailModal != undefined) {
        const isOddLot = board == 'G4' ? 'true' : 'false';
        if (isOddLot == detailModal)
          PubSub.publish(`${payload.sym}_${board}`, payload);
      }
      break;
    }
    case 3250:
      process3250(payload);
      break;

    // Derivative
    case 3410: {
      process3410(payload);
      PubSub.publish(`${payload.sym}_${board}`, payload);
      break;
    }

    case 3420: {
      process3420(payload);
      PubSub.publish(`${payload.sym}_${board}`, payload);
      break;
    }

    case 3450:
      process3450(payload);
      break;
    default:
      break;
  }
};

const privateHandler = (event, dispatch) => {
  const payload = event.data;
  const action = event.action;

  if (action === 'logout') {
    removeCookie('token');
    removeCookie('authen');
    dispatch({ type: 'CLIENT_UNSET' });
  }

  if (action === 'Event') {
    if (TYPE_SOCKET_CONDITION.includes(payload?.eventType)) {
      dispatch(setDataSocketConditionOrder(payload));
    }
    dispatch({ type: 'Event', resData: payload });
    // process event lenh
  } else if (action == 'disconnected') {
    // Disconect private from backend side, so re-regist private socket
    dispatch(forcePrivateRegisterHandle(true));
  } else {
    // other private event
    console.log('socket private');
    const _apiValid = handlePrivateApiErrors(payload, action);
    if (_apiValid && _apiValid.status) {
      if (action === 'ListAccount') {
        const resData = _apiValid.data;
        let listAccount = [],
          defaultAccount;
        if (resData && resData.normal && !!resData.normal.length) {
          resData.normal.forEach((element) => {
            const type = accountTypeDetect(element);
            const opts = {
              ...element,
              value: element.subAcntNo,
              label: element.subAcntNo + ' - ' + type,
            };
            listAccount.push(opts);
            if (element.isDefSubAcnt) defaultAccount = opts;
          });
        }
        dispatch(updateListAccount(listAccount));
        dispatch({ type: 'DefaultAccount', resData: defaultAccount });
      } else if (action === 'getSymbolInfo') {
        const _dt = {
          list: _apiValid.data.list ? [..._apiValid.data.list] : [],
          dataMargin: _apiValid.dataMargin ? _apiValid.dataMargin : null,
        };
        dispatch({ type: event.action, resData: _dt });
      } else if (action !== 'ping') {
        dispatch({ type: event.action, resData: _apiValid.data });
      }
    } else {
      dispatch({ type: _apiValid.type, resData: _apiValid.data });
    }
  }
};
