import i18n from '../../../i18n';
import {
  RECALL_ALERT_REQUEST,
  RECALL_COUNT_REQUEST,
  RECALL_MARKET_REQUEST,
  RECALL_IMPORTANT_REQUEST,
  SET_BACKABLE,
  SET_NOTIFICATION_PERMISSION,
  SET_TAB_ACTIVE,
  SET_TITLE,
  TYPES,
} from './constants';

const initialState = {
  tabActive: TYPES.IMPORTANT,
  backable: false,
  title: 'txt-notifications-board',
  recallCountApi: false,
  recallAlertApi: false,
  recallMarketApi: false,
  recallImportantApi: false,
  permission: '',
};

const reducer = function bondReducer(state = initialState, action) {
  switch (action.type) {
    case 'INVALID_SESSION':
    case 'LOG_OUT':
    case 'CLIENT_UNSET':
      return initialState;

    case SET_TAB_ACTIVE: {
      return {
        ...state,
        tabActive: action.type,
      };
    }

    case SET_BACKABLE: {
      const _title = state.title;

      return {
        ...state,
        backable: action.state,
        title: !action.state ? 'txt-notifications-board' : _title,
      };
    }

    case SET_TITLE: {
      return {
        ...state,
        title: action.title,
      };
    }

    case RECALL_COUNT_REQUEST: {
      return {
        ...state,
        recallCountApi: !state.recallCountApi,
      };
    }

    case SET_NOTIFICATION_PERMISSION: {
      return {
        ...state,
        permission: action.permission,
      };
    }

    case RECALL_ALERT_REQUEST: {
      return {
        ...state,
        recallAlertApi: !state.recallAlertApi,
      };
    }

    case RECALL_MARKET_REQUEST: {
      return {
        ...state,
        recallMarketApi: !state.recallMarketApi,
      };
    }

    case RECALL_IMPORTANT_REQUEST: {
      return {
        ...state,
        recallImportantApi: !state.recallImportantApi,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
