import { ORDER_PANEL_TP, constants } from '../../util/constant';
import {
  CLOSE_ALL_DVX_POS_ERROR,
  CLOSE_ALL_DVX_POS_REQUEST,
  CLOSE_ALL_DVX_POS_SUCCESS,
  CLOSE_REVERSE_DVX_POS_REQUEST,
  CONTRACT_ACTIVE_SET,
  DERIVATIVE_COMMON_REQUEST,
  DERIVATIVE_COMMON_REQUEST2,
  DERIVATIVE_COMMON_REQUEST3,
  GET_ACTV_DVX_ORDER_ERROR,
  GET_ACTV_DVX_ORDER_REQUEST,
  GET_ACTV_DVX_ORDER_SUCCESS,
  GET_CFM_CLOSE_REVT_DVX_POS_ERROR,
  GET_CFM_CLOSE_REVT_DVX_POS_REQUEST,
  GET_CFM_CLOSE_REVT_DVX_POS_SUCCESS,
  GET_DETAIL_REQUEST,
  GET_DETAIL_REQUEST2,
  GET_DETAIL_RESET,
  GET_DVX_CLOSED_POS_ERROR,
  GET_DVX_CLOSED_POS_REQUEST,
  GET_DVX_CLOSED_POS_SUCCESS,
  GET_DVX_CURR_POS_ERROR,
  GET_DVX_CURR_POS_REQUEST,
  GET_DVX_CURR_POS_SUCCESS,
  GET_DVX_MATCH_ORDER_ERROR,
  GET_DVX_MATCH_ORDER_REQUEST,
  GET_DVX_MATCH_ORDER_SUCCESS,
  GET_DVX_PNL_RPT_ERROR,
  GET_DVX_PNL_RPT_REQUEST,
  GET_DVX_PNL_RPT_SUCCESS,
  GET_DVX_PP_ERROR,
  GET_DVX_PP_REQUEST,
  GET_DVX_PP_SUCCESS,
  GET_DVX_TOTAL_ASSET_ERROR,
  GET_DVX_TOTAL_ASSET_REQUEST,
  GET_DVX_TOTAL_ASSET_SUCCESS,
  GET_LIST_DVX_ERROR,
  GET_LIST_DVX_REQUEST,
  GET_LIST_DVX_SUCCESS,
  GET_ORDER_DVX_CORE_REQUEST,
  GET_PRICEBOARD_REQUEST,
  GET_STOCK_TRADE,
  GET_TOP_STOCK_INFLUENCE_REQUEST,
  LEAVE_SOCKET_DRVX,
  PLACE_ORDER_DVX_CORE_REQUEST,
  PLACE_ORDER_DVX_REQUEST,
  PUSH_CONDITION_ORDER_DATA_REALTIME,
  REGISTER_SOCKET_DRVX,
  SET_CURRENT_SYMBOL,
  SET_DEFAULT_TAB,
  SET_DEPOSIT_WITHDRAWTH_MARGIN_MODAL,
  SET_DERIVATIVE_ORDER,
  SET_ORDER_PRICE,
  SET_ORDER_QUANTITY,
  SET_SHOW_DETAIL_MODAL,
  SET_SHOW_OPEN_ORDERS_PANEL,
  SET_SHOW_ORDER_PANEL,
  SET_SHOW_PLACE_ORDER_PANEL,
  SET_SHOW_POSITION_PANEL,
  SET_TAB_ORDER,
  SORT_DIRECTION_CHANGE,
  SORT_FIELD_CHANGE,
  SWITCH_DVX_LIST,
} from './constants';

export const contractActiveSet = (data) => {
  return {
    type: CONTRACT_ACTIVE_SET,
    data,
  };
};

export const getDerivativePriceboardRequest = (data) => {
  return {
    type: GET_PRICEBOARD_REQUEST,
    data,
  };
};

export const getDerivativeDetailRequest = (
  symbol,
  board = constants.G.DEFAULT,
  callback = null
) => {
  return {
    type: GET_DETAIL_REQUEST,
    symbol,
    board,
    callback,
  };
};

export const getDerivativeDetailRequest2 = (
  symbol,
  board = constants.G.DEFAULT,
  callback = null,
  finallyCallback = null
) => {
  return {
    type: GET_DETAIL_REQUEST2,
    symbol,
    board,
    callback,
    finallyCallback,
  };
};

export const getStockTradeRequest = (
  symbol,
  board = constants.G.DEFAULT,
  callback = null
) => {
  return {
    type: GET_STOCK_TRADE,
    symbol,
    board,
    callback,
  };
};

export const resetDerivativeDetailRequest = () => {
  return {
    type: GET_DETAIL_RESET,
  };
};

export const changeSortDirection = () => {
  return {
    type: SORT_DIRECTION_CHANGE,
  };
};

export const changeSortField = (sortField) => {
  return {
    type: SORT_FIELD_CHANGE,
    sortField,
  };
};

export const setCurrentSymbol = (sym = '') => {
  return {
    type: SET_CURRENT_SYMBOL,
    sym,
  };
};

export const setShowPlaceOrderPanel = (isShowPlaceOrderPanel) => {
  return {
    type: SET_SHOW_PLACE_ORDER_PANEL,
    isShowPlaceOrderPanel,
  };
};

export const setShowAssetPanel = (isShowAssetPanel) => {
  return {
    type: SET_SHOW_OPEN_ORDERS_PANEL,
    isShowAssetPanel,
  };
};

export const setShowPositionPanel = (isShowPositionPanel) => {
  return {
    type: SET_SHOW_POSITION_PANEL,
    isShowPositionPanel,
  };
};

export const getListDvxRequest = (params) => {
  return {
    type: GET_LIST_DVX_REQUEST,
    params,
  };
};

export const getListDvxSuccess = (listDvx) => {
  return {
    type: GET_LIST_DVX_SUCCESS,
    listDvx,
  };
};

export const getListDvxError = (error) => {
  return {
    type: GET_LIST_DVX_ERROR,
    error,
  };
};

export const placeOrderDvxRequest = (params, callback, isProtect = true) => {
  return {
    type: PLACE_ORDER_DVX_REQUEST,
    params: params,
    callback,
    isProtect,
  };
};

export const placeOrderDvxRequestCore = (
  params,
  callback,
  isProtect = true
) => {
  return {
    type: PLACE_ORDER_DVX_CORE_REQUEST,
    params: params,
    callback,
    isProtect,
  };
};

export const getOrderDvxCondition = (params, callback, isProtect = true) => {
  return {
    type: GET_ORDER_DVX_CORE_REQUEST,
    params: params,
    callback,
    isProtect,
  };
};

export const getDvxPPRequest = (params) => {
  return {
    type: GET_DVX_PP_REQUEST,
    params,
  };
};

export const getDvxPPSuccess = (dvxPP) => {
  return {
    type: GET_DVX_PP_SUCCESS,
    dvxPP,
  };
};

export const getDvxPPError = (error) => {
  return {
    type: GET_DVX_PP_ERROR,
    error,
  };
};

export const getDvxCurrPosRequest = (params) => {
  return {
    type: GET_DVX_CURR_POS_REQUEST,
    params,
  };
};

export const getDvxCurrPosSuccess = (dvxCurrPos) => {
  return {
    type: GET_DVX_CURR_POS_SUCCESS,
    dvxCurrPos,
  };
};

export const getDvxCurrPosError = (error) => {
  return {
    type: GET_DVX_CURR_POS_ERROR,
    error,
  };
};

export const getActvDvxOrdrRequest = (params) => {
  return {
    type: GET_ACTV_DVX_ORDER_REQUEST,
    params,
  };
};
export const getActvDvxOrdrSuccess = (actvDvxOrder) => {
  return {
    type: GET_ACTV_DVX_ORDER_SUCCESS,
    actvDvxOrder,
  };
};

export const getActvDvxOrdrError = (error) => {
  return {
    type: GET_ACTV_DVX_ORDER_ERROR,
    error,
  };
};

export const getDvxMatchOrderRequest = (params) => {
  return {
    type: GET_DVX_MATCH_ORDER_REQUEST,
    params,
  };
};

export const getDvxMatchOrderSuccess = (dvxMatchOrder) => {
  return {
    type: GET_DVX_MATCH_ORDER_SUCCESS,
    dvxMatchOrder,
  };
};

export const getDvxMatchOrderError = (error) => {
  return {
    type: GET_DVX_MATCH_ORDER_ERROR,
    error,
  };
};

export const getDvxClosePosRequest = (params) => {
  return {
    type: GET_DVX_CLOSED_POS_REQUEST,
    params,
  };
};

export const getDvxClosePosSuccess = (dvxClosePos) => {
  return {
    type: GET_DVX_CLOSED_POS_SUCCESS,
    dvxClosePos,
  };
};

export const getDvxClosePosError = (error) => {
  return {
    type: GET_DVX_CLOSED_POS_ERROR,
    error,
  };
};

export const getDvxPnLRptRequest = (params) => {
  return {
    type: GET_DVX_PNL_RPT_REQUEST,
    params,
  };
};

export const getDvxPnLRptSuccess = (dvxPnlRpt) => {
  return {
    type: GET_DVX_PNL_RPT_SUCCESS,
    dvxPnlRpt,
  };
};

export const getDvxPnLRptError = (error) => {
  return {
    type: GET_DVX_PNL_RPT_ERROR,
    error,
  };
};

export const getDvxTotalAssetRequest = (params) => {
  return {
    type: GET_DVX_TOTAL_ASSET_REQUEST,
    params,
  };
};

export const getDvxTotalAssetSuccess = (dvxTotalAsset) => {
  return {
    type: GET_DVX_TOTAL_ASSET_SUCCESS,
    dvxTotalAsset,
  };
};

export const getDvxTotalAssetError = (error) => {
  return {
    type: GET_DVX_TOTAL_ASSET_ERROR,
    error,
  };
};

export const getDerivativeSymbol = (params) => {
  return {
    type: GET_PRICEBOARD_REQUEST,
    params,
  };
};

export const closeAllDvxPosRequest = (params, callback) => {
  return {
    type: CLOSE_ALL_DVX_POS_REQUEST,
    params,
    callback,
  };
};

export const closeAllDvxPosSuccess = (closeAllDvxPosResp) => {
  return {
    type: CLOSE_ALL_DVX_POS_SUCCESS,
    closeAllDvxPosResp,
  };
};

export const closeAllDvxPosError = (error) => {
  return {
    type: CLOSE_ALL_DVX_POS_ERROR,
    error,
  };
};

export const getCfmClsRevtDvxPosRequest = (params, callback) => {
  return {
    type: GET_CFM_CLOSE_REVT_DVX_POS_REQUEST,
    params,
    callback,
  };
};

export const getCfmClsRevtDvxPosSuccess = (cfmClsRevtDvxPos) => {
  return {
    type: GET_CFM_CLOSE_REVT_DVX_POS_SUCCESS,
    cfmClsRevtDvxPos,
  };
};

export const getCfmClsRevtDvxPosError = (error) => {
  return {
    type: GET_CFM_CLOSE_REVT_DVX_POS_ERROR,
    error,
  };
};

export const closeDvxPostionRequest = (params, callback) => {
  return {
    type: CLOSE_ALL_DVX_POS_REQUEST,
    params,
    callback,
  };
};

export const setShowDetailModal = (status = false) => {
  return {
    type: SET_SHOW_DETAIL_MODAL,
    status,
  };
};

export const getTopStockInfluenceRequest = (marketId, callback) => {
  return {
    type: GET_TOP_STOCK_INFLUENCE_REQUEST,
    marketId,
    callback,
  };
};

export const closeReverseDvxPosRequest = (params, callback) => {
  return {
    type: CLOSE_REVERSE_DVX_POS_REQUEST,
    params,
    callback,
  };
};

export const setDerivativeOrder = (order) => {
  return {
    type: SET_DERIVATIVE_ORDER,
    order,
  };
};

export const setDrvOrderPrice = (price) => {
  return {
    type: SET_ORDER_PRICE,
    price,
  };
};

export const setDrvOrderQuantity = (quantity, type = ORDER_PANEL_TP.NORMAL) => {
  return {
    type: SET_ORDER_QUANTITY,
    quantity,
    type,
  };
};

export const getDrvCommonRequest = (params, callback, isProtect = false) => {
  return {
    type: DERIVATIVE_COMMON_REQUEST,
    params,
    callback,
    isProtect,
  };
};

export const getDrvCommonRequest2 = (params, callback, isProtect = false) => {
  return {
    type: DERIVATIVE_COMMON_REQUEST2,
    params,
    callback,
    isProtect,
  };
};

export const getDrvCommonRequest3 = (params, callback, isProtect = false) => {
  return {
    type: DERIVATIVE_COMMON_REQUEST3,
    params,
    callback,
    isProtect,
  };
};

export const setDefaultTab = (tab) => {
  return {
    type: SET_DEFAULT_TAB,
    tab,
  };
};

export const switchDvrxList = (seltp) => {
  return {
    type: SWITCH_DVX_LIST,
    seltp,
  };
};

export const registSocketDrvx = (contracts, component) => {
  return {
    type: REGISTER_SOCKET_DRVX,
    contracts,
    component,
  };
};

export const leaveSocketDrvx = (contracts, component) => {
  return {
    type: LEAVE_SOCKET_DRVX,
    contracts,
    component,
  };
};

export const setDepositWithdrawthMarginModal = (show) => {
  return {
    type: SET_DEPOSIT_WITHDRAWTH_MARGIN_MODAL,
    show,
  };
};

export const setDataSocketConditionOrder = (data) => {
  return {
    type: PUSH_CONDITION_ORDER_DATA_REALTIME,
    data,
  };
};

export const setTabOrder = (data) => {
  return {
    type: SET_TAB_ORDER,
    data,
  };
};
export const reCallApiRequest = (type) => {
  return {
    type: type,
  };
};
