import mixpanel, { track } from 'mixpanel-browser';
import { bondConstants, constants } from '../util/constant';
import { getBondProductByType } from '../containers/bond/utils';
import packageJson from '../../package.json';

const platform = 'WTS';
const kycStatus = '';

export const MixConstants = {
  BOND_TYPE: [
    { id: '2', name: 'OTC' },
    { id: '3', name: 'Listed' },
    { id: '4', name: 'Odd Lots' },
  ],

  LOCATIONS: {
    QUICK_BAR: 'Quick Bar',
    MARGIN_LIST: 'Margin List',
    CONDITIONAL_ORDER: 'Tab Conditional Order',
    PRICE_BOARD: 'Price Board',
    ASSET_PORTFOLIO: 'Asset Portfolio',
    NAVBAR: 'Navbar',
    PINEFOLIO: 'Pinefolio',
    POPUP: 'Popup',
  },

  INVESTMENT_TYPE: {
    DERIVATIVE: 'Derivatives',
    EQUITIES: 'Equities',
  },

  ORDER_LIST: {
    NORMAL: 'Normal Order',
    CONDITIONAL: 'Conditional Order',
    PUT_THROUGH: 'Put-through Order',
    HISTORY: 'Order History',
  },

  DERIVATIVE_POSITION_TYPE: {
    CLOSE: 'Close Position',
    OPEN: 'Open Position',
  },
};

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },

  openApp: (token) => {
    const date = new Date();
    mixpanel.register({ 'App Version': `v.${packageJson.version}` });

    if (!token) {
      mixpanel.register({
        Platform: 'WTS',
        'Login Status': 'Not Login',
      });

      mixpanel.people.set({ 'Time of Last Visit': date.toISOString() });
      mixpanel.track('Open App');
    } else {
      mixpanel.register({
        Platform: platform,
        'Login Status': 'Login',
        CIF: token?.cif,
        'Implementation Method': 'Client-Side',
      });
      mixpanel.identify(token.cif);
      mixpanel.people.set({ 'Time of Last Visit': date.toISOString() });
      mixpanel.track('Open App');
    }
  },

  closeApp: (props) => {
    mixpanel.track('Close App');
  },

  login: (token = null) => {
    const date = new Date();
    mixpanel.register({
      'Implementation Method': 'Client-Side',
    });

    if (token) {
      const vsd = token.vsd;
      mixpanel.identify(token.cif);
      mixpanel.track('Login', {
        'Login Status': 'Login',
        'Login Method': 'Password',
      });
      mixpanel.register({
        'Login Status': 'Login',
        CIF: token?.cif,
      });

      if (vsd) {
        mixpanel.people.set({
          Username: token.user,
          $name: token.cif,
          'Time of Last Visit': date.toISOString(),
          'KYC Status': 'Complete VSD Account',
        });

        mixpanel.register_once({
          'KYC Status': 'Complete VSD Account',
        });
      } else {
        mixpanel.people.set_once({
          'KYC Status': 'Unidentified',
        });

        mixpanel.register_once({ 'KYC Status': 'Unidentified' });
      }
    } else {
      mixpanel.register({
        Platform: platform,
        'Login Status': 'Failed',
        'Login Method': 'Password',
      });

      mixpanel.track('Login', {
        'Login Status': 'Failed',
        'Login Method': 'Password',
      });

      mixpanel.people.set({
        'Time of Last Visit': date.toISOString(),
      });
    }
  },

  logout: () => {
    const date = new Date();
    mixpanel.register({
      'Login Status': 'Not Login',
    });
    mixpanel.track('Logout');
    mixpanel.people.set({
      'Last Seen': date.toISOString(),
    });
  },

  viewScreen: (
    screenName,
    screenPath,
    previousScreen,
    screenType = 'Feature'
  ) => {
    mixpanel.track('View Screen', {
      'Screen Name': screenName,
      'Screen Type': screenType,
      'Screen Path': screenPath,
      'Screen URL': window.location?.href,
      'Previous Screen': previousScreen || '',
    });
  },

  time_event: (name = 'Exit Screen') => {
    mixpanel.time_event(name);
  },

  getMoreInfor: (props = null) => {
    const path = window.location.hash.replace('#', '');
    const _props = { 'Screen Name': path };
    if (props) {
      mixpanel.track('Get More Info', { ...props, ..._props });
    } else {
      mixpanel.track('Get More Info', _props);
    }
  },

  register: (props) => {
    mixpanel.track('Register', props);
  },

  order: (props) => {
    mixpanel.track('Start Order', props);
  },

  // View watchlist event
  viewWatchlist: (props) => {
    mixpanel.track('View Watchlist', props);
  },

  // View group event
  viewStockList: (props) => {
    mixpanel.track('View Stock List', props);
  },

  viewTicketInfo: (props) => {
    mixpanel.track('View Ticker Info', props);
  },

  analyzeTicker: (props) => {
    mixpanel.track('Analyze Ticker', props);
  },

  viewNewsBoard: () => {
    mixpanel.track('View News Board');
  },

  viewBondBoard: () => {
    mixpanel.track('View PineBond Board');
  },

  viewAssets: (props) => {
    mixpanel.track('View Asset', props);
  },

  viewMarginDebt: (props) => {
    mixpanel.track('View Margin Debt', props);
  },

  contactPinetree: () => {
    mixpanel.track('Contact Pinetree');
  },

  viewOrderList: (props) => {
    mixpanel.track('View Order List', props);
  },

  bond: {
    viewTickerInfo: (ticker, tickerType, location, locationDetail) => {
      mixpanel.track('View Ticker Info', {
        Ticker: ticker,
        'Ticker Type': 'PineB ' + getBondProductByType(tickerType),
        'Ticker Location': location,
        'Ticker Location Detail': locationDetail,
      });
    },
    viewProductList: (value) => {
      mixpanel.track('View Product List', {
        'Product Type': 'PineB ' + getBondProductByType(value),
      });
    },
    viewFunctionTab: (value) => {
      let eventName = '';
      if (value == bondConstants.tabs.ORDER_LIST) eventName = 'View Order List';
      if (value == bondConstants.tabs.DEAL_LIST) eventName = 'View Deal List';
      if (value == bondConstants.tabs.PORTFOLIO) eventName = 'View Portfolio';

      mixpanel.track(eventName);
    },
    startOrder: (ticker, orderSide, tickerType, location) => {
      mixpanel.track('Start Order', {
        Ticker: ticker,
        'Order Side': orderSide,
        'Ticker Type': 'PineB ' + getBondProductByType(tickerType),
        Location: location,
      });
    },
    viewPage: () => {
      mixpanel.track('View Bond Board');
    },
    resetFilter: (fieldName, fieldType = 'Bond') => {
      mixpanel.track('Reset Filter', {
        'Filter Name': fieldName,
        'Filter Type': fieldType,
      });
    },
    filter: (
      investmentAmount,
      bondType,
      term,
      investorType,
      issuer,
      productCode,
      fromDate,
      toDate,
      productType,
      status
    ) => {
      mixpanel.track('Filter', {
        'Investment Amount': investmentAmount,
        'Bond Type':
          MixConstants.BOND_TYPE.find((x) => x.id == bondType)?.name || '',
        Term: term,
        'Investor Type': investorType,
        Issuer: issuer,
        'Product Code': productCode,
        'From Date': fromDate,
        'To Date': toDate,
        'Product Type': productType
          ? 'PineB ' + getBondProductByType(productType)
          : 'All Type',
        Status: status,
      });
    },
    getMoreInfor: (screenName, infoGroup, infoDetail) => {
      mixpanel.track('Get More Info', {
        'Screen Name': screenName,
        'Info Group': infoGroup,
        'Info Detail': infoDetail,
      });
    },
    demo: (productCode, productType = constants.bondProductType.Var) => {
      mixpanel.track('Demo', {
        'Product Code': productCode,
        'Product Type': 'PineB ' + getBondProductByType(productType),
      });
    },
    previewMoneyFlow: (
      productCode,
      productType = constants.bondProductType.Var
    ) => {
      mixpanel.track('Preview Money Flow', {
        'Product Code': productCode,
        'Product Type': 'PineB ' + getBondProductByType(productType),
      });
    },
  },

  equities: {
    viewOrderList: (orderListType, buttonLocation) => {
      mixpanel.track('View Order List', {
        'Order List Type': orderListType,
        'Button Location': buttonLocation,
        'Investment Type': MixConstants.INVESTMENT_TYPE.EQUITIES,
      });
    },

    viewAsset: (buttonLocation, assetDetail) => {
      mixpanel.track('View Asset', {
        'Button Location': buttonLocation,
        'Asset Detail': assetDetail,
        'Investment Type': MixConstants.INVESTMENT_TYPE.EQUITIES,
      });
    },
  },

  derivative: {
    viewPage: () => {
      mixpanel.track('View Derivatives Board');
    },
    viewTicketInfor: (
      ticker,
      location = MixConstants.LOCATIONS.PRICE_BOARD
    ) => {
      mixpanel.track('View Ticker Info', {
        Ticker: ticker,
        'Ticker Type': 'Derivatives',
        'Ticker Location': location, // Only in drvx priceboard
      });
    },
    viewOrderList: (orderListType, buttonLocation) => {
      mixpanel.track('View Order List', {
        'Order List Type': orderListType,
        'Button Location': buttonLocation,
        'Investment Type': MixConstants.INVESTMENT_TYPE.DERIVATIVE,
      });
    },
    viewStatement: (statementType = 'P&L Report') => {
      mixpanel.track('View Statement', {
        'Investment Type': MixConstants.INVESTMENT_TYPE.DERIVATIVE,
        'Statement Type': statementType,
      });
    },
    viewAsset: (buttonLocation, assetDetail) => {
      mixpanel.track('View Asset', {
        'Button Location': buttonLocation,
        'Asset Detail': assetDetail,
        'Investment Type': MixConstants.INVESTMENT_TYPE.DERIVATIVE,
      });
    },
    viewPosition: (position, buttonLocation) => {
      mixpanel.track('View Position', {
        Position: position,
        'Button Location': buttonLocation,
      });
    },
    startOrder: (ticker, orderType, orderSide, location) => {
      mixpanel.track('Start Order', {
        Ticker: ticker,
        'Order Type': orderType,
        'Order Side': orderSide == 1 ? 'Long' : 'Short',
        'Ticker Type': 'Derivatives',
        Location: location,
      });
    },
    analyzeTicker: (ticker, infoGroup, infoType) => {
      mixpanel.track('Analyze Ticker', {
        Ticker: ticker,
        'Info Group': infoGroup,
        'Info Type': infoType,
      });
    },
  },

  news: {
    readNews: (source, type, location, title, link, releaseDate) => {
      mixpanel.track('Read News', {
        'News Source': source,
        'News Type': type,
        'News Location': location,
        'News Title': title,
        Hyperlink: link,
        'Release Date': releaseDate,
      });
    },
  },
};

export let Mixpanel = actions;
