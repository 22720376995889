import {
  call,
  put,
  takeLatest,
  all,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import i18n from '../../i18n';
import { handleApiErrors } from '../../lib/api-errors';
import { getMessage } from '../../lib/selector';
import { removeCookie, setCookie } from '../../lib/storages';
import { setToast, unsetClient } from '../client/actions';
import {
  getActvDvxOrdrSuccess,
  getCfmClsRevtDvxPosSuccess,
  getDvxClosePosSuccess,
  getDvxCurrPosSuccess,
  getDvxMatchOrderSuccess,
  getDvxPPSuccess,
  getDvxPnLRptSuccess,
  getDvxTotalAssetSuccess,
  getListDvxSuccess,
} from './actions';
import {
  PLACE_ORDER_DVX_CORE_REQUEST,
  GET_ACTV_DVX_ORDER_REQUEST,
  GET_DETAIL_REQUEST,
  GET_DVX_CLOSED_POS_REQUEST,
  GET_DVX_CURR_POS_REQUEST,
  GET_DVX_MATCH_ORDER_REQUEST,
  GET_DVX_PNL_RPT_REQUEST,
  GET_DVX_PP_REQUEST,
  GET_DVX_TOTAL_ASSET_REQUEST,
  GET_LIST_DVX_REQUEST,
  PLACE_ORDER_DVX_REQUEST,
  GET_PRICEBOARD_ERROR,
  GET_PRICEBOARD_REQUEST,
  GET_PRICEBOARD_SUCCESS,
  GET_DETAIL_SUCCESS,
  CLOSE_ALL_DVX_POS_REQUEST,
  GET_CFM_CLOSE_REVT_DVX_POS_REQUEST,
  GET_TOP_STOCK_INFLUENCE_REQUEST,
  CLOSE_REVERSE_DVX_POS_REQUEST,
  DERIVATIVE_COMMON_REQUEST,
  DERIVATIVE_COMMON_REQUEST2,
  GET_STOCK_TRADE,
  GET_DETAIL_REQUEST2,
  DERIVATIVE_COMMON_REQUEST3,
  GET_ORDER_DVX_CORE_REQUEST,
} from './constants';
import { Logout, logoutRequest } from '../../components/modal/login/actions';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const t = i18n.t;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}
function getListDvxAPI(data) {
  const url = `${appUrl}/getListDvx`;
  const request = fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getTopStockAPI(data) {
  const url = `${appUrl}/topstockinfluence/${data}`;
  const request = fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });
  return handleRequest(request);
}

function dvxAPI(data) {
  const url = `${appUrl}/DerivativeServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function dvxCoreAPI(data) {
  const url = `${appUrl}/CorsServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getStockDetailRequest(symbol, board) {
  const url = `${appUrl}/getliststockdata/${symbol}?board=${board}`;
  const request = fetch(url, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });

  return handleRequest(request);
}

function getStockTradeRequest(symbol, board) {
  const url = `${appUrl}/getliststocktrade/${symbol.toUpperCase()}?board=${board}`;
  const request = fetch(url);

  return handleRequest(request);
}

function toastHandle(message, title = 'txt-notice') {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    time: new Date(),
    title: i18n.t(`${title}`),
  };
  return put(setToast(toastMsg));
}

function getStockRequestApi(type) {
  const url = `${appUrl}/getListDvx${type ? `?seTp=${type}` : ''} `;
  const request = fetch(url);

  return handleRequest(request);
}

function* getPriceboardRequestFlow(action) {
  try {
    const { params } = action;
    const data = yield call(getStockRequestApi, params?.type);

    yield put({ type: GET_PRICEBOARD_SUCCESS, data: data });
  } catch (error) {
    console.error(error);
    yield put({ type: GET_PRICEBOARD_ERROR, data: error });
  }
}

function* getDetailRequestFlow(action) {
  const { symbol, board, callback, finallyCallback } = action;
  try {
    const resData = yield call(getStockDetailRequest, symbol, board);
    if (!callback) yield put({ type: GET_DETAIL_SUCCESS, data: resData });
    else {
      if (typeof callback == 'function') callback(resData);
    }
  } catch (error) {
    console.error(error);
    yield toastHandle(error, 'txt-error');
  } finally {
    if (finallyCallback && typeof finallyCallback == 'function')
      finallyCallback();
  }
}

function* getStockTradeRequestFlow(action) {
  try {
    const { symbol, board, callback } = action;
    const resData = yield call(getStockTradeRequest, symbol, board);
    if (callback && typeof callback == 'function') callback(resData);
  } catch (error) {
    yield toastHandle(error, 'txt-error');
  }
}

function* getListDvxRequestFlow(action, callback) {
  try {
    const resData = yield call(getListDvxAPI, action.params);
    yield put(getListDvxSuccess(resData));
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getTopStockFlow(action) {
  try {
    const { marketId, callback } = action;
    const resData = yield call(getTopStockAPI, marketId);

    if (
      resData &&
      Array.isArray(resData) &&
      callback &&
      typeof callback == 'function'
    ) {
      callback(resData);
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getDvxPPFlow(action) {
  try {
    const resData = yield call(dvxAPI, action.params);
    const token = yield select((state) => state.client.token);

    const callback = action.callback;
    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.http === 401) {
        if (token) {
          put(logoutRequest(token));
        }

        put(unsetClient());
        put(Logout());
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
        toastMsg = i18n.t('txt-valid-loss-session');
      } else {
        const msgErr = yield select(getMessage, resData);
        toastMsg = msgErr;
      }
      yield toastHandle(toastMsg);
    } else {
      if (callback && typeof callback == 'function') {
        callback();
      }
      yield put(getDvxPPSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getDvxCurrPosFlow(action) {
  try {
    const resData = yield call(dvxAPI, action.params);
    const callback = action.callback;
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      if (callback && typeof callback == 'function') {
        callback();
      }
      yield put(getDvxCurrPosSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getActvDvxOrdrFlow(action) {
  try {
    const resData = yield call(dvxAPI, action.params);
    const callback = action.callback;
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      if (callback && typeof callback == 'function') {
        callback();
      }
      yield put(getActvDvxOrdrSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getDvxMatchOrderFlow(action) {
  try {
    const resData = yield call(dvxAPI, action.params);
    const callback = action.callback;
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      if (callback && typeof callback == 'function') {
        callback();
      }
      yield put(getDvxMatchOrderSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getDvxClosedPosFlow(action) {
  try {
    const resData = yield call(dvxAPI, action.params);
    const callback = action.callback;
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      if (callback && typeof callback == 'function') {
        callback();
      }
      yield put(getDvxClosePosSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getDvxTotalAssetFlow(action) {
  try {
    const resData = yield call(dvxAPI, action.params);
    const callback = action.callback;
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      if (callback && typeof callback == 'function') {
        callback();
      }
      yield put(getDvxTotalAssetSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* closeAllDvxPosFlow(action) {
  try {
    const resData = yield call(dvxAPI, action.params);
    const callback = action.callback;
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getCfmClsRevtDvxPosFlow(action) {
  try {
    const resData = yield call(dvxAPI, action.params);
    const callback = action.callback;
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(getCfmClsRevtDvxPosSuccess(resData.data));
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* closeReverseDvxPositionFlow(action) {
  try {
    const resData = yield call(dvxAPI, action.params);
    const callback = action.callback;
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

/**
 * This func try to aim for covering almost call derrivative api cases
 * @param {isProtect} param0
 */
function* derivativeCommonRequestFlow({ params, callback, isProtect }) {
  try {
    const resData = yield call(dvxAPI, params);
    const token = yield select((state) => state.client.token);

    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.http === 401) {
        if (isProtect) {
          if (token) {
            put(logoutRequest(token));
            put(unsetClient());
            put(Logout());
          }
          removeCookie('token');
          removeCookie('authen');
          yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
        }

        toastMsg = i18n.t('txt-valid-loss-session');
      } else {
        const msgErr = yield select(getMessage, resData);
        toastMsg = msgErr;
      }

      yield toastHandle(toastMsg);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* derivativeCommonRequestFlowCORE({ params, callback, isProtect }) {
  try {
    const resData = yield call(dvxCoreAPI, params);
    const token = yield select((state) => state.client.token);
    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.http === 401) {
        if (isProtect) {
          if (token) {
            put(logoutRequest(token));
            put(unsetClient());
            put(Logout());
          }
          removeCookie('token');
          removeCookie('authen');
          yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
        }

        toastMsg = i18n.t('txt-valid-loss-session');
      } else {
        const msgErr = yield select(getMessage, resData);
        toastMsg = msgErr;
      }

      yield toastHandle(toastMsg);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* derivativeWatcher() {
  yield all([
    takeLatest(GET_PRICEBOARD_REQUEST, getPriceboardRequestFlow),
    takeLeading(GET_DETAIL_REQUEST, getDetailRequestFlow),
    takeEvery(GET_DETAIL_REQUEST2, getDetailRequestFlow),
    takeLeading(GET_STOCK_TRADE, getStockTradeRequestFlow),
    takeLatest(GET_LIST_DVX_REQUEST, getListDvxRequestFlow),
    takeLeading(PLACE_ORDER_DVX_REQUEST, derivativeCommonRequestFlow),
    takeLeading(PLACE_ORDER_DVX_CORE_REQUEST, derivativeCommonRequestFlowCORE),
    takeEvery(GET_ORDER_DVX_CORE_REQUEST, derivativeCommonRequestFlowCORE),
    takeLatest(GET_DVX_PP_REQUEST, getDvxPPFlow),
    takeLatest(GET_DVX_CURR_POS_REQUEST, getDvxCurrPosFlow),
    takeLatest(GET_ACTV_DVX_ORDER_REQUEST, getActvDvxOrdrFlow),
    takeLatest(GET_DVX_MATCH_ORDER_REQUEST, getDvxMatchOrderFlow),
    takeLatest(GET_DVX_CLOSED_POS_REQUEST, getDvxClosedPosFlow),
    takeLatest(GET_DVX_PNL_RPT_REQUEST, derivativeCommonRequestFlow),
    takeLatest(GET_DVX_TOTAL_ASSET_REQUEST, getDvxTotalAssetFlow),
    takeLatest(CLOSE_ALL_DVX_POS_REQUEST, closeAllDvxPosFlow),
    takeLatest(GET_CFM_CLOSE_REVT_DVX_POS_REQUEST, getCfmClsRevtDvxPosFlow),
    takeLatest(CLOSE_REVERSE_DVX_POS_REQUEST, closeReverseDvxPositionFlow),
    takeLatest(GET_TOP_STOCK_INFLUENCE_REQUEST, getTopStockFlow),

    takeEvery(DERIVATIVE_COMMON_REQUEST, derivativeCommonRequestFlow),
    takeLatest(DERIVATIVE_COMMON_REQUEST2, derivativeCommonRequestFlow),
    takeLeading(DERIVATIVE_COMMON_REQUEST3, derivativeCommonRequestFlow),
  ]);
}

export default derivativeWatcher;
