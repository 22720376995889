import React, { useState, useEffect, useLayoutEffect } from 'react';
import { authListRequest } from '../../../components/modal/auth/actions';
import { makeGetToken } from '../../../lib/selector';
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { _formatDate2, _hideMidStr } from '../../../util';
import './index.scss';
import { Mixpanel } from '../../../lib/mixpanel';
import Sms from './Sms';
import Matrix from './Matrix';
import SmartOTP from './SmartOtp';

const AuthenStep = withNamespaces('translations')(
  ({
    t,
    oneTimeToken = '',
    defaultType = 'SmartOTP',
    setOneTimeToken,
    setAuthType,
    tempToken,
    ignoreTypes = [],
    trackMixpanel,
  }) => {
    const [type, setType] = useState(defaultType);
    const [ignores, setIgnore] = useState(ignoreTypes);
    const { actList } = useSelector((state) => state.authen);
    const token = useSelector(makeGetToken());
    const dispatch = useDispatch();

    const OTPConfigs = {
      SMS: {
        type: 'SMS',
        label: 'SMS',
        Component: Sms,
      },
      Matrix: {
        type: 'Matrix',
        label: t('txt-matrix'),
        Component: Matrix,
      },
      SmartOTP: {
        type: 'SmartOTP',
        label: 'Smart OTP',
        Component: SmartOTP,
      },
    };

    useEffect(() => {
      setOneTimeToken('');
      if (trackMixpanel) {
        Mixpanel.track('Change OTP Type', {
          'OTP Type': type,
        });
      }
    }, [type]);

    useLayoutEffect(() => {
      const _token = tempToken || token;
      if (_token) getAuthListHandle(_token);
    }, [token, tempToken]);

    useLayoutEffect(() => {
      if (actList && actList.list) {
        const SupportedType = {
          MATRIX: 'Matrix',
          TOP: 'SMS',
        };

        const filteredAuthTypes = actList.list
          .filter(
            (item) =>
              item.authYn === 'N' &&
              Object.entries(SupportedType)
                .map(([key]) => key)
                .includes(item.authType)
          )
          .map((item) => SupportedType[item.authType]);

        setIgnore((items) => [...items, ...filteredAuthTypes]);
      }
    }, [actList]);

    const getAuthListHandle = (_token) => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: _token.user,
        session: _token.session,
        cmd: 'GetAuthList',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: _token.cif,
        },
      };

      dispatch(authListRequest(params));
    };

    const defaultTypes = Object.entries(OTPConfigs).map(([key]) => key);
    const tabs = defaultTypes.filter((tab) => !ignores.includes(tab));

    const SelectedOTP = OTPConfigs[type].Component;

    return (
      <div className="authen-section">
        <span>{t('account.authen.title')}</span>
        <div className="authen-type-switches">
          {tabs.map((tab) => {
            return (
              <span
                className={`authen-type-switch ${
                  OTPConfigs[tab].type == type ? 'active' : ''
                }`}
                onClick={() => {
                  if (setAuthType) {
                    setAuthType(tab);
                  }
                  setType(tab);
                }}
              >
                <span>{OTPConfigs[tab].label}</span>
              </span>
            );
          })}
        </div>
        <SelectedOTP
          value={oneTimeToken}
          onChange={(value) => {
            setOneTimeToken(value);
          }}
          tempToken={tempToken}
        />
      </div>
    );
  }
);

export default AuthenStep;
