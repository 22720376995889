import {
  GET_PROFILE_REQUESTING,
  GET_PROFILE_REQUEST_SUCCESS,
  BANK_ACC_REQUESTING,
  BANK_ACC_REQUEST_SUCCESS,
  BANK_ACC_REQUEST_ERROR,
  GET_PROFILE_REQUEST_ERROR,
  SET_DEFAULT_REQUESTING,
  SET_DEFAULT_REQUEST_SUCCESS,
  SET_DEFAULT_REQUEST_ERROR,
  GET_SMS_SERVICES_REQUESTING,
  GET_SMS_SERVICES_REQUEST_ERROR,
  GET_SMS_SERVICES_REQUEST_SUCCESS,
  SET_SMS_SERVICES_REQUEST_SUCCESS,
  SET_SMS_SERVICES_REQUEST_ERROR,
  SET_SMS_SERVICES_REQUESTING,
  GET_EXT_SETTING_REQUESTING,
  GET_EXT_SETTING_REQUEST_SUCCESS,
  GET_EXT_SETTING_REQUEST_ERROR,
  SET_EXT_SETTING_REQUEST_SUCCESS,
  SET_EXT_SETTING_REQUEST_ERROR,
  SET_EXT_SETTING_REQUESTING,
  REMOVE_BANK_REQUESTING,
  REMOVE_BANK_REQUEST_SUCCESS,
  REMOVE_BANK_REQUEST_ERROR,
  UPDATE_PROFILE_REQUESTING,
  UPDATE_PROFILE_REQUEST_SUCCESS,
  UPDATE_PROFILE_REQUEST_ERROR,
  GET_PROFILE_STATE_REQUEST,
  GET_PROFILE_STATE_REQUEST_SUCCESS,
  GET_PROFILE_STATE_REQUEST_ERROR,
  UPDATE_PROFILE_RESET,
  RESET_EXT_SETTING_REQUEST,
  RESET_SMS_SERVICES_REQUEST,
  RESET_DEFAULT_REQUEST,
  VC_BOOKING_DATE_REQUESTING,
  VC_BOOKING_DATE_SUCCESS,
  VC_BOOKING_DATE_ERROR,
  VC_BOOKING_TIME_ERROR,
  VC_BOOKING_TIME_SUCCESS,
  VC_BOOKING_TIME_REQUESTING,
  VC_BOOKING_REQUESTING,
  VC_BOOKING_SUCCESS,
  VC_BOOKING_ERROR,
  REGIST_VC_BOOKING_ERROR,
  REGIST_VC_BOOKING_SUCCESS,
  REGIST_VC_BOOKING_REQUESTING,
  CANCEL_VC_BOOKING_ERROR,
  CANCEL_VC_BOOKING_SUCCESS,
  CANCEL_VC_BOOKING_REQUESTING,
  OPEN_SUBACCOUNT_CONTRACT_REQUEST,
  OPEN_SUBACCOUNT_CONTRACT_SUCCESS,
  OPEN_BOOKING_VIDEO_MODAL,
  GET_CREDIT_LIMIT_LEVEL,
  GET_CREDIT_LIMIT_LEVEL_SUCCESS,
  OPEN_CREDIT_LIMIT_MODAL,
  GET_BROKER_INFOR_SUCCESS,
  GET_BROKER_INFOR_REQUEST,
  SET_PARTNER_SHIP_REQUEST,
  SUBMIT_PARTNER_SHIP_SUCCESS,
} from './constants';

const initialState = {
  profile: null,
  profileRequesting: false,
  profileErrors: [],

  profileUpdateRequest: false,
  profileUpdateRequestSuccess: false,
  profileUpdateRequestError: [],

  profileUpdateState: false,

  bankAcc: [],
  bankAccRequesting: false,
  bankAccErrors: [],

  removeBankRequesting: false,
  removeBankRequestSuccess: false,
  removeBankRequestErrors: [],

  setDefaultSuccess: false,
  setDefaultErrors: [],

  sMSSetting: [],
  lastSMSSetting: {},
  getSMSSettingSuccess: false,
  getSMSSettingErrors: [],

  setSMSSettingRequesting: false,
  setSMSSettingSuccess: false,
  setSMSSettingErrors: [],

  extSetting: [],
  extSettingSuccess: false,
  extSettingErrors: [],

  setExtSettingRequesting: false,
  setExtSettingSuccess: false,
  setExtSettingErrors: [],
  lastExtSetting: {},

  setVcBookingDateRequesting: false,
  setVcBookingDateSuccess: false,
  setVcBookingDateErors: false,
  vcBookingDate: {},

  setVcBookingTimeRequesting: false,
  setVcBookingTimeSuccess: false,
  setVcBookingTimeErors: false,
  vcBookingTime: {},

  setVcBookingRequesting: false,
  setVcBookingSuccess: false,
  setVcBookingErrors: [],
  vcBooking: {},

  setRegistVcBookingRequesting: false,
  setRegistVcBookingSuccess: false,
  setRegistVcBookingErrors: [],
  registVcBooking: {},

  openSubAccountContract: null,
  initBookingModal: null,
  creditLimitLevels: [],
  initCreditLimitLevelModal: null,

  broker: null,
  registPartnershipShow: false,
  registPartnershipSuccess: false,
};

const reducer = function customerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_REQUESTING:
      return {
        ...state,
        profile: null,
        profileRequesting: true,
        profileErrors: [],
      };

    case GET_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        profile: action.data,
        profileRequesting: false,
        profileErrors: [],
      };

    case GET_PROFILE_REQUEST_ERROR:
      return {
        ...state,
        profile: null,
        profileRequesting: false,
        profileErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case UPDATE_PROFILE_RESET:
      return {
        ...state,
        profileUpdateRequest: false,
        profileUpdateRequestSuccess: false,
        profileUpdateRequestError: [],
      };

    case UPDATE_PROFILE_REQUESTING:
      return {
        ...state,
        profileUpdateRequest: true,
        profileUpdateRequestSuccess: false,
        profileUpdateRequestError: [],
      };

    case UPDATE_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        profileUpdateRequest: false,
        profileUpdateRequestSuccess: true,
        profileUpdateRequestError: [],
      };

    case UPDATE_PROFILE_REQUEST_ERROR:
      return {
        ...state,
        profileUpdateRequest: false,
        profileUpdateRequestSuccess: false,
        profileUpdateRequestError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case BANK_ACC_REQUESTING:
      return {
        ...state,
        bankAcc: [],
        bankAccRequesting: true,
        bankAccErrors: [],
      };

    case BANK_ACC_REQUEST_SUCCESS:
      return {
        ...state,
        bankAcc: action.data,
        bankAccRequesting: false,
        bankAccErrors: [],
      };

    case BANK_ACC_REQUEST_ERROR:
      return {
        ...state,
        bankAcc: [],
        bankAccRequesting: false,
        bankAccErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case REMOVE_BANK_REQUESTING:
      return {
        ...state,
        removeBankRequesting: true,
        removeBankRequestSuccess: false,
        removeBankRequestErrors: [],
      };

    case REMOVE_BANK_REQUEST_SUCCESS:
      const params = action.params?.data;
      let _bankAcc = state.bankAcc;
      let _bankAccount = _bankAcc.bankAccount;
      if (params && params.bankAcntStat == 9) {
        _bankAccount = _bankAccount.filter((x) => {
          return x.bankAcntNo != params.bankAcntNo;
        });
      }
      _bankAcc = { ..._bankAcc, bankAccount: _bankAccount };

      return {
        ...state,
        bankAcc: _bankAcc,
        removeBankRequesting: false,
        removeBankRequestSuccess: true,
        removeBankRequestErrors: [],
      };

    case REMOVE_BANK_REQUEST_ERROR:
      return {
        ...state,
        removeBankRequesting: false,
        removeBankRequestSuccess: false,
        removeBankRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SET_DEFAULT_REQUESTING:
      return {
        ...state,
        setDefaultSuccess: false,
        setDefaultErrors: [],
      };

    case SET_DEFAULT_REQUEST_SUCCESS:
      return {
        ...state,
        setDefaultSuccess: true,
        setDefaultErrors: [],
      };

    case SET_DEFAULT_REQUEST_ERROR:
      return {
        ...state,
        setDefaultSuccess: false,
        setDefaultErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case RESET_DEFAULT_REQUEST:
      return {
        ...state,
        setDefaultSuccess: false,
        setDefaultErrors: [],
      };

    case GET_SMS_SERVICES_REQUESTING:
      return {
        ...state,
        getSMSSettingSuccess: false,
        sMSSetting: [],
        getSMSSettingErrors: [],
      };

    case GET_SMS_SERVICES_REQUEST_SUCCESS:
      return {
        ...state,
        getSMSSettingSuccess: true,
        sMSSetting: action.data,
        getSMSSettingErrors: [],
      };

    case GET_SMS_SERVICES_REQUEST_ERROR:
      return {
        ...state,
        getSMSSettingSuccess: false,
        sMSSetting: [],
        getSMSSettingErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SET_SMS_SERVICES_REQUESTING:
      return {
        ...state,
        setSMSSettingRequesting: true,
        setSMSSettingSuccess: false,
        lastSMSSetting: {},
        setSMSSettingErrors: [],
      };

    case SET_SMS_SERVICES_REQUEST_SUCCESS:
      const sms_params = action.params;
      const _sMSSetting = state.sMSSetting;
      const sms_option = _sMSSetting.find(
        (x) =>
          x.custNo == sms_params.custNo && x.notifiCode == sms_params.notifiCode
      );
      sms_option.custNotifiSetStat = sms_params.custNotifiSetStat;

      return {
        ...state,
        sMSSetting: _sMSSetting,
        setSMSSettingRequesting: false,
        setSMSSettingSuccess: true,
        lastSMSSetting: sms_params,
        setSMSSettingErrors: [],
      };

    case SET_SMS_SERVICES_REQUEST_ERROR:
      return {
        ...state,
        setSMSSettingRequesting: false,
        setSMSSettingSuccess: false,
        lastSMSSetting: {},
        setSMSSettingErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case RESET_SMS_SERVICES_REQUEST: {
      return {
        ...state,
        setSMSSettingRequesting: false,
        setSMSSettingSuccess: false,
        lastSMSSetting: {},
        setSMSSettingErrors: [],
      };
    }

    case GET_EXT_SETTING_REQUESTING:
      return {
        ...state,
        extSettingSuccess: false,
        extSetting: [],
        extSettingErrors: [],
      };

    case GET_EXT_SETTING_REQUEST_SUCCESS:
      return {
        ...state,
        extSettingSuccess: true,
        extSetting: action.data,
        extSettingErrors: [],
      };

    case GET_EXT_SETTING_REQUEST_ERROR:
      return {
        ...state,
        extSettingSuccess: false,
        extSetting: [],
        extSettingErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SET_EXT_SETTING_REQUESTING:
      return {
        ...state,
        setExtSettingRequesting: true,
        setExtSettingSuccess: false,
        setExtSettingErrors: [],
        lastExtSetting: {},
      };

    case SET_EXT_SETTING_REQUEST_SUCCESS:
      const ext_params = action.params;
      const _extSetting = state.extSetting;
      const ext_option = _extSetting.find(
        (x) =>
          x.custNo == ext_params.custNo && x.featureTp == ext_params.featureTp
      );

      if (ext_option && ext_option.regTp == ext_params.regTp) {
        ext_option.regTp_note = 'R'; // R: reset - đặt lại
      } else {
        ext_option.regTp_note = '';
      }

      ext_option.regTp = ext_params.regTp;

      return {
        ...state,
        extSetting: _extSetting,
        setExtSettingRequesting: false,
        setExtSettingSuccess: true,
        lastExtSetting: ext_params,
        setExtSettingErrors: [],
      };

    case SET_EXT_SETTING_REQUEST_ERROR:
      return {
        ...state,
        setExtSettingSuccess: false,
        setExtSettingRequesting: false,
        lastExtSetting: {},
        setExtSettingErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case RESET_EXT_SETTING_REQUEST: {
      return {
        ...state,
        setExtSettingSuccess: false,
        setExtSettingRequesting: false,
        lastExtSetting: {},
        setExtSettingErrors: [],
      };
    }

    case GET_PROFILE_STATE_REQUEST_SUCCESS:
      return {
        ...state,
        profileUpdateState: action.data,
      };

    case VC_BOOKING_DATE_REQUESTING: {
      return {
        ...state,
        setVcBookingDateRequesting: true,
        setVcBookingDateSuccess: false,
        setVcBookingDateErrors: [],
        vcBookingDate: {},
      };
    }

    case VC_BOOKING_DATE_SUCCESS: {
      return {
        ...state,
        setVcBookingDateRequesting: false,
        setVcBookingDateSuccess: true,
        setVcBookingDateErrors: [],
        vcBookingDate: action.data,
      };
    }

    case VC_BOOKING_DATE_ERROR: {
      return {
        ...state,
        setVcBookingDateRequesting: false,
        setVcBookingDateSuccess: action.data,
        setVcBookingDateErrors: [],
        vcBookingDate: {},
      };
    }

    case VC_BOOKING_TIME_REQUESTING: {
      return {
        ...state,
        setVcBookingTimeRequesting: true,
        setVcBookingTimeSuccess: false,
        setVcBookingTimeErrors: [],
        vcBookingTime: {},
      };
    }

    case VC_BOOKING_TIME_SUCCESS: {
      return {
        ...state,
        setVcBookingTimeRequesting: false,
        setVcBookingTimeSuccess: true,
        setVcBookingTimeErrors: [],
        vcBookingTime: action.data,
      };
    }

    case VC_BOOKING_TIME_ERROR: {
      return {
        ...state,
        setVcBookingTimeRequesting: false,
        setVcBookingTimeSuccess: action.data,
        setVcBookingTimeErrors: [],
        vcBookingTime: {},
      };
    }

    case REGIST_VC_BOOKING_REQUESTING: {
      return {
        ...state,
        setRegistVcBookingRequesting: true,
        setRegistVcBookingSuccess: false,
        setRegistVcBookingErrors: [],
        registVcBooking: {},
      };
    }

    case VC_BOOKING_SUCCESS: {
      return {
        ...state,
        setVcBookingRequesting: false,
        setVcBookingSuccess: true,
        setVcBookingErrors: [],
        vcBooking: action.data,
      };
    }

    case VC_BOOKING_ERROR: {
      return {
        ...state,
        setVcBookingRequesting: false,
        setVcBookingSuccess: action.data,
        setVcBookingErrors: [],
        vcBooking: {},
      };
    }

    case REGIST_VC_BOOKING_REQUESTING: {
      return {
        ...state,
        setVcBookingRequesting: true,
        setVcBookingSuccess: false,
        setVcBookingErrors: [],
        vcBooking: {},
      };
    }

    case REGIST_VC_BOOKING_SUCCESS: {
      return {
        ...state,
        setRegistVcBookingRequesting: false,
        setRegistVcBookingSuccess: true,
        setRegistVcBookingErrors: [],
        registVcBooking: action.data,
        initBookingModal: null,
      };
    }

    case REGIST_VC_BOOKING_ERROR: {
      return {
        ...state,
        setRegistVcBookingRequesting: false,
        setRegistVcBookingSuccess: action.data,
        setRegistVcBookingErrors: [],
        registVcBooking: {},
      };
    }

    case CANCEL_VC_BOOKING_REQUESTING: {
      return {
        ...state,
        setVcBookingRequesting: true,
        setVcBookingSuccess: false,
        setVcBookingErrors: [],
        vcBooking: {},
      };
    }

    case CANCEL_VC_BOOKING_SUCCESS: {
      return {
        ...state,
        setRegistVcBookingRequesting: false,
        setRegistVcBookingSuccess: true,
        setRegistVcBookingErrors: [],
        registVcBooking: action.data,
      };
    }

    case CANCEL_VC_BOOKING_ERROR: {
      return {
        ...state,
        setRegistVcBookingRequesting: false,
        setRegistVcBookingSuccess: action.data,
        setRegistVcBookingErrors: [],
        registVcBooking: {},
      };
    }

    case OPEN_SUBACCOUNT_CONTRACT_REQUEST: {
      return {
        ...state,
        openSubAccountContract: {},
      };
    }

    case OPEN_SUBACCOUNT_CONTRACT_SUCCESS: {
      return {
        ...state,
        openSubAccountContract: action.data,
      };
    }

    case OPEN_BOOKING_VIDEO_MODAL: {
      return {
        ...state,
        initBookingModal: action.data,
      };
    }

    case GET_CREDIT_LIMIT_LEVEL: {
      return {
        ...state,
        creditLimitLevels: [],
      };
    }

    case GET_CREDIT_LIMIT_LEVEL_SUCCESS: {
      return {
        ...state,
        creditLimitLevels: action.data,
      };
    }

    case OPEN_CREDIT_LIMIT_MODAL: {
      return {
        ...state,
        initCreditLimitLevelModal: action.data,
      };
    }

    case GET_BROKER_INFOR_REQUEST: {
      return {
        ...state,
        broker: null,
      };
    }

    case GET_BROKER_INFOR_SUCCESS: {
      const { resData } = action;
      return {
        ...state,
        broker: !resData
          ? null
          : {
              id: resData.brokerId,
              status: resData.status,
            },
      };
    }

    case SET_PARTNER_SHIP_REQUEST: {
      return {
        ...state,
        registPartnershipShow: action.show,
      };
    }

    case SUBMIT_PARTNER_SHIP_SUCCESS: {
      return {
        ...state,
        registPartnershipSuccess: action.show,
      };
    }

    default:
      return state;
  }
};

export default reducer;
