import { constants } from '../../util/constant';
import {
  ALL_STOCK_REQUESTING,
  ALL_STOCK_REQUEST_SUCCESS,
  ALL_STOCK_REQUEST_ERROR,
  SNAPSHOT_REQUESTING,
  SNAPSHOT_REQUEST_SUCCESS,
  SNAPSHOT_REQUEST_ERROR,
  STOCK_INFO_REQUEST_SUCCESS,
  ALL_ORD_REQUESTING,
  ALL_ORD_REQUEST_SUCCESS,
  ALL_ORD_REQUEST_ERROR,
  CLEAR_PART_REQUEST,
  SNAPSHOT_PART_REQUESTING,
  SNAPSHOT_PART_REQUEST_SUCCESS,
  SNAPSHOT_PART_REQUEST_ERROR,
  PT_LIST_REQUEST_SUCCESS,
  PT_LIST_REQUESTING,
  PT_LIST_REQUEST_ERROR,
  CW_LIST_REQUESTING,
  CW_LIST_REQUEST_SUCCESS,
  CW_LIST_REQUEST_ERROR,
  LIST_30_REQUESTING,
  LIST_30_REQUEST_ERROR,
  LIST_30_REQUEST_SUCCESS,
  LIST_MESSAGE_REQUEST_ERROR,
  LIST_MESSAGE_REQUESTING,
  LIST_MESSAGE_REQUEST_SUCCESS,
  BRANCH_CODE_REQUESTING,
  BRANCH_CODE_REQUEST_ERROR,
  BRANCH_CODE_REQUEST_SUCCESS,
  SUMMARY_REQUESTING,
  SUMMARY_REQUEST_ERROR,
  SUMMARY_REQUEST_SUCCESS,
  NOI_CAP_REQUESTING,
  NOI_CAP_REQUEST_ERROR,
  NOI_CAP_REQUEST_SUCCESS,
  GET_STOCK_BY_ID_REQUESTING,
  GET_STOCK_BY_ID_REQUEST_SUCCESS,
  GET_STOCK_BY_ID_REQUEST_ERROR,
  INDEX_REQUESTING,
  INDEX_REQUEST_SUCCESS,
  INDEX_REQUEST_ERROR,
  WORLD_INDEX_REQUESTING,
  WORLD_INDEX_REQUEST_SUCCESS,
  WORLD_INDEX_REQUEST_ERROR,
  PRICE_TABLE_SORT_FIELD_CHANGE,
  PRICE_TABLE_SORT_DIRECTION_CHAGE,
  SET_PRICE_TABLE_SYMBOLS,
  ODD_LOT_SETUP,
  COMMODITY_REQUESTING,
  COMMODITY_REQUESTING_SUCCESS,
  COMMODITY_REQUESTING_ERROR,
} from './constants';

const initialState = {
  allStock: [],
  allStockRequesting: false,
  allStockSuccessful: false,
  allStockErrors: [],

  listMessage: null,

  snapshot: [],
  snapshotRequesting: false,
  snapshotSuccessful: false,
  snapshotErrors: [],

  part: [],
  partRequesting: false,
  partSuccessful: false,

  partId: [],
  partIdRequesting: false,
  partIdSuccessful: false,
  partIdErrors: [],

  allOrder: null,
  ptList: [],
  cwList: null,
  list30: null,
  branchList: null,
  currentIndustry: null,
  noicap: null,
  indSum: null,

  indexList: [],
  worldList: [],
  worldListSuccess: false,
  requesting: false,
  successful: false,
  errors: [],

  sortField: 'sym',
  isAsc: true,
  currentSymbols: '',
  isOddlot: false,

  commodityList: [],
  commodityListSuccess: false,
  commodityListRequesting: false,
  commodityListErrors: [],
};

const reducer = function widgetReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_STOCK_REQUESTING:
      return {
        ...state,
        allStockRequesting: false,
        allStockSuccessful: false,
        allStockErrors: [],
      };

    case ALL_STOCK_REQUEST_SUCCESS:
      return {
        ...state,
        allStock: action.allStock,
        allStockRequesting: false,
        allStockSuccessful: true,
        allStockErrors: [],
      };

    case ALL_STOCK_REQUEST_ERROR:
      return {
        ...state,
        allStockRequesting: false,
        allStockSuccessful: false,
        allStockErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SNAPSHOT_REQUESTING:
      return {
        ...state,
        snapshotRequesting: true,
        snapshotSuccessful: false,
        snapshotErrors: [],
        snapshot: [],
      };

    case SNAPSHOT_REQUEST_SUCCESS:
      return {
        ...state,
        snapshot: action.dataList, // replace with fresh list
        snapshotRequesting: false,
        snapshotSuccessful: true,
        snapshotErrors: [],
      };

    case SNAPSHOT_REQUEST_ERROR:
      return {
        ...state,
        snapshotRequesting: false,
        snapshotSuccessful: false,
        snapshot: [],
        snapshotErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    // case SNAPSHOT_SYMBOL_DETAIL_REQUESTING:
    // return{
    //   ...state
    // }

    case CLEAR_PART_REQUEST:
      return {
        ...state,
        part: [],
        partRequesting: false,
        partSuccessful: false,
      };

    case SNAPSHOT_PART_REQUESTING:
      return {
        ...state,
        partRequesting: true,
        partSuccessful: false,
      };

    case SNAPSHOT_PART_REQUEST_SUCCESS:
      return {
        ...state,
        part: [...state.part, ...action.dataList], // replace with fresh list
        partRequesting: false,
        partSuccessful: true,
      };

    case SNAPSHOT_PART_REQUEST_ERROR:
      return {
        ...state,
        partRequesting: false,
        partSuccessful: false,
      };

    case STOCK_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        snapshot: [...state.snapshot, ...action.stockInfo],
      };

    case ALL_ORD_REQUESTING:
    case ALL_ORD_REQUEST_ERROR:
      return {
        ...state,
        allOrder: null,
      };

    case ALL_ORD_REQUEST_SUCCESS:
      return {
        ...state,
        allOrder: action.allOrder,
      };

    case PT_LIST_REQUESTING:
    case PT_LIST_REQUEST_ERROR:
      return {
        ...state,
        ptList: [],
      };

    case PT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        ptList: action.ptList,
      };

    case CW_LIST_REQUESTING:
    case CW_LIST_REQUEST_ERROR:
      return {
        ...state,
        cwList: null,
      };

    case CW_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        cwList: action.cwList,
      };

    case LIST_30_REQUESTING:
    case LIST_30_REQUEST_ERROR:
      return {
        ...state,
        list30: null,
      };

    case LIST_30_REQUEST_SUCCESS:
      return {
        ...state,
        list30: action.list30,
      };

    case LIST_MESSAGE_REQUESTING:
    case LIST_MESSAGE_REQUEST_ERROR:
      return {
        ...state,
        listMessage: null,
      };

    case LIST_MESSAGE_REQUEST_SUCCESS:
      return {
        ...state,
        listMessage: action.listMessage,
      };

    case BRANCH_CODE_REQUESTING:
    case BRANCH_CODE_REQUEST_ERROR:
      return {
        ...state,
        branchList: null,
      };

    case BRANCH_CODE_REQUEST_SUCCESS:
      return {
        ...state,
        branchList: action.branchList,
      };

    case NOI_CAP_REQUESTING:
    case NOI_CAP_REQUEST_ERROR:
      return {
        ...state,
        noicap: null,
      };

    case NOI_CAP_REQUEST_SUCCESS:
      return {
        ...state,
        noicap: action.noicap,
      };

    case SUMMARY_REQUESTING:
    case SUMMARY_REQUEST_ERROR:
      return {
        ...state,
        indSum: null,
      };

    case SUMMARY_REQUEST_SUCCESS:
      return {
        ...state,
        indSum: action.indSum,
      };

    case GET_STOCK_BY_ID_REQUESTING:
      return {
        ...state,
        partIdRequesting: true,
        partIdSuccessful: false,
        partIdErrors: [],
      };

    case GET_STOCK_BY_ID_REQUEST_SUCCESS:
      return {
        ...state,
        partId: action.dataList,
        partIdRequesting: false,
        partIdSuccessful: true,
        partIdErrors: [],
      };

    case GET_STOCK_BY_ID_REQUEST_ERROR:
      return {
        ...state,
        partId: [],
        partIdRequesting: false,
        partIdSuccessful: false,
        partIdErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case INDEX_REQUESTING:
      return {
        ...state, // ensure that we don't erase fetched ones
        requesting: true,
        successful: false,
        errors: [],
      };

    case INDEX_REQUEST_SUCCESS:
      // console.log(action);
      return {
        ...state,
        indexList: action.indexList, // replace with fresh list
        requesting: false,
        successful: true,
        errors: [],
      };

    case INDEX_REQUEST_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case WORLD_INDEX_REQUESTING:
      return {
        ...state, // ensure that we don't erase fetched ones
        requesting: true,
        worldListSuccess: false,
        errors: [],
      };

    case WORLD_INDEX_REQUEST_SUCCESS:
      return {
        ...state,
        worldList: action.worldList, // replace with fresh list
        requesting: false,
        worldListSuccess: true,
        errors: [],
      };

    case WORLD_INDEX_REQUEST_ERROR:
      return {
        ...state,
        requesting: false,
        worldListSuccess: false,
        errors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case PRICE_TABLE_SORT_FIELD_CHANGE:
      return {
        ...state,
        sortField: action.sortField,
      };

    case PRICE_TABLE_SORT_DIRECTION_CHAGE:
      const { isAsc } = state;
      return {
        ...state,
        isAsc: !isAsc,
      };

    case SET_PRICE_TABLE_SYMBOLS:
      return {
        ...state,
        currentSymbols: action.symbols,
      };

    case ODD_LOT_SETUP: {
      return {
        ...state,
        isOddlot: action.status,
      };
    }

    case COMMODITY_REQUESTING:
      return {
        ...state, // ensure that we don't erase fetched ones
        commodityListRequesting: true,
        commodityListSuccess: false,
        commodityListErrors: [],
      };

    case COMMODITY_REQUESTING_SUCCESS:
      return {
        ...state,
        commodityList: action.commodityList, // replace with fresh list
        commodityListRequesting: false,
        commodityListSuccess: true,
        commodityListErrors: [],
      };

    case COMMODITY_REQUESTING_ERROR:
      return {
        ...state,
        commodityList: [],
        commodityListRequesting: false,
        commodityListSuccess: false,
        commodityListErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    default:
      return state;
  }
};

export default reducer;
