import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getCommonRequest } from '../../customer/actions';
import { withNamespaces } from 'react-i18next';
import { setToast } from '../../client/actions';
import * as clipboard from 'clipboard-polyfill';
import { Redirect } from 'react-router';
import QRCode from 'qrcode.react';
import logo from '../../../assets/img/logo.svg';

const Partnership = withNamespaces('translations')(({ t }) => {
  const { broker } = useSelector((state) => state.customer);
  const {
    token,
    setting: { lang = 'vi' },
    appsetting,
  } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const qrRef = useRef();

  const generals =
    appsetting && !!appsetting?.length > 0
      ? JSON.parse(appsetting?.find((item) => item.key == 'GENERAL')?.values || [])
      : [];

  const broker_url =
    generals.find((x) => x.key == 'partnership-url')?.value ||
    process.env.REACT_APP_BROKER_URL;

  const statusLabel = (status) => {
    return status == '0' ? t('txt-active') : t('txt-deactive');
  };

  const copyToClipboardHandle = (e, msg = t('txt-copied-to-clipboard')) => {
    if (!e) return;

    const textToCopy = e.trim();

    var tempInputElement = document.createElement('input');
    tempInputElement.type = 'text';
    tempInputElement.value = textToCopy;
    document.body.appendChild(tempInputElement);
    tempInputElement.select();
    document.execCommand('Copy');
    document.body.removeChild(tempInputElement);

    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };

    dispatch(setToast(toastMsg));
  };

  const downloadQRCodeHandle = () => {
    const canvas = qrRef.current.children[0];
    if (canvas) {
      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = url;
      a.download = 'pinetree-qrcode.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const copyImageToClipboard = async (msg = t('txt-copied-to-clipboard')) => {
    try {
      const canvas = qrRef.current.children[0];
      if (canvas) {
        // Convert canvas to blob
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        // Write blob to clipboard
        await navigator.clipboard.write([
          new ClipboardItem({ 'image/png': blob }),
        ]);

        const toastMsg = {
          id: Math.random(),
          msg: msg,
          title: t('txt-notice'),
        };

        dispatch(setToast(toastMsg));
      }
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  // if (!broker) return <Redirect to="/" />;

  return (
    <div className="partner-page">
      <div className="account-panel col-md-8">
        <div className="account-panel-header">{t('partner.infor')}</div>
        <div className="account-panel-body">
          <div className="account-panel-items">
            <div className="account-panel-item">
              <span className="account-panel-item__name">
                {t('partner.id')}
              </span>
              <span className="account-panel-item__value d-flex align-items-center">
                <span>{broker?.id}</span>
                <span
                  className="d-flex ml-2 btn-icon btn--transparent cursor-pointer"
                  onClick={() => {
                    copyToClipboardHandle(
                      broker?.id,
                      t('txt-copied-to-clipboard', {
                        object: t('partner.id').toLowerCase() + ' ',
                      })
                    );
                  }}
                >
                  <span className="icon iCopy"></span>
                </span>
              </span>
            </div>
            <div className="account-panel-item">
              <span className="account-panel-item__name">
                {t('partner.status')}
              </span>
              <span className="account-panel-item__value">
                {statusLabel(broker?.status)}
              </span>
            </div>
            <div className="account-panel-item">
              <span className="account-panel-item__name">
                {t('partner.link')}
              </span>
              <span className="account-panel-item__value d-flex align-items-center">
                <a
                  className="text text--light-blue3"
                  href={`${broker_url}/${broker?.id}`}
                  target="_blank"
                >
                  {`${broker_url}/${broker?.id}`}
                </a>
                <span
                  className="d-flex ml-2 btn-icon btn--transparent cursor-pointer"
                  onClick={() => {
                    copyToClipboardHandle(
                      `${broker_url}/${broker?.id}`,
                      t('txt-copied-to-clipboard', {
                        object: t('object.url').toLowerCase() + ' ',
                      })
                    );
                  }}
                >
                  <span className="icon iCopy"></span>
                </span>
              </span>
            </div>
            <div className="account-panel-item">
              <span className="account-panel-item__name">
                {t('partner.qr')}
              </span>
              <div className="account-panel-item__value d-flex gap-3">
                <div className="partner-page__qr" ref={qrRef}>
                  <QRCode
                    id="partnership-qr"
                    value={`${broker_url}/${broker?.id}`}
                    imageSettings={{
                      src: logo,
                      excavate: true,
                      width: 24,
                      height: 24,
                    }}
                  />
                </div>
                <div className="d-flex flex-column gap-3">
                  <button
                    className="btn btn--primary2"
                    onClick={() => downloadQRCodeHandle()}
                  >
                    {t('txt-save')}
                  </button>
                  <button
                    className="btn btn--primary2"
                    onClick={() =>
                      copyImageToClipboard(
                        t('txt-copied-to-clipboard', {
                          object: t('object.img').toLowerCase() + ' ',
                        })
                      )
                    }
                  >
                    {t('txt-copy')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Partnership;
