import { all, call, put, takeLatest } from 'redux-saga/effects';
import { handleApiErrors } from '../../../lib/api-errors';
import { _processMapDataCS } from '../../../util';
import {
  cdktRequestError,
  cdktRequestSuccess,
  companyInfoRequestError,
  companyInfoRequestSuccess,
  cstRequestError,
  cstRequestSuccess,
  kqkdRequestError,
  kqkdRequestSuccess,
  stockDetailRequestError,
  stockDetailRequestSuccess,
  stockEventsRequestError,
  stockEventsRequestSuccess,
  stockHisRequestError,
  stockHisRequestSuccess,
  stockManagementRequestError,
  stockManagementRequestSuccess,
  stockNewsRequestError,
  stockNewsRequestSuccess,
  stockTradeRequestError,
  stockTradeRequestSuccess,
} from './actions';
import {
  CDKT_REQUESTING,
  COMPANY_INFO_REQUESTING,
  CST_REQUESTING,
  KQKD_REQUESTING,
  STOCK_DETAIL_REQUESTING,
  STOCK_EVENTS_REQUESTING,
  STOCK_HIS_REQUESTING,
  STOCK_MANAGEMENT_REQUESTING,
  STOCK_NEWS_REQUESTING,
  STOCK_TRADE_REQUESTING,
} from './constants';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const appUrl2 = `${process.env.REACT_APP_API_URL_2}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function stockDetailRequestApi(data, board) {
  const url = `${appUrl}/getliststockdata/${data.toUpperCase()}?board=${board}`;
  const request = fetch(url);

  return handleRequest(request);
}

function stockTradeRequestApi(data, board) {
  const url = `${appUrl}/getliststocktrade/${data.toUpperCase()}?board=${board}`;
  const request = fetch(url);

  return handleRequest(request);
}

function reportRequestApi(data) {
  const url = `${appUrl}/stockReport.pt?${data}`;
  const request = fetch(url);
  return handleRequest(request);
}

function reportHisRequestApi(data) {
  const url = `${appUrl}/stockHis.pt?${data}`;
  const request = fetch(url);
  return handleRequest(request);
}

function reportNewsRequestApi(data) {
  const url = `${appUrl2}/api/News/GetNewsByStock?stockCode=${data}`;
  const request = fetch(url);
  return handleRequest(request);
}

function stockEventsRequestApi(data) {
  const url = `${appUrl}/stockEvents.pt?${data}`;
  const request = fetch(url);
  return handleRequest(request);
}

function stockManageRequestApi(data) {
  const url = `${appUrl}/stockMangement.pt?symbol=${data}`;
  const request = fetch(url);
  return handleRequest(request);
}

function companyInfoRequestApi(data) {
  const url = `${appUrl}/companyInfo.pt?symbol=${data}`;
  const request = fetch(url);
  return handleRequest(request);
}

function* stockDetailRequestFlow(action) {
  try {
    // grab the data from our action
    const { data, board, callback } = action;
    // call to our snapshotRequestApi function with the client
    const dataList = yield call(stockDetailRequestApi, data, board);

    let symbol;
    if (dataList && !!dataList.length) {
      symbol = _processMapDataCS(dataList[0]);
    }

    // dispatch the action with our symbol!
    if (!callback) yield put(stockDetailRequestSuccess(symbol));
    else callback(symbol);
  } catch (error) {
    yield put(stockDetailRequestError(error));
  }
}

function* stockTradeRequestFlow(action) {
  try {
    // grab the data from our action
    const { data, board, callback } = action;
    // call to our snapshotRequestApi function with the client
    const dataList = yield call(stockTradeRequestApi, data, board);

    // dispatch the action with our symbol!
    if (!callback) yield put(stockTradeRequestSuccess(dataList));
    else {
      callback(dataList);
    }
  } catch (error) {
    yield put(stockTradeRequestError(error));
  }
}

function* cstRequestFlow(action) {
  try {
    const { data } = action;
    const dataList = yield call(reportRequestApi, data);

    yield put(cstRequestSuccess(dataList));
  } catch (error) {
    yield put(cstRequestError(error));
  }
}

function* kqkdRequestFlow(action) {
  try {
    const { data } = action;
    const dataList = yield call(reportRequestApi, data);

    yield put(kqkdRequestSuccess(dataList));
  } catch (error) {
    yield put(kqkdRequestError(error));
  }
}

function* cdktRequestFlow(action) {
  try {
    const { data } = action;
    const dataList = yield call(reportRequestApi, data);

    yield put(cdktRequestSuccess(dataList));
  } catch (error) {
    yield put(cdktRequestError(error));
  }
}

function* stockHisRequestFlow(action) {
  try {
    const { data } = action;
    const dataList = yield call(reportHisRequestApi, data);

    yield put(stockHisRequestSuccess(Array.isArray(dataList) ? dataList : []));
  } catch (error) {
    yield put(stockTradeRequestError(error));
  }
}

function* stockNewsRequestFlow(action) {
  try {
    const { data } = action;
    const news = yield call(reportNewsRequestApi, data);

    yield put(stockNewsRequestSuccess(news));
  } catch (error) {
    yield put(stockNewsRequestError(error));
  }
}

function* stockEventsRequestFlow(action) {
  try {
    const { data } = action;
    const events = yield call(stockEventsRequestApi, data);

    yield put(stockEventsRequestSuccess(events));
  } catch (error) {
    yield put(stockEventsRequestError(error));
  }
}

function* stockManageRequestFlow(action) {
  try {
    const { data } = action;
    const events = yield call(stockManageRequestApi, data);

    yield put(stockManagementRequestSuccess(events));
  } catch (error) {
    yield put(stockManagementRequestError(error));
  }
}

function* companyInfoRequestFlow(action) {
  try {
    const { data } = action;
    const events = yield call(companyInfoRequestApi, data);

    yield put(companyInfoRequestSuccess(events));
  } catch (error) {
    yield put(companyInfoRequestError(error));
  }
}

function* stockDetailWatcher() {
  yield all([
    takeLatest(STOCK_DETAIL_REQUESTING, stockDetailRequestFlow),
    takeLatest(STOCK_TRADE_REQUESTING, stockTradeRequestFlow),
    takeLatest(CST_REQUESTING, cstRequestFlow),
    takeLatest(KQKD_REQUESTING, kqkdRequestFlow),
    takeLatest(CDKT_REQUESTING, cdktRequestFlow),
    takeLatest(STOCK_HIS_REQUESTING, stockHisRequestFlow),
    takeLatest(STOCK_NEWS_REQUESTING, stockNewsRequestFlow),
    takeLatest(STOCK_EVENTS_REQUESTING, stockEventsRequestFlow),
    takeLatest(STOCK_MANAGEMENT_REQUESTING, stockManageRequestFlow),
    takeLatest(COMPANY_INFO_REQUESTING, companyInfoRequestFlow),
  ]);
}

export default stockDetailWatcher;
