import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import uk from 'date-fns/locale/uk';
import { stringToDate } from '../util';
import { Portal } from 'react-overlays';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../containers/client/actions';

registerLocale('vi', vi);
registerLocale('en', uk);

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');

  return <Portal container={el}>{children}</Portal>;
};

const Index = withNamespaces('translations')(
  ({ t, callback, minDate, maxDate, startDate, endDate, disableStartDate }) => {
    const [from, setFrom] = useState(startDate);
    const [to, setTo] = useState(endDate);
    const { setting } = useSelector((state) => state.client);
    const lang = setting.lang || 'vi';
    const dispatch = useDispatch();

    const toastHandle = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    const onChangeHandle = (from, to) => {
      if (callback && typeof callback == 'function') callback(from, to);
    };

    useEffect(() => {
      if (from && to) {
        onChangeHandle(
          moment(from).format('DD/MM/YYYY'),
          moment(to).format('DD/MM/YYYY')
        );
      }
    }, [from, to]);

    return (
      <div className="filter-datePicker text--light">
        <span className="icon iCalendar"></span>
        <span className="filter-datePicker__from">
          <DatePicker
            disabled={disableStartDate}
            minDate={minDate ?? undefined}
            className="form-input"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              setFrom(date);
            }}
            selectsStart
            popperContainer={CalendarContainer}
            locale={lang}
            selected={from}
          />
        </span>{' '}
        {'-'}
        <span className="filter-datePicker__to">
          <DatePicker
            id="to-date"
            className="form-input"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              if (moment(date).diff(from, 'd') < 0) {
                toastHandle(t('time.toDateLessThanFromDate'));
              } else {
                setTo(date);
              }
            }}
            selected={to}
            selectsEnd
            popperContainer={CalendarContainer}
            popperClassName="datepicker-right"
            locale={lang}
          />
        </span>
      </div>
    );
  }
);

export default Index;
