import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { numberFormat } from '../../../util';
import { cloneDeep } from 'lodash';
import { constants } from '../../../util/constant';

const Assets = withNamespaces('translations')(
  ({ t, cashBalance, refresh, account, tabActive }) => {
    const lang = useSelector((state) => state.client.setting?.lang) || 'vi';
    const theme = useSelector((state) => state.client.currentTheme);
    const [assetAllocation, setAssetAllocation] = useState([]);
    const [debtToAsset, setDebtToAsset] = useState([]);

    const chartHandle = () => {
      if (cashBalance) {
        const _perStock = cashBalance.totAsst
          ? Math.round(cashBalance.stockValue * 100) / cashBalance.totAsst
          : 0;
        const _perBond = cashBalance.totAsst
          ? Math.round(cashBalance.totalBond * 100) / cashBalance.totAsst
          : 0;
        const _perDebt = cashBalance.totAsst
          ? Math.round(cashBalance.debt * 100) / cashBalance.totAsst
          : 0;

        const _perPineB = cashBalance.totAsst
          ? Math.round(cashBalance.pineBndValue * 100) / cashBalance.totAsst
          : 0;

        const _perDerivative =
          cashBalance?.totAsst != 0
            ? Math.round(cashBalance.drvtVsdAmt * 100) / cashBalance?.totAsst
            : 0;

        const _perCash =
          100 - _perBond - _perStock - _perPineB - _perDerivative;

        const asset0 = {
          name: 'personal.asset.bondOTC.name',
          value: _perBond,
          color: theme?.name == 'dark' ? '#FFE70B' : '#f0ae03',
        };

        const asset1 = {
          name: 'personal.asset.cash',
          value: _perCash,
          color: theme?.name == 'dark' ? '#00FF57' : '#2db35b',
        };

        const asset2 = {
          name: 'personal.asset.listedSecurity',
          value: _perStock,
          color: theme?.name == 'dark' ? '#da1ef9' : '#aa3fff',
        };

        const asset3 = {
          name: 'PineB',
          value: _perPineB,
          color: theme?.name == 'dark' ? '#AFC6D3' : '#AFC6D3',
        };

        const asset4 = {
          name: 'derivative.name',
          value: _perDerivative,
          color: theme?.name == 'dark' ? '#5F8EA7' : '##0F557B',
        };

        const debt = {
          name: 'personal.asset.debt',
          value: _perDebt,
          color: theme?.name == 'dark' ? '#FF233D' : '#f15555',
        };

        const nav = {
          name: 'personal.asset.netAssetValue',
          value: 100 - _perDebt,
          color: '#0F557B',
        };

        const _assetAllocation = [
          {
            name: asset1.name,
            y: asset1.value,
            color: asset1.color,
          },
          {
            name: asset2.name,
            y: asset2.value,
            color: asset2.color,
          },
          {
            name: asset0.name,
            y: asset0.value,
            color: asset0.color,
          },
          {
            name: asset3.name,
            y: asset3.value,
            color: asset3.color,
          },
          {
            name: asset4.name,
            y: asset4.value,
            color: asset4.color,
          },
        ];

        const _debtToAsset = [
          {
            name: nav.name,
            y: nav.value,
            color: nav.color,
          },
          {
            name: debt.name,
            y: debt.value,
            color: debt.color,
          },
        ];

        setAssetAllocation(_assetAllocation);
        setDebtToAsset(_debtToAsset);
      }
    };

    // const allLocationMemo = useMemo
    useEffect(() => {
      chartHandle();
    }, [cashBalance]);

    return (
      <div className="personal-assets card-panel-2">
        <div className="personal-assets-body card-panel-2-body">
          <PerfectScrollBar>
            <div className="body-wrapper">
              <div className="section-overview">
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.totalAsset')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance && numberFormat(cashBalance.totAsst, 0, '0')}
                  </span>
                </div>
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.netAssetValue')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance && numberFormat(cashBalance.realAsst, 0, '0')}
                  </span>
                </div>
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.withdrawable')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance &&
                      numberFormat(cashBalance.wdrawAvail, 0, '0')}
                  </span>
                </div>
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.availableAdvance')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance &&
                      numberFormat(cashBalance.advanceAvail, 0, '0')}
                  </span>
                </div>
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.marginDebt')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance && numberFormat(cashBalance.mgDebt, 0, '0')}
                  </span>
                </div>
                {tabActive != 0 && (
                  <>
                    <div className="overview-metric">
                      <span className="overview-metric__key">
                        {t('personal.asset.totalOverview.rTT')}
                      </span>
                      <span className="overview-metric__value">
                        {cashBalance &&
                          numberFormat(cashBalance.marginRatio, 2, '0') + '%'}
                      </span>
                    </div>
                    {account?.subAcntClss !=
                      constants.subAccount.Derivative && (
                      <div className="overview-metric">
                        <span className="overview-metric__key">
                          {t('personal.asset.totalOverview.pP')}
                        </span>
                        <span className="overview-metric__value">
                          {cashBalance && numberFormat(cashBalance.pp0, 0, '0')}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="section-chart">
                <div className="chart-panel asset-allowcation">
                  <div className="section-chart__info">
                    <span className="text-title">
                      {t('personal.asset.assetAllocation')}
                    </span>
                    {assetAllocation &&
                      assetAllocation.map((item, i) => (
                        <span key={i} className="metric">
                          <span className="metric__value">
                            <span
                              className="circle"
                              style={{ backgroundColor: item.color }}
                            ></span>
                            <span>{item.y?.toFixed(2)} %</span>
                          </span>
                          <span className="metric__key">{t(item.name)}</span>
                        </span>
                      ))}
                  </div>
                  <div className="section-chart__chart">
                    <PieChart key={lang} data={assetAllocation} />
                  </div>
                </div>
                <div className="chart-panel debt-to-asset">
                  <div className="section-chart__info">
                    <span className="text-title">
                      {t('personal.asset.debtToAssetRatio')}
                    </span>
                    {debtToAsset &&
                      debtToAsset.map((item, i) => (
                        <span key={i} className="metric">
                          <span className="metric__value">
                            <span
                              className="circle"
                              style={{ backgroundColor: item.color }}
                            ></span>
                            <span>{item.y?.toFixed(2)} %</span>
                          </span>
                          <span className="metric__key">{t(item.name)}</span>
                        </span>
                      ))}
                  </div>
                  <div className="section-chart__chart">
                    <PieChart key={lang} data={debtToAsset} />
                  </div>
                </div>
              </div>
              <div className="section-details">
                <div className="card-panel-2 section-detail">
                  <div className="personal-assets-header card-panel-2-header">
                    <div className="personal-assets-header__left">
                      <span className="text text--light">
                        {t('personal.asset.cash')}
                      </span>
                      <span className="ml-1">(1 + 2 + 3 - 4.1 + 6 - 7)</span>
                    </div>
                    <div className="personal-assets-header__right i">
                      <span className="text text--md">
                        {cashBalance && numberFormat(cashBalance.cash, 0, '0')}
                      </span>
                    </div>
                  </div>
                  <div className="personal-assets-body p-0">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td className="text-left w-60 fw-500">
                            1. {t('txt-asset-cash-balance')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.balance, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            2. {t('txt-asset-receivable-1')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.advanceAvail, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              2.1. {t('txt-asset-receivable-1')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.receiveAmt, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              2.2. {t('txt-AdvanceDebt')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.advanceLoan, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            3. {t('txt-CashDividence')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.cashDiv, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            4. {t('txt-BoughtT0')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.buyT0, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              4.1. {t('txt-BoughtT0-normal')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(
                                cashBalance.buyT0 - cashBalance.exptDisbm,
                                0,
                                '0'
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              4.2. {t('txt-BoughtT0-margin')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.exptDisbm, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            5. {t('txt-UnMatchedBoughtT0')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(
                                cashBalance.tdtBuyAmtNotMatch,
                                0,
                                '0'
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            6. {t('txt-funds-accrued-in-plan')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.ipCash, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            7. {t('txt-derivatives-Fee-Tax-T0')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.drvtOdFee, 0, '0')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex flex-column flex-1 gap-12">
                  <div className="card-panel-2 section-detail">
                    <div className="personal-assets-header card-panel-2-header">
                      <div className="personal-assets-header__left">
                        <span className="text text--light">
                          {t('personal.asset.listedSecurity')}
                        </span>
                        <span className="ml-1">(1 + 2)</span>
                      </div>
                      <div className="personal-assets-header__right c">
                        <span className="text text--md">
                          {cashBalance &&
                            numberFormat(cashBalance.stockValue, 0, '0')}
                        </span>
                      </div>
                    </div>
                    <div className="personal-assets-body p-0">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td className="text-left w-60 fw-500">
                              1. {t('txt-TotalStocks')}
                            </td>
                            <td className="text text-right text--light fw-500">
                              {cashBalance &&
                                numberFormat(cashBalance.totalStock, 0, '0')}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                              <span className="ml-3 text text--light3">
                                1.1. {t('txt-AvailTradingStock')}
                              </span>
                            </td>
                            <td className="text text-right text--light">
                              {cashBalance &&
                                numberFormat(
                                  cashBalance.tavlStockValue,
                                  0,
                                  '0'
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                              <span className="ml-3 text text--light3">
                                1.2. {t('txt-PTAStock')}
                              </span>
                            </td>
                            <td className="text text-right text--light">
                              {cashBalance &&
                                numberFormat(
                                  cashBalance.ptavlStockValue,
                                  0,
                                  '0'
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                              <span className="ml-3 text text--light3">
                                1.3. {t('txt-TARestric')}
                              </span>
                            </td>
                            <td className="text text-right text--light">
                              {cashBalance &&
                                numberFormat(
                                  cashBalance.tartStockValue,
                                  0,
                                  '0'
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                              <span className="ml-3 text text--light3">
                                1.4. {t('txt-PTARestric')}
                              </span>
                            </td>
                            <td className="text text-right text--light">
                              {cashBalance &&
                                numberFormat(
                                  cashBalance.ptartStockValue,
                                  0,
                                  '0'
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                              <span className="ml-3 text text--light3">
                                1.5. {t('txt-RightStock')}
                              </span>
                            </td>
                            <td className="text text-right text--light">
                              {cashBalance &&
                                numberFormat(
                                  cashBalance.righStockValue,
                                  0,
                                  '0'
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left fw-500">
                              2. {t('txt-ReceivingStockQtty')}
                            </td>
                            <td className="text text-right text--light fw-500">
                              {cashBalance &&
                                numberFormat(cashBalance.rcvStockValue, 0, '0')}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left fw-500">
                              3. {t('txt-SoldT0')}
                            </td>
                            <td className="text text-right text--light fw-500">
                              {cashBalance &&
                                numberFormat(cashBalance.sellT0, 0, '0')}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="card-panel-2 section-detail">
                  <div className="personal-assets-header card-panel-2-header">
                    <div className="personal-assets-header__left">
                      <span className="text text--light">
                        {t('derivative.name')}
                      </span>
                      <span className="ml-1">(1-2)</span>
                    </div>
                    <div className="personal-assets-header__right">
                      <span className="text text--md text--light-blue3">
                        {cashBalance &&
                          numberFormat(cashBalance.drvtVsdAmt, 0, '0')}
                      </span>
                    </div>
                  </div>
                  <div className="personal-assets-body p-0">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td className="text-left w-60 fw-500">
                            <span className="text text--light">
                              1. {t('txt-asset')}
                            </span>
                            <span className="ml-1 text text--light3">
                              (1.1+1.2+1.4)
                            </span>
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(
                                cashBalance.drvtCIm +
                                  cashBalance.drvtTA +
                                  cashBalance.drvtCdtPnlVM,
                                0,
                                '0'
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              1.1. {t('personal.asset.derivative.cashAtVSD')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.drvtCIm, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              1.2. {t('personal.asset.derivative.valueAtVSD')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.drvtTA, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              1.3. {t('personal.asset.derivative.usedMargin')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.drvtMrVsd, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              1.4.{' '}
                              {t(
                                'personal.asset.derivative.profitWaitForSettlement'
                              )}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.drvtCdtPnlVM, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            2. {t('personal.asset.derivative.liability')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.drvtCdtLossVM, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              2.1.{' '}
                              {t('personal.asset.derivative.lossSettlement')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.drvtCdtLossVM, 0, '0')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-panel-2 section-detail">
                  <div className="personal-assets-header card-panel-2-header">
                    <div className="personal-assets-header__left">
                      <span className="text-white">
                        {t('personal.asset.bondOTC.name')}
                      </span>
                      <span className="ml-1">(1 + 2)</span>
                    </div>
                    <div className="personal-assets-header__right r">
                      <span className="text-highlight">
                        {cashBalance &&
                          numberFormat(cashBalance.totalBond, 0, '0')}
                      </span>
                    </div>
                  </div>
                  <div className="personal-assets-body p-0">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td className="text-left w-60 fw-500">
                            1. {t('personal.asset.bondOTC.availBond')}
                          </td>
                          <td className="text-right text-white fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.tavlBondValue, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            2. {t('personal.asset.bondOTC.receiving')}
                          </td>
                          <td className="text-right text-white fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.rcvBondValue, 0, '0')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="personal-assets-header card-panel-2-header">
                    <div className="personal-assets-header__left">
                      <span className="text-white">PineB</span>
                      <span className="ml-1">(1 + 2 + 3)</span>
                    </div>
                    <div className="personal-assets-header__right ">
                      <span className="text-highlight text--light-blue2">
                        {cashBalance &&
                          numberFormat(cashBalance.pineBndValue, 0, '0')}
                      </span>
                    </div>
                  </div>
                  <div className="personal-assets-body p-0">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td className="text-left w-60 fw-500">
                            1. PineB Fix
                          </td>
                          <td className="text-right text-white fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.pineBndFixValue, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">2. PineB Flex</td>
                          <td className="text-right text-white fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.pineBndVarValue, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">3. PineB Growth</td>
                          <td className="text-right text-white fw-500">
                            {cashBalance &&
                              numberFormat(
                                cashBalance.pineBndGrthValue,
                                0,
                                '0'
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-panel-2 section-detail">
                  <div className="personal-assets-header card-panel-2-header">
                    <div className="personal-assets-header__left">
                      <span className="text text--light">
                        {t('personal.asset.debt')}
                      </span>
                      <span className="ml-1">(1 + 2 + 3 + 4)</span>
                    </div>
                    <div className="personal-assets-header__right d">
                      <span className="text text--md">
                        {cashBalance && numberFormat(cashBalance.debt, 0, '0')}
                      </span>
                    </div>
                  </div>
                  <div className="personal-assets-body p-0">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td className="text-left w-60 fw-500">
                            1. {t('txt-MarginDebt')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.mgDebt, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              1.1. {t('txt-Principal')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.prinDebt, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              1.2. {t('txt-Interest')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.intDebt, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            2. {t('txt-ExpectedDisbursement')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.exptDisbm, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            3. {t('txt-derivatives-debt-pay-late')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.drvDebtPayLate, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left fw-500">
                            4. {t('txt-label-fee')}
                          </td>
                          <td className="text text-right text--light fw-500">
                            {cashBalance &&
                              numberFormat(cashBalance.fee, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              4.1. {t('txt-depoFee')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.depoFee, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              4.2. {t('txt-asset-SMS-fee')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.smsFee, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              4.3. {t('txt-asset-other-fee')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.othrFee, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              4.4. {t('txt-position-management-fee')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.drvtPoFee, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              4.5. {t('txt-asset-management-fee')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(cashBalance.drvtImFee, 0, '0')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="ml-3 text text--light3">
                              4.6. {t('txt-derivative-fee-tax-deb')}
                            </span>
                          </td>
                          <td className="text text-right text--light">
                            {cashBalance &&
                              numberFormat(
                                cashBalance.drvtFeeTaxLoanAmt,
                                0,
                                '0'
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: '150px' }}></div>
          </PerfectScrollBar>
        </div>
      </div>
    );
  }
);

const PieChart = withNamespaces('translations')(({ t, data }) => {
  console.log('Pie chart', data);
  if (!data || data.length == 0) return null;
  const _data = cloneDeep(data);
  _data.map((item) => {
    item.name = t(item.name);
    item.y = Number(numberFormat(item.y, 2, '0'));
  });

  const [chartOptions, setChartOptions] = useState({});

  const mapDataToState = () => {
    const chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 250,
        width: 250,
        spacingLeft: 10,
        backgroundColor: 'transparent',
      },
      title: { text: ' ' },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
          borderWidth: 0,
          // colors: COLORS,
        },
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemStyle: { color: '#ffffff' },
      },
      series: [
        {
          name: 'Tỷ lệ',
          colorByPoint: true,
          innerSize: '50%',
          data: _data,
        },
      ],
    };
    setChartOptions(chartOptions);
  };

  useEffect(() => {
    if (data) mapDataToState();
  }, [data]);

  console.log('chartOptions', chartOptions);
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      //   containerProps={{ style: { height: '200px', width: '200px' } }}
    />
  );
});

export default Assets;
