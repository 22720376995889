import { LOGIN_SUCCESS } from '../../components/modal/login/constants';
import { DERIVATIVE_CONSTANTS } from '../../util/constant';
import {
  CONTRACT_ACTIVE_SET,
  GET_DETAIL_REQUEST,
  GET_DETAIL_SUCCESS,
  GET_ACTV_DVX_ORDER_ERROR,
  GET_ACTV_DVX_ORDER_REQUEST,
  GET_ACTV_DVX_ORDER_SUCCESS,
  GET_DVX_CLOSED_POS_ERROR,
  GET_DVX_CLOSED_POS_REQUEST,
  GET_DVX_CLOSED_POS_SUCCESS,
  GET_DVX_CURR_POS_ERROR,
  GET_DVX_CURR_POS_REQUEST,
  GET_DVX_CURR_POS_SUCCESS,
  GET_DVX_MATCH_ORDER_REQUEST,
  GET_DVX_MATCH_ORDER_SUCCESS,
  GET_DVX_PNL_RPT_ERROR,
  GET_DVX_PNL_RPT_REQUEST,
  GET_DVX_PNL_RPT_SUCCESS,
  GET_DVX_PP_ERROR,
  GET_DVX_PP_REQUEST,
  GET_DVX_PP_SUCCESS,
  GET_DVX_TOTAL_ASSET_ERROR,
  GET_DVX_TOTAL_ASSET_REQUEST,
  GET_DVX_TOTAL_ASSET_SUCCESS,
  GET_LIST_DVX_ERROR,
  GET_LIST_DVX_REQUEST,
  GET_LIST_DVX_SUCCESS,
  GET_PRICEBOARD_REQUEST,
  GET_PRICEBOARD_SUCCESS,
  SET_CURRENT_SYMBOL,
  SET_SHOW_OPEN_ORDERS_PANEL,
  SET_SHOW_PLACE_ORDER_PANEL,
  SET_SHOW_POSITION_PANEL,
  SORT_DIRECTION_CHANGE,
  SORT_FIELD_CHANGE,
  GET_DETAIL_RESET,
  CLOSE_ALL_DVX_POS_REQUEST,
  CLOSE_ALL_DVX_POS_SUCCESS,
  CLOSE_ALL_DVX_POS_ERROR,
  GET_CFM_CLOSE_REVT_DVX_POS_REQUEST,
  GET_CFM_CLOSE_REVT_DVX_POS_SUCCESS,
  GET_CFM_CLOSE_REVT_DVX_POS_ERROR,
  SET_SHOW_DETAIL_MODAL,
  SET_DERIVATIVE_ORDER,
  SET_ORDER_PRICE,
  SET_ORDER_QUANTITY,
  SET_DEFAULT_TAB,
  SWITCH_DVX_LIST,
  REGISTER_SOCKET_DRVX,
  LEAVE_SOCKET_DRVX,
  SET_DEPOSIT_WITHDRAWTH_MARGIN_MODAL,
  RECALL_CURR_POS_REQUEST,
  RECALL_ORDER_LIST_REQUEST,
  RECALL_CONDITION_ORDER_LIST_REQUEST,
  PUSH_CONDITION_ORDER_DATA_REALTIME,
  SET_TAB_ORDER,
} from './constants';

const initialSate = {
  contractActive: '',
  sortField: 'sym',
  isAsc: true,
  seltp: DERIVATIVE_CONSTANTS.DRVX_TYPE.INDEX, // type of contract future || Index: 21 (default) | bond (5 years): 22 | bond (10 years): 23

  tabDefault: DERIVATIVE_CONSTANTS.POSITION_PANEL.POSITION_TAB,
  isShowPlaceOrderPanel: false,
  isShowAssetPanel: false,
  isShowPositionPanel: false,

  getListDvxRequesting: false,
  listDvx: null,
  getListDvxSuccess: false,
  getListDvxError: null,

  getDvxPPRequesting: false,
  dvxPP: null,
  getDvxPPSuccess: false,
  getDvxPPError: null,

  getDvxCurrPosRequesting: false,
  dvxCurrPos: null,
  getDvxCurrPosSuccess: false,
  getDvxCurrPosError: null,
  recallDvxCurrPos: false, // using for trigger 1 recall api to get derivative current position

  getActvDvxOrderRequest: false,
  actvDvxOrder: null,
  getActvDvxOrderSuccess: false,
  getActvDvxOrderError: null,
  recallActvDvxOrder: false, // using for trigger 1 recall api to get derivative current active orders
  recalConditionOrder: false,
  dataSocketConditionOrder: undefined,
  tabOrderGlobal: 0,

  getDvxMatchOrderRequesting: false,
  dvxMatchOrder: null,
  getDvxMatchOrderSuccess: false,
  getDvxMatchOrderError: null,

  getDvxClosePosRequesting: false,
  dvxClosePos: null,
  getDvxClosePosSuccess: false,
  getDvxClosePosError: null,

  getDvxPnlRptRequesting: false,
  dvxPnlRpt: null,
  getDvxPnlRptSuccess: false,
  getDvxPnlRptError: null,

  getDvxTotalAssetRequesting: false,
  dvxTotalAsset: null,
  getDvxTotalAssetSuccess: false,
  getDvxTotalAssetError: false,
  symbols: [],
  currentSymbol: '',
  contractDetail: null,
  showModalDetail: false,

  closeAllDvxPosRequesting: false,
  closeAllDvxPosResp: null,
  closeAllDvxPosSuccess: false,
  closeAllDvxPosError: null,

  getCfmClsRevtDvxPosRequesting: false,
  cfmClsRevtDvxPos: null,
  getCfmClsRevtDvxPosSuccess: false,
  getCfmClsRevtDvxPosError: null,

  resetSettingConfirm: false,
  derivativeOrder: null,

  orderPrice: null,
  orderQuantity: null,
  drvxRegSyms: [],

  showDepositWithdrawMarginModal: false,
};

const reducer = function derivativeReducer(state = initialSate, action) {
  switch (action.type) {
    case 'LOG_OUT': {
      return {
        ...state,
        resetSettingConfirm: true,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        resetSettingConfirm: false,
      };
    }

    case CONTRACT_ACTIVE_SET:
      return {
        ...state,
        contractActive: action.data,
      };

    case SORT_DIRECTION_CHANGE:
      const { isAsc } = state;
      return {
        ...state,
        isAsc: !isAsc,
      };

    case SORT_FIELD_CHANGE:
      return {
        ...state,
        sortField: action.sortField,
      };

    case SET_CURRENT_SYMBOL:
      return {
        ...state,
        currentSymbol: action.sym,
      };

    case SET_DEFAULT_TAB:
      return {
        ...state,
        tabDefault: action.tab,
      };

    case SET_SHOW_PLACE_ORDER_PANEL: {
      return {
        ...state,
        isShowPlaceOrderPanel: action.isShowPlaceOrderPanel,
      };
    }

    case SET_SHOW_OPEN_ORDERS_PANEL: {
      return {
        ...state,
        isShowAssetPanel: action.isShowAssetPanel,
      };
    }

    case SET_SHOW_POSITION_PANEL: {
      return {
        ...state,
        isShowPositionPanel: action.isShowPositionPanel,
      };
    }

    case GET_LIST_DVX_REQUEST: {
      return {
        ...state,
        getListDvxRequesting: true,
        listDvx: [],
        getListDvxSuccess: false,
        getListDvxError: [],
      };
    }

    case GET_LIST_DVX_SUCCESS: {
      return {
        ...state,
        getListDvxRequesting: false,
        listDvx: action.listDvx,
        getListDvxSuccess: true,
        getListDvxError: [],
      };
    }

    case GET_LIST_DVX_ERROR: {
      return {
        ...state,
        getListDvxRequesting: false,
        listDvx: [],
        getListDvxSuccess: false,
        getListDvxError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_DVX_PP_REQUEST: {
      return {
        ...state,
        getDvxPPRequesting: true,
        getDvxPPSuccess: false,
        getDvxPPError: [],
      };
    }

    case GET_DVX_PP_SUCCESS: {
      return {
        ...state,
        getDvxPPRequesting: false,
        dvxPP: action.dvxPP,
        getDvxPPSuccess: true,
        getDvxPPError: [],
      };
    }

    case GET_DVX_PP_ERROR: {
      return {
        ...state,
        getDvxPPRequesting: false,
        dvxPP: [],
        getDvxPPSuccess: false,
        getDvxPPError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_DVX_CURR_POS_REQUEST: {
      return {
        ...state,
        getDvxCurrPosRequesting: true,
        dvxCurrPos: null,
        getDvxCurrPosSuccess: false,
        getDvxCurrPosError: null,
      };
    }

    case GET_DVX_CURR_POS_SUCCESS: {
      return {
        ...state,
        getDvxCurrPosRequesting: false,
        dvxCurrPos: action.dvxCurrPos,
        getDvxCurrPosSuccess: true,
        getDvxCurrPosError: null,
      };
    }

    case GET_DVX_CURR_POS_ERROR: {
      return {
        ...state,
        getDvxCurrPosRequesting: false,
        dvxCurrPos: null,
        getDvxCurrPosSuccess: false,
        getDvxCurrPosError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_ACTV_DVX_ORDER_REQUEST: {
      return {
        ...state,
        getActvDvxOrderRequest: true,
        actvDvxOrder: null,
        getActvDvxOrderSuccess: false,
        getActvDvxOrderError: null,
      };
    }

    case GET_ACTV_DVX_ORDER_SUCCESS: {
      return {
        ...state,
        getActvDvxOrderRequest: false,
        actvDvxOrder: action.actvDvxOrder,
        getActvDvxOrderSuccess: true,
        getActvDvxOrderError: null,
      };
    }

    case GET_ACTV_DVX_ORDER_ERROR: {
      return {
        ...state,
        getActvDvxOrderRequest: false,
        actvDvxOrder: null,
        getActvDvxOrderSuccess: false,
        getActvDvxOrderError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_DVX_MATCH_ORDER_REQUEST: {
      return {
        ...state,
        getDvxMatchOrderRequesting: true,
        dvxMatchOrder: null,
        getDvxMatchOrderSuccess: false,
        getDvxMatchOrderError: null,
      };
    }

    case GET_DVX_MATCH_ORDER_SUCCESS: {
      return {
        ...state,
        getDvxMatchOrderRequesting: false,
        dvxMatchOrder: action.dvxMatchOrder,
        getDvxMatchOrderSuccess: true,
        getDvxMatchOrderError: null,
      };
    }

    case GET_DVX_CLOSED_POS_REQUEST: {
      return {
        ...state,
        getDvxClosePosRequesting: true,
        dvxClosePos: null,
        getDvxClosePosSuccess: false,
        getDvxClosePosError: null,
      };
    }

    case GET_DVX_CLOSED_POS_SUCCESS: {
      return {
        ...state,
        getDvxClosePosRequesting: false,
        dvxClosePos: action.dvxClosePos,
        getDvxClosePosSuccess: true,
        getDvxClosePosError: null,
      };
    }

    case GET_DVX_CLOSED_POS_ERROR: {
      return {
        ...state,
        getDvxClosePosRequesting: false,
        dvxClosePos: null,
        getDvxClosePosSuccess: false,
        getDvxClosePosError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_DVX_PNL_RPT_REQUEST: {
      return {
        ...state,
        getDvxPnlRptRequesting: true,
        dvxPnlRpt: null,
        getDvxPnlRptSuccess: false,
        getDvxPnlRptError: null,
      };
    }

    case GET_DVX_PNL_RPT_SUCCESS: {
      return {
        ...state,
        getDvxPnlRptRequesting: false,
        dvxPnlRpt: action.dvxPnlRpt,
        getDvxPnlRptSuccess: true,
        getDvxPnlRptError: null,
      };
    }

    case GET_DVX_PNL_RPT_ERROR: {
      return {
        ...state,
        getDvxPnlRptRequesting: false,
        dvxPnlRpt: null,
        getDvxPnlRptSuccess: false,
        getDvxPnlRptError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_DVX_TOTAL_ASSET_REQUEST: {
      return {
        ...state,
        getDvxTotalAssetRequesting: true,
        dvxTotalAsset: null,
        getDvxTotalAssetSuccess: false,
        getDvxTotalAssetError: null,
      };
    }

    case GET_DVX_TOTAL_ASSET_SUCCESS: {
      return {
        ...state,
        getDvxTotalAssetRequesting: false,
        dvxTotalAsset: action.dvxTotalAsset,
        getDvxTotalAssetSuccess: true,
        getDvxTotalAssetError: null,
      };
    }

    case GET_DVX_TOTAL_ASSET_ERROR: {
      return {
        ...state,
        getDvxTotalAssetRequesting: false,
        dvxTotalAsset: null,
        getDvxTotalAssetSuccess: false,
        getDvxTotalAssetError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }
    case GET_PRICEBOARD_REQUEST: {
      return {
        ...state,
        symbols: [],
      };
    }
    case GET_PRICEBOARD_SUCCESS: {
      return {
        ...state,
        symbols: action.data,
      };
    }

    case GET_DETAIL_REQUEST: {
      console.log('action:  ', action);
      if (!action?.callback)
        return {
          ...state,
          contractDetail: null,
        };
      else return { ...state };
    }

    case GET_DETAIL_SUCCESS: {
      return {
        ...state,
        contractDetail: action.data[0],
      };
    }

    case CLOSE_ALL_DVX_POS_REQUEST: {
      return {
        ...state,
        closeAllDvxPosRequesting: true,
        closeAllDvxPosResp: null,
        closeAllDvxPosSuccess: false,
        closeAllDvxPosError: null,
      };
    }
    case CLOSE_ALL_DVX_POS_SUCCESS: {
      return {
        ...state,
        closeAllDvxPosRequesting: false,
        closeAllDvxPosResp: action.closeAllDvxPosResp,
        closeAllDvxPosSuccess: true,
        closeAllDvxPosError: null,
      };
    }
    case CLOSE_ALL_DVX_POS_ERROR: {
      return {
        ...state,
        closeAllDvxPosRequesting: false,
        closeAllDvxOrderResp: null,
        closeAllDvxPosSuccess: false,
        closeAllDvxPosError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_CFM_CLOSE_REVT_DVX_POS_REQUEST: {
      return {
        ...state,
        getCfmClsRevtDvxPosRequesting: true,
        cfmClsRevtDvxPos: null,
        getCfmClsRevtDvxPosSuccess: false,
        getCfmClsRevtDvxPosError: null,
      };
    }

    case GET_CFM_CLOSE_REVT_DVX_POS_SUCCESS: {
      return {
        ...state,
        getCfmClsRevtDvxPosRequesting: false,
        cfmClsRevtDvxPos: action.cfmClsRevtDvxPos,
        getCfmClsRevtDvxPosSuccess: true,
        getCfmClsRevtDvxPosError: null,
      };
    }

    case GET_CFM_CLOSE_REVT_DVX_POS_ERROR: {
      return {
        ...state,
        getCfmClsRevtDvxPosRequesting: false,
        cfmClsRevtDvxPos: null,
        getCfmClsRevtDvxPosSuccess: false,
        getCfmClsRevtDvxPosError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_DETAIL_RESET: {
      return {
        ...state,
        contractDetail: null,
      };
    }

    case SET_SHOW_DETAIL_MODAL: {
      const { status } = action;
      return {
        ...state,
        showModalDetail: status,
        isShowPlaceOrderPanel: status ? false : state.isShowPlaceOrderPanel, // close order panel if open modal detail
        isShowAssetPanel: status ? false : state.isShowAssetPanel,
        isShowPositionPanel: status ? false : state.isShowPositionPanel,
      };
    }

    case SET_DERIVATIVE_ORDER: {
      return {
        ...state,
        derivativeOrder: action.order,
      };
    }

    case SET_ORDER_PRICE: {
      debugger;
      const { price } = action;
      return {
        ...state,
        orderPrice: price,
      };
    }

    case SET_ORDER_QUANTITY: {
      const { quantity, type } = action;

      return {
        ...state,
        orderQuantity: quantity,
      };
    }

    case SWITCH_DVX_LIST: {
      return {
        ...state,
        seltp: action.seltp,
      };
    }

    case REGISTER_SOCKET_DRVX: {
      console.log('REGISTER_SOCKET_DRVX');

      let { contracts, component } = action,
        _drvxRegSyms = [];
      const { drvxRegSyms } = state;

      if (contracts && !Array.isArray(contracts)) {
        contracts = [contracts];
      }

      // append if drvxRegSym is array already
      if (drvxRegSyms && drvxRegSyms?.length > 0) {
        // check by key map component
        const drvxRegSymIdx = drvxRegSyms.findIndex((x) => x.key == component);
        if (drvxRegSymIdx !== -1) {
          _drvxRegSyms = drvxRegSyms;
          _drvxRegSyms[drvxRegSymIdx].contracts = contracts;
        } else
          _drvxRegSyms = [
            ...drvxRegSyms,
            { key: component, contracts: contracts },
          ];
      } else {
        _drvxRegSyms = [{ key: component, contracts: contracts }];
      }

      return {
        ...state,
        drvxRegSyms: _drvxRegSyms,
      };
    }

    case LEAVE_SOCKET_DRVX: {
      let { contracts, component } = action,
        _drvxRegSyms = [];

      if (contracts && !Array.isArray(contracts)) {
        contracts = [contracts];
      }

      _drvxRegSyms = state?.drvxRegSyms.filter((x) => x.key != component) || [];

      return {
        ...state,
        drvxRegSyms: _drvxRegSyms,
      };
    }

    case SET_DEPOSIT_WITHDRAWTH_MARGIN_MODAL: {
      const { show } = action;

      return {
        ...state,
        showDepositWithdrawMarginModal: show,
      };
    }

    case RECALL_CURR_POS_REQUEST: {
      return {
        ...state,
        recallDvxCurrPos: !state.recallDvxCurrPos,
      };
    }

    case RECALL_ORDER_LIST_REQUEST: {
      return {
        ...state,
        recallActvDvxOrder: !state.recallActvDvxOrder,
      };
    }

    case RECALL_CONDITION_ORDER_LIST_REQUEST: {
      return {
        ...state,
        recalConditionOrder: !state.recalConditionOrder,
      };
    }

    case PUSH_CONDITION_ORDER_DATA_REALTIME: {
      return {
        ...state,
        dataSocketConditionOrder: action.data,
      };
    }

    case SET_TAB_ORDER: {
      return {
        ...state,
        tabOrderGlobal: action.data,
      };
    }

    default:
      return state;
  }
};

export default reducer;
