export const ALL_STOCK_REQUESTING = 'ALL_STOCK_REQUESTING';
export const ALL_STOCK_REQUEST_SUCCESS = 'ALL_STOCK_REQUEST_SUCCESS';
export const ALL_STOCK_REQUEST_ERROR = 'ALL_STOCK_REQUEST_ERROR';

export const LIST_MESSAGE_REQUESTING = 'LIST_MESSAGE_REQUESTING';
export const LIST_MESSAGE_REQUEST_SUCCESS = 'LIST_MESSAGE_REQUEST_SUCCESS';
export const LIST_MESSAGE_REQUEST_ERROR = 'LIST_MESSAGE_REQUEST_ERROR';

export const SNAPSHOT_REQUESTING = 'SNAPSHOT_REQUESTING';
export const SNAPSHOT_REQUEST_SUCCESS = 'SNAPSHOT_REQUEST_SUCCESS';
export const SNAPSHOT_REQUEST_ERROR = 'SNAPSHOT_REQUEST_ERROR';

export const SNAPSHOT_SYMBOL_DETAIL_REQUESTING =
  'SNAPSHOT_SYMBOL_DETAIL_REQUESTING';

export const CLEAR_PART_REQUEST = 'CLEAR_PART_REQUEST';
export const SNAPSHOT_PART_REQUESTING = 'SNAPSHOT_PART_REQUESTING';
export const SNAPSHOT_PART_REQUEST_SUCCESS = 'SNAPSHOT_PART_REQUEST_SUCCESS';
export const SNAPSHOT_PART_REQUEST_ERROR = 'SNAPSHOT_PART_REQUEST_ERROR';

export const STOCK_INFO_REQUESTING = 'STOCK_INFO_REQUESTING';
export const STOCK_INFO_REQUEST_SUCCESS = 'STOCK_INFO_REQUEST_SUCCESS';
export const STOCK_INFO_REQUEST_ERROR = 'STOCK_INFO_REQUEST_ERROR';

export const ALL_ORD_REQUESTING = 'ALL_ORD_REQUESTING';
export const ALL_ORD_REQUEST_SUCCESS = 'ALL_ORD_REQUEST_SUCCESS';
export const ALL_ORD_REQUEST_ERROR = 'ALL_ORD_REQUEST_ERROR';

export const PT_LIST_REQUESTING = 'PT_LIST_REQUESTING';
export const PT_LIST_REQUEST_SUCCESS = 'PT_LIST_REQUEST_SUCCESS';
export const PT_LIST_REQUEST_ERROR = 'PT_LIST_REQUEST_ERROR';

export const CW_LIST_REQUESTING = 'CW_LIST_REQUESTING';
export const CW_LIST_REQUEST_SUCCESS = 'CW_LIST_REQUEST_SUCCESS';
export const CW_LIST_REQUEST_ERROR = 'CW_LIST_REQUEST_ERROR';

export const LIST_30_REQUESTING = 'LIST_30_REQUESTING';
export const LIST_30_REQUEST_SUCCESS = 'LIST_30_REQUEST_SUCCESS';
export const LIST_30_REQUEST_ERROR = 'LIST_30_REQUEST_ERROR';

export const BRANCH_CODE_REQUESTING = 'BRANCH_CODE_REQUESTING';
export const BRANCH_CODE_REQUEST_SUCCESS = 'BRANCH_CODE_REQUEST_SUCCESS';
export const BRANCH_CODE_REQUEST_ERROR = 'BRANCH_CODE_REQUEST_ERROR';

export const NOI_CAP_REQUESTING = 'NOI_CAP_REQUESTING';
export const NOI_CAP_REQUEST_SUCCESS = 'NOI_CAP_REQUEST_SUCCESS';
export const NOI_CAP_REQUEST_ERROR = 'NOI_CAP_REQUEST_ERROR';

export const SUMMARY_REQUESTING = 'SUMMARY_REQUESTING';
export const SUMMARY_REQUEST_SUCCESS = 'SUMMARY_REQUEST_SUCCESS';
export const SUMMARY_REQUEST_ERROR = 'SUMMARY_REQUEST_ERROR';

export const GET_STOCK_BY_ID_REQUESTING = 'GET_STOCK_BY_ID_REQUESTING';
export const GET_STOCK_BY_ID_REQUEST_SUCCESS =
  'GET_STOCK_BY_ID_REQUEST_SUCCESS';
export const GET_STOCK_BY_ID_REQUEST_ERROR = 'GET_STOCK_BY_ID_REQUEST_ERROR';

export const INDEX_REQUESTING = 'INDEX_REQUESTING';
export const INDEX_REQUEST_SUCCESS = 'INDEX_REQUEST_SUCCESS';
export const INDEX_REQUEST_ERROR = 'INDEX_REQUEST_ERROR';

export const WORLD_INDEX_REQUESTING = 'WORLD_INDEX_REQUESTING';
export const WORLD_INDEX_REQUEST_SUCCESS = 'WORLD_INDEX_REQUEST_SUCCESS';
export const WORLD_INDEX_REQUEST_ERROR = 'WORLD_INDEX_REQUEST_ERROR';

export const PRICE_TABLE_SORT_FIELD_CHANGE = 'PRICE_TABLE_SORT_FIELD_CHANGE';
export const PRICE_TABLE_SORT_DIRECTION_CHAGE =
  'PRICE_TABLE_SORT_DIRECTION_CHAGE';

export const SET_PRICE_TABLE_SYMBOLS = 'SET_PRICE_TABLE_SYMBOLS';
export const ODD_LOT_SETUP = 'ODD_LOT_SETUP';

export const COMMODITY_REQUESTING = 'COMMODITY_REQUESTING';
export const COMMODITY_REQUESTING_SUCCESS = 'COMMODITY_REQUESTING_SUCCESS';
export const COMMODITY_REQUESTING_ERROR = 'COMMODITY_REQUESTING_ERROR';

export const GET_PRICEBOARD_COMMON_REQUEST = 'GET_PRICEBOARD_COMMON_REQUEST';
