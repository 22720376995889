import {
  call,
  put,
  takeLatest,
  all,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';

import {
  GET_NOTIFICATIONS_REQUEST_EVERY,
  GET_NOTIFICATIONS_REQUEST_LEAD,
  GET_NOTIFICATIONS_REQUEST_LASTEST,
} from './constants';
import { Logout, logoutRequest } from '../login/actions';
import { setToast, unsetClient } from '../../../containers/client/actions';
import { removeCookie } from '../../../lib/storages';
import i18n from '../../../i18n';
import { lockResendOtp } from '../auth/actions';
import { handleApiErrors } from '../../../lib/api-errors';
import { getMessage } from '../../../lib/selector';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const toastHandle = (message, title = i18n.t('txt-notice')) => {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    title: title,
  };
  return put(setToast(toastMsg));
};

const handleRequest = (request) => {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};

const notificationRequestApi = (data) => {
  const url = `${appUrl}/NotiServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
};

/**
 * This func try to aim for covering almost call notification api cases
 * @param {isProtect} param0 // this param help to protect private api (user data - must to authentication)
 */
function* getNotificationCommonRequestFlow({ params, callback, isProtect }) {
  try {
    const resData = yield call(notificationRequestApi, params);
    const token = yield select((state) => state.client.token);

    if (resData?.http) {
      let toastMsg = '';

      if (resData.http === 401) {
        if (isProtect) {
          if (token) {
            put(logoutRequest(token));
            put(unsetClient());
            put(Logout());
          }
          removeCookie('token');
          removeCookie('authen');
          yield put({
            type: 'INVALID_SESSION',
            resData: resData.data.message,
          });
        }

        toastMsg = i18n.t('txt-valid-loss-session');
      } else {
        if (resData.data.messageNo === 99991) {
          put(lockResendOtp());
        } else {
          const msgErr = yield select(getMessage, resData);
          toastMsg = msgErr;
        }
      }

      yield toastHandle(toastMsg);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* notificationWatcher() {
  yield all([
    takeEvery(
      GET_NOTIFICATIONS_REQUEST_EVERY,
      getNotificationCommonRequestFlow
    ),
  ]);
  yield all([
    takeLeading(
      GET_NOTIFICATIONS_REQUEST_LEAD,
      getNotificationCommonRequestFlow
    ),
  ]);
  yield all([
    takeLatest(
      GET_NOTIFICATIONS_REQUEST_LASTEST,
      getNotificationCommonRequestFlow
    ),
  ]);
}

export default notificationWatcher;
