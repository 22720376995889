export const GET_NOTIFICATIONS_REQUEST_EVERY =
  'GET_NOTIFICATIONS_REQUEST_EVERY';
export const GET_NOTIFICATIONS_REQUEST_LEAD = 'GET_NOTIFICATIONS_REQUEST_LEAD';
export const GET_NOTIFICATIONS_REQUEST_LASTEST =
  'GET_NOTIFICATIONS_REQUEST_LASTEST';

export const SET_TAB_ACTIVE = 'SET_TAB_ACTIVE';
export const SET_BACKABLE = 'SET_BACKABLE';
export const SET_TITLE = 'SET_TITLE';
export const SET_NOTIFICATION_PERMISSION = 'SET_NOTIFICATION_PERMISSION';

export const RECALL_COUNT_REQUEST = 'RECALL_COUNT_REQUEST';
export const RECALL_ALERT_REQUEST = 'RECALL_ALERT_REQUEST';
export const RECALL_MARKET_REQUEST = 'RECALL_MARKET_REQUEST';
export const RECALL_IMPORTANT_REQUEST = 'RECALL_IMPORTANT_REQUEST';
export const RECALL_API_BY_TABLES_REQUEST = 'RECALL_API_BY_TABLES_REQUEST';

export const TYPES = Object.freeze({
  IMPORTANT: 0,
  ALERT: 1,
  MARKET: 2,
});
