import React from 'react';
import { Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
// import '../bookingMarginCall/popupCustom.scss';
import { numberFormat } from '../../../util';

const MarginExtendingModal = withNamespaces('translations')(
  ({
    t,
    showModalExtend,
    defaultAccount,
    extendRecord,
    handleMarginExtend,
    handleCancel,
  }) => {
    const theme = useSelector((state) => state.client.currentTheme);
    return (
      <Modal
        show={showModalExtend}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop changeBank-modal-backdrop z-index--lv1"
        contentClassName={`${
          theme?.name || 'dark'
        } wts-modal-content--sm3 custom-modal margin-extending-modal`}
        className="z-index--lv1"
        centered
      >
        <div className="popup-header">
          <span className="popup-header__title fw-500">
            {t('personal.nav.margin.marginExtending')}
          </span>
          <span
            className="icon iClose cursor-pointer"
            onClick={() => handleCancel()}
          ></span>
        </div>
        <div className="popup-body">
          <div className="popup-body__content w-100">
            <div className="subAccount">
              <span className="subAccount--label text--light3">
                {t('txt-sub-account')}
              </span>
              <span className="subAccount--value text--light">
                {defaultAccount?.subAcntNo}
              </span>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t('txt-label-disbursement-date')}</th>
                  <th className="text-right">
                    {t('transaction.marginDebt.remainingPrincipalDebt')}
                  </th>
                  <th className="text-right">
                    {t('transaction.marginDebt.extendingTime')}
                  </th>
                  <th className="text-right">{t('txt-label-fee')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">{extendRecord?.loanSeq}</td>
                  <td className="text-center">{extendRecord?.loanDt}</td>
                  <td className="text-right">
                    {numberFormat(
                      extendRecord?.debt - extendRecord?.remnInt,
                      0,
                      '0'
                    )}
                  </td>
                  <td className="text-right">{extendRecord?.exprExtTms}</td>
                  <td className="text-right">
                    {numberFormat(extendRecord?.extendFee, 0, '0')}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="actions">
              <span
                className="btn btn--cancel w-25"
                onClick={() => handleCancel()}
              >
                {t('txt-cancel')}
              </span>
              <span
                className="btn btn--primary w-75"
                onClick={() => handleMarginExtend()}
              >
                {t('transaction.marginDebt.extendingConfirm')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default MarginExtendingModal;
