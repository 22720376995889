export const CONTRACT_ACTIVE_SET = 'CONTRACT_ACTIVE_SET';
export const SORT_DIRECTION_CHANGE = 'SORT_DIRECTION_CHANGE';
export const SORT_FIELD_CHANGE = 'SORT_FIELD_CHANGE';
export const SET_CURRENT_SYMBOL = 'SET_CURRENT_SYMBOL';

export const GET_PRICEBOARD_REQUEST = 'GET_PRICEBOARD_REQUEST';
export const GET_PRICEBOARD_SUCCESS = 'GET_PRICEBOARD_SUCCESS';
export const GET_PRICEBOARD_ERROR = 'GET_PRICEBOARD_ERROR';

export const GET_STOCK_TRADE = 'GET_STOCK_TRADE';
export const GET_DETAIL_REQUEST = 'GET_DETAIL_REQUEST';
export const GET_DETAIL_REQUEST2 = 'GET_DETAIL_REQUEST2';
export const GET_DETAIL_SUCCESS = 'GET_DETAIL_SUCCESS';
export const GET_DETAIL_RESET = 'GET_DETAIL_RESET';

export const SET_DEFAULT_TAB = 'SET_DEFAULT_TAB';
export const SET_SHOW_PLACE_ORDER_PANEL = 'SET_SHOW_PLACE_ORDER_PANEL';
export const SET_SHOW_OPEN_ORDERS_PANEL = 'SET_SHOW_OPEN_ORDERS_PANEL';
export const SET_SHOW_POSITION_PANEL = 'SET_SHOW_POSITION_PANEL';
export const SET_SHOW_ORDER_PANEL = 'SET_SHOW_ORDER_PANEL';

export const SWITCH_DVX_LIST = 'SWITCH_DVX_LIST';
export const GET_LIST_DVX_REQUEST = 'GET_LIST_DVX_REQUEST';
export const GET_LIST_DVX_SUCCESS = 'GET_LIST_DVX_SUCCESS';
export const GET_LIST_DVX_ERROR = 'GET_LIST_DVX_ERROR';

export const PLACE_ORDER_DVX_REQUEST = 'PLACE_ORDER_DVX_REQUEST';
export const PLACE_ORDER_DVX_SUCCESS = 'PLACE_ORDER_DVX_SUCCESS';
export const PLACE_ORDER_DVX_ERROR = 'PLACE_ORDER_DVX_ERROR';
export const PLACE_ORDER_DVX_CORE_REQUEST = 'PLACE_ORDER_DVX_CORE_REQUEST';
export const GET_ORDER_DVX_CORE_REQUEST = 'GET_ORDER_DVX_CORE_REQUEST';

export const GET_DVX_PP_REQUEST = 'GET_DVX_PP_REQUEST';
export const GET_DVX_PP_SUCCESS = 'GET_DVX_PP_SUCCESS';
export const GET_DVX_PP_ERROR = 'GET_DVX_PP_ERROR';

export const GET_DVX_CURR_POS_REQUEST = 'GET_DVX_CURR_POS_REQUEST';
export const GET_DVX_CURR_POS_SUCCESS = 'GET_DVX_CURR_POS_SUCCESS';
export const GET_DVX_CURR_POS_ERROR = 'GET_DVX_CURR_POS_ERROR';

export const GET_ACTV_DVX_ORDER_REQUEST = 'GET_ACTV_DVX_ORDER_REQUEST';
export const GET_ACTV_DVX_ORDER_SUCCESS = 'GET_ACTV_DVX_ORDER_SUCCESS';
export const GET_ACTV_DVX_ORDER_ERROR = 'GET_ACTV_DVX_ORDER_ERROR';

export const GET_DVX_MATCH_ORDER_REQUEST = 'GET_DVX_MATCH_ORDER_REQUEST';
export const GET_DVX_MATCH_ORDER_SUCCESS = 'GET_DVX_MATCH_ORDER_SUCCESS';
export const GET_DVX_MATCH_ORDER_ERROR = 'GET_DVX_MATCH_ORDER_ERROR';

export const GET_DVX_CLOSED_POS_REQUEST = 'GET_DVX_CLOSED_POS_REQUEST';
export const GET_DVX_CLOSED_POS_SUCCESS = 'GET_DVX_CLOSED_POS_SUCCESS';
export const GET_DVX_CLOSED_POS_ERROR = 'GET_DVX_CLOSED_POS_ERROR';

export const GET_DVX_PNL_RPT_REQUEST = 'GET_DVX_PNL_RPT_REQUEST';
export const GET_DVX_PNL_RPT_SUCCESS = 'GET_DVX_PNL_RPT_SUCCESS';
export const GET_DVX_PNL_RPT_ERROR = 'GET_DVX_PNL_RPT_ERROR';

export const GET_DVX_TOTAL_ASSET_REQUEST = 'GET_DVX_TOTAL_ASSET_REQUEST';
export const GET_DVX_TOTAL_ASSET_SUCCESS = 'GET_DVX_TOTAL_ASSET_SUCCESS';
export const GET_DVX_TOTAL_ASSET_ERROR = 'GET_DVX_TOTAL_ASSET_ERROR';

export const CLOSE_ALL_DVX_POS_REQUEST = 'CLOSE_ALL_DVX_POS_REQUEST';
export const CLOSE_ALL_DVX_POS_SUCCESS = 'CLOSE_ALL_DVX_POS_SUCCESS';
export const CLOSE_ALL_DVX_POS_ERROR = 'CLOSE_ALL_DVX_POS_ERROR';

export const GET_CFM_CLOSE_REVT_DVX_POS_REQUEST =
  'GET_CFM_CLOSE_REVT_DVX_POS_REQUEST';
export const GET_CFM_CLOSE_REVT_DVX_POS_SUCCESS =
  'GET_CFM_CLOSE_REVT_DVX_POS_SUCCESS';
export const GET_CFM_CLOSE_REVT_DVX_POS_ERROR =
  'GET_CFM_CLOSE_REVT_DVX_POS_ERROR';

export const CLOSE_REVERSE_DVX_POS_REQUEST = 'CLOSE_REVERSE_DVX_POS_REQUEST';
export const SET_SHOW_DETAIL_MODAL = 'SET_SHOW_DETAIL_MODAL';

export const GET_TOP_STOCK_INFLUENCE_REQUEST =
  'GET_TOP_STOCK_INFLUENCE_REQUEST';

export const SET_DERIVATIVE_ORDER = 'SET_DERIVATIVE_ORDER';
export const SET_ORDER_PRICE = 'SET_ORDER_PRICE';
export const SET_ORDER_QUANTITY = 'SET_ORDER_QUANTITY';

export const DERIVATIVE_COMMON_REQUEST = 'DERIVATIVE_COMMON_REQUEST';
export const DERIVATIVE_COMMON_REQUEST2 = 'DERIVATIVE_COMMON_REQUEST2';
export const DERIVATIVE_COMMON_REQUEST3 = 'DERIVATIVE_COMMON_REQUEST3';

// derivative
export const REGISTER_SOCKET_DRVX = 'REGISTER_SOCKET_DRVX';
export const LEAVE_SOCKET_DRVX = 'LEAVE_SOCKET_DRVX';

export const SOCKET_COMPONENTS = {
  PRICEBOARD: 'PRICEBOARD',
  ORDER: 'ORDER',
  POSITION: 'POSITION',
};

export const SET_DEPOSIT_WITHDRAWTH_MARGIN_MODAL =
  'SET_DEPOSIT_WITHDRAWTH_MARGIN_MODAL';

// recall api trigger (position, portfolio, asset, ...)
export const RECALL_CURR_POS_REQUEST = 'RECALL_CURR_POS_REQUEST';
export const RECALL_ORDER_LIST_REQUEST = 'RECALL_ORDER_LIST_REQUEST';
export const RECALL_CONDITION_ORDER_LIST_REQUEST =
  'RECALL_CONDITION_ORDER_LIST_REQUEST';
export const PUSH_CONDITION_ORDER_DATA_REALTIME =
  'PUSH_CONDITION_ORDER_DATA_REALTIME';
export const SET_TAB_ORDER = 'SET_TAB_ORDER';
